import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseHighlightComponent } from '@fuse/components/highlight';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DisplayErrorsDialogComponent } from '../../dialogs/display-errors-dialog/display-errors-dialog.component';
import { Utils } from '../../others/utils';
import { OTAChannelManagerResponse } from '../../interceptors/channel-manager.interceptor';
import { DialogService } from '../../services/dialog.service';
import { ApiService } from '../../services/api.service';
import { finalize, take } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-channel-manager',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatFormFieldModule, TranslocoModule, FuseAlertComponent, MatTabsModule, FuseHighlightComponent],
  templateUrl: './channel-manager.component.html',
  styleUrl: './channel-manager.component.scss'
})
export class ChannelManagerComponent {
  errors: OTAChannelManagerResponse[] = []
  successes: OTAChannelManagerResponse[] = []
  retry = null
  canTry = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { successes, errors, retry },
    private _dialog: MatDialogRef<ChannelManagerComponent>,
    private _dialogService: DialogService,
    public translate: TranslocoService,
    private _apiService: ApiService,
    private _snackbarService: SnackbarService
  ) {
    if (Utils.isDefined(data, "errors")) {
      this.errors = data.errors
    }
    if (Utils.isDefined(data, "successes")) {
      this.successes = data.successes
    }
    if (Utils.isDefined(data, "retry")) {
      this.retry = data.retry
      this.canTry = true
    }
  }

  public close() {
    this._dialog.close()
  }
  public confirmClose() {

    // Dont show confirmation if there is no errors
    if (this.errors.length == 0 || !this.canTry) {
      this._dialog.close()
      return
    }

    this._dialogService.confirmDialog({
      type: 'danger',
      title: this.translate.translate('potwierdzenie'),
      message: this.translate.translate('czy_na_pewno_chcesz_zamknac_to_okno_bez_wyslania_potwierdzenia'),
      confirmText: this.translate.translate('zamknij_mimo_to'),
      cancelText: this.translate.translate('anuluj')
    })
      .subscribe({
        next: (data) => {
          if (data == true) {
            this._dialog.close()
          }
        }
      })
  }

  isProcessing = false
  public tryAgain() {

    if (this.isProcessing == true) return
    this.isProcessing = true
    this._dialog.close()



    this._apiService.retryRequest(this.retry.url, this.retry.data)
      .pipe(take(1))
      .pipe(finalize(() => {
        this.isProcessing = false
      }))
      .subscribe({
        next: (response: any) => {
          let allSent = true

          if (!Utils.isDefined(response, "data.channelManager.channels")) return
          response.data.channelManager.channels.forEach((channel) => {
            if (channel.status == 'error') allSent = false
          })

          // Show success confirmation toast
          if (allSent == true)
            this._snackbarService.showSnackBar(this.translate.translate('dane_zostaly_prawidlowo_wyslane'), 'success')
        }
      })
    // 


  }
}
