import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DbNotification } from 'app/core/shared/models/DbNotification';
import { SourcesService } from 'app/core/shared/services/sources.service';
import { Utils } from 'app/core/shared/others/utils';
import { DbSource } from 'app/core/shared/models/DbSource';
import { Source } from 'app/core/shared/models/Source';
import { TranslocoModule } from '@ngneat/transloco';
import { DateFormatterComponent } from 'app/core/shared/components/date-formatter/date-formatter.component';
import { MatIconModule } from '@angular/material/icon';
import { SourceAvatarComponent } from 'app/core/shared/components/source-avatar/source-avatar.component';
import { RoomsService } from 'app/core/shared/services/rooms.service';
import { DbRoom } from 'app/core/shared/models/DbRoom';

@Component({
  selector: 'ical-notification',
  standalone: true,
  imports: [CommonModule, TranslocoModule, DateFormatterComponent, SourceAvatarComponent, MatIconModule],
  templateUrl: './ical-notification.component.html',
  styleUrl: './ical-notification.component.scss'
})
export class IcalNotificationComponent implements OnChanges {

  @Input() notification:DbNotification = null
  source:Source
  room:DbRoom = null
  constructor(
    private _sourcesService:SourcesService,
    private _roomsService:RoomsService) {

  }

  ngOnChanges(changes:SimpleChanges) {
    if(Utils.isDefined(changes,"notification.currentValue.body.sourceId")) {
      this.source = this._sourcesService.getSourceById(changes.notification.currentValue.body.sourceId)
    }
    if(Utils.isDefined(this.notification,"body.roomId")) {
      this.room = this._roomsService.getById(this.notification.body.roomId)
    }
  }
}
