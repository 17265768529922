import { Injectable, OnDestroy } from "@angular/core";
import { AppDB } from "../models/db";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { DbReservation } from "../models/DbReservation";
import { DbClient } from "../models/DbClient";
import { CalendarReservationService } from "./calendar-reservation.service";
import { ClientsService } from "./clients.service";
import { EmployeesService } from "./employees.service";
import { DbEmployee } from "../models/DbEmployee";
import { RoomsService } from "./rooms.service";
import { Collision } from "../models/Collision";
import { CONST } from "../data/const";
import { CollisionService } from "./collision.service";
import { SettingsService } from "./settings.service";
import { UserSettings } from "../models/UserSettings";
import { Utils } from "../others/utils";

@Injectable({
  providedIn: 'root'
})
export class ReservationsService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _reservations = new BehaviorSubject<DbReservationObject>({})
  private _reservationsArray = []
  private _canceledReservationsArray = []
  private _counter = new BehaviorSubject<DbReservationCounter>({
    valid: 0,
    canceled: 0
  })
  public currentReservations: Observable<DbReservationObject> = this._reservations.asObservable()

  private _employees: DbEmployee[];
  private _db: AppDB
  private _userSettings: UserSettings
  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService,
    private _calendarReservationService: CalendarReservationService,
    private _clientsService: ClientsService,
    private _employeesService: EmployeesService,
    private _roomsService: RoomsService,
    private _collisionService: CollisionService,
    private _settingsService: SettingsService
  ) {

    this._subscriptions.push(this._employeesService.currentEmployees.subscribe({
      next: (data) => {
        this._employees = data
      }
    }))
    
    this._subscriptions.push(this._settingsService.currentUserSettings.subscribe({
      next: (data) => {
        this._userSettings = data
        this._countAll(this._reservations.value)
      }
    }))

    this._subscriptions.push(this.currentReservations.subscribe({
      next: (data) => {
        this._countAll(data)
      }
    }))

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
          this._reservations.next({})
        }
      })
    )

    // Add reservation on synchronize
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: (data) => {

          if (data.table == 'reservations') {

            // Add or update
            const reservationChanges = []
            data.value.forEach((reservation: DbReservation) => {

              if (reservation.room == null || typeof reservation.room == 'undefined') reservation.room = this._roomsService.getById(reservation.roomId)

              let newReservation = new DbReservation(reservation)

              // Add client before update rooms
              newReservation.client = this._clientsService.getById(reservation.clientId)
              reservationChanges.push({
                previous: this._reservations.value[reservation.reservationId],
                current: newReservation
              })

              // Delete reservation if user has no permission and reservation existing
              if (newReservation.hasPermission == false) {
                if (typeof this._reservations.value[newReservation.reservationId] != 'undefined') {
                  delete this._reservations.value[newReservation.reservationId]
                  this._reservations.next(this._reservations.value)
                }
              }

              // Prepare reservation calendar element
              const calendarReservation = this._calendarReservationService.create(this._employees, newReservation)
              newReservation.calendarReservation = calendarReservation;

              // Update existing reservation
              this._reservations.value[reservation.reservationId] = newReservation

            });

            this._reservations.next(this._reservations.value)
            this._reservationsArray = Object.values(this._reservations.value).filter(x => x.status == 1 && x.hasPermission == true)
            this._canceledReservationsArray = Object.values(this._reservations.value).filter(x => x.status == 0 && x.hasPermission == true)
            this._dataSynchronizerService.received()

            // Push changes to RoomsService
            this._dataSynchronizerService.next('roomsReservations', reservationChanges, null)

            setTimeout(() => {
              this._collisionService.detectCollisions();
            }, 0);
          }

          else if (data.table == 'reservationClients') {

            // TODO
            // REWRITE IT TO GET STRAIGTH TO SERVICE
            const reservationChanges = this._processReservationClients(data.value)
            this._reservations.next(this._reservations.value)
            this._dataSynchronizerService.received()

            // Push changes to RoomsService
            this._dataSynchronizerService.next('roomsReservations', reservationChanges, null)
          }


        }
      }))
  }

  getReservations(): DbReservationObject {
    return this._reservations.value
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  public updateReservationField(reservationId, key, value) {
    let reservation = this._reservations.value[reservationId]
    if (reservation) {
      reservation[key] = value
      this._reservations.next(this._reservations.value)
    }
  }

  lastSort = null
  browseReservations({ collection = 'reservations', sort = { active: 'id', direction: 'desc' }, filters = [], allResults = [] }): PaginatedDbReservations {
    let t1 = new Date().getTime();

    let sortFn

    // To avoid creating variable inside sort
    // Create it not if needed 
    let field = ''
    if (sort.active == 'arrival' || sort.active == 'departure') {
      field = sort.active == 'arrival' ? 'arrivalTimestamp' : 'departureTimestamp'
    }

    if (sort.active == 'id') {
      if (sort.direction == 'desc') sortFn = (a: DbReservation, b: DbReservation) => b.reservationNo - a.reservationNo
      if (sort.direction == 'asc') sortFn = (a: DbReservation, b: DbReservation) => a.reservationNo - b.reservationNo
    }

    else if (sort.active == 'arrival' || sort.active == 'departure') {
      if (sort.direction == 'desc') sortFn = (a: DbReservation, b: DbReservation) => b[field] - a[field]
      if (sort.direction == 'asc') sortFn = (a: DbReservation, b: DbReservation) => a[field] - b[field]
    }

    // Take from temporary or from already filtered
    let searchingResults = []
    if (!allResults) allResults = []

    if (allResults.length > 0) searchingResults = allResults
    else if (collection == 'reservations') searchingResults = this._reservationsArray
    else searchingResults = this._canceledReservationsArray

    if (this.lastSort != sort) searchingResults = searchingResults.sort(sortFn)
    this.lastSort = sort

    if (filters.length > 0) {
      filters.forEach(filter => {
        searchingResults = searchingResults.filter(filter)
      })
    }

    const paginationResult: PaginatedDbReservations = {
      time: new Date().getTime() - t1,
      resultsCount: searchingResults.length,
      allResults: searchingResults,
      totalResults: this._reservationsArray.length
    }

    return paginationResult

  }

  getAll(): Observable<boolean> {

    this._reservations.next({})
    if (!this._db) return of(true)
    let startTime = 0

    const loader = from(this._db.transaction("r", this._db.reservations, () => {
      startTime = performance.now()
      return this._db.reservations.toArray();
    }))
      .pipe(
        map((reservations: DbReservation[]) => {

          const reservationChanges = []
          reservations.forEach((reservation: DbReservation) => {

            // if(reservation.hasPermission === false) return
            reservation.room = this._roomsService.getById(reservation.roomId)
            const newReservation = new DbReservation(reservation)
            if (newReservation.hasPermission == false) return

            newReservation.client = this._clientsService.getById(newReservation.clientId)
            const calendarReservation = this._calendarReservationService.create(this._employees, newReservation)
            newReservation.calendarReservation = calendarReservation;
            reservationChanges.push({
              previous: this._reservations.value[reservation.reservationId],
              current: newReservation
            })
            this._reservations.value[reservation.reservationId] = newReservation
          });

          this._dataSynchronizerService.next('roomsReservations', reservationChanges, null)

          this._reservations.next(this._reservations.value)
          this._reservationsArray = Object.values(this._reservations.value).filter(x => x.status == 1 && x.hasPermission == true)
          this._canceledReservationsArray = Object.values(this._reservations.value).filter(x => x.status == 0 && x.hasPermission == true)

         // Perform collision detection asynchronously
          setTimeout(() => {
            this._collisionService.detectCollisions();
          }, 0);
          return true

        }),
        tap(() => console.log(`[Reservations Service]: Built in ${performance.now() - startTime}ms.`)),
      )

    return loader
  }


  /**
   * Find reservation by `reservationNo`, it is faster to get reservation using getById function
   * @param reservationNo 
   * @returns 
   */
  findByNumber(reservationNo): DbReservation {
    const reservation = Object.values(this._reservations.value).find(x => x.reservationNo == reservationNo)
    if (reservation) return reservation
    else console.warn(`Reservation with reservationNo: ${reservationNo} not found.`)
  }

  /**
   * Get reservation by `reservationId` field
   * @param reservationNo 
   * @returns 
   */
  getById(reservationId): DbReservation {
    const reservation = this._reservations.value[reservationId]
    if (reservation) return reservation
    else console.warn(`Reservation with id: ${reservationId} not found.`)
  }

  getClientReservations(collection: 'canceled' | 'reservations', clientId) {
    if (Utils.isNullOrEmpty(clientId)) return

    let fitler = (res) => res.clientId == clientId
    const results = this.browseReservations({
      collection: collection,
      sort: {
        direction: 'desc',
        active: 'id'
      },
      filters: [fitler],
      allResults: null
    })

    return results.allResults
  }

  getCounter(): Observable<DbReservationCounter> {
    return this._counter.asObservable()
  }

  private _processReservationClients(clients: DbClient[]) {

    const reservationChanges = []
    // Update clients in reservations
    // TODO REWRITE
    // FOR BIG NUMBERS IT'S NOT EFFICIENT
    Object.values(this._reservations.value).forEach((reservation: DbReservation) => {
      clients.forEach((client: any) => {
        if (reservation.clientId == client.clientId) {
          reservation.client = client;
          // Recreate calendar reservation
          const calendarReservation = this._calendarReservationService.create(this._employees, reservation)
          reservation.calendarReservation = calendarReservation;

          reservationChanges.push({
            previous: this._reservations.value[reservation.reservationId],
            current: reservation
          })
        }
      });
    })

    return reservationChanges
  }

  private _countAll(data) {
    const withLocks = Utils.getNestedValue(this._userSettings, "settings.reservationLockAsReservationTurnOn")
    let valid = 0
    let canceled = 0
    Object.values(data).forEach((reservation: DbReservation) => {
      if (reservation.status == 1 && reservation.hasPermission == true) {
        if (withLocks == 0 && reservation.clientId > 0) { valid++ }
        else if (withLocks == 1) valid++
      }
      else if (reservation.status == 0 && reservation.hasPermission == true) {
        if (withLocks == 0 && reservation.clientId > 0) { canceled++ }
        else if (withLocks == 1) canceled++
      }
    })

    this._counter.next({ valid, canceled })
  }
}

export interface DbReservationObject {
  [key: number]: DbReservation
}

export interface PaginatedDbReservations {
  allResults: DbReservation[]
  // results: DbReservation[]
  resultsCount: number
  time: number,
  totalResults: number
}

export interface DbReservationCounter {
  valid: number,
  canceled: number
}