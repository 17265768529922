import { IStyles } from "./interfaces/istyles";

export class DarkStyles implements IStyles {

    public static DefaultHeaderBackgroundColor = '#0f172a'
    public static DefaultBookingReservationColor = '#e53935'
    public static BackgroundLinesColor = "#334155"
    public static TodayLineColor = '#f53513'
    public static CanDragColor = "rgba(100,250,100,0.8)";
    public static CanNotDragColor = "rgba(113, 113, 113, 0.2)"
    public static WeekendHeaderBackgroundColor = 'rgba(30, 41, 59, 0.4)'
    public static WeekendCalendarBackgroundColor = 'rgba(30, 41, 59, 0.4)'
    public static InActiveRoomBackgroundColor = 'rgba(113, 113, 113, 0.2);'
    public static PastBackgroundColor = 'rgba(75,75,75,0.05)'
 
    public static CalendarHeaderHistoricalTextColor = '#525f7f'
    public static CalendarHeaderFutureTextColor = '#dbdbdb'
    public static CalendarHeaderBorderColor = '#334155'
    public static PricingRoomSeparatorBackgroundColor = '#161f35'
    public static PricingRoomSeparatorBorderColor= '#1b253e'

    public static DefaultRoomRectColor = '#1f2a3b'
    public static CreatedReservationBackground = '#f53513'
    public static DefaultReservationRectColor = '#f53513'
    public static HighlightColor = 'rgba(150,150,150,0.1)'
    public static HighlightRectColor = '#4f46e5'
    public static SingleRoomCalendarPrevNextMonthDayBackgroundColor = 'rgba(150,150,150,0.1)'
    public static SingleRoomCalendarCurrentMonthDayBackgroundColor = 'rgba(250,250,250,0.3)'

    public static SingleRoomCalendarDayNumberTextColor = 'white'
    public static PricingTextColor = 'white'
    public static PricingRoomTextColor = 'white'
    public static PricingNotEditableTextColor = '#dbdbdb'
    public static TextColor = 'white'
    public static InversedTextColor = 'black'

    public static LockGradientFirstColor = '#1e1e28'
    public static LockGradientSecondColor = '#404048'

    public static DraggingGradientFirstColor = '#1e1e28'
    public static DraggingGradientSecondColor = '#4f46e5'
    
    public static VariantHighlight = '#404048'

    public static Availability = {
        Available: {
            Background: '#12970e',
            Border: '#12970e'
        },
        NotAvailable: {
            Background: '#e53935',
            Border: '#e53935'
        }
    }
    
    public static Reservations = {
        typ1d: '#e53935',
        typ1w: '#fb8c00',
        typ1: '#43a047',
        typ1i: '#00acc1',
        typ1waiting: '#8e24aa',
    };
}

