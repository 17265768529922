export class HTMLUtils {
    public static objectToParams(filters: { [key: string]: any }): string {
        const params = new URLSearchParams();
    
        for (const key in filters) {
            if (filters.hasOwnProperty(key)) {
                const value = filters[key];
    
                if (Array.isArray(value)) {
                    value.forEach((item) => {
                        params.append(`${key}[]`, item);
                    });
                } else {
                    params.append(key, value);
                }
            }
        }
    
        return `?${params.toString()}`;
    }

    public static getText(html: string): string {
        // Parse the HTML string into a document
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        // Extract and return only the text content
        return doc.body.textContent || "";
    }
}