import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LoaderDialogService } from '../../services/loader-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  imports: [MatIconModule, MatDialogModule, MatProgressBarModule, TranslocoModule, FuseCardComponent]
})
export class LoadingDialogComponent implements OnDestroy {

  private readonly _subscriptions: Subscription[] = []

  title = this._translocoService.translate('ladowanie')
  content = this._translocoService.translate('ladowanie_danych')

  constructor(
    private _translocoService: TranslocoService,
    private _loaderDialogService: LoaderDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this._subscriptions.push(this._loaderDialogService.currentTitle.subscribe({
      next: (title) => {
        this.title = title
      }
    }))

    this._subscriptions.push(this._loaderDialogService.currentContent.subscribe({
      next: (content) => {
        this.content = content
      }
    }))

    if (!data) return
    if (data.title) this.title = data.title
    if (data.content) this.content = data.content

    this._loaderDialogService.title.next(this.title)
    this._loaderDialogService.content.next(this.content)
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}
