import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { languages } from 'environments/environment';
import { AppDB } from '../../models/db';
import { InternetConnectionService } from '../../services/internet-connection.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FuseCardComponent } from '@fuse/components/card';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from 'app/core/auth/auth.service';
import { decodeToken } from '../../utils/jwt-decode';


@Component({
  standalone: true,
  selector: 'app-refresh-page',
  templateUrl: './refresh-page.component.html',
  styleUrls: ['./refresh-page.component.scss'],
  imports: [CommonModule, MatDialogModule, TranslocoModule, MatButtonModule, FuseCardComponent, MatIconModule]
})
export class RefreshPageComponent {

  canGoOffline = false;

  constructor(
    private ics:InternetConnectionService,
    private dialog: MatDialogRef<RefreshPageComponent>,
    public translate:TranslocoService,
    private _authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
    )
  {
    this.checkPossibilityToGoOffline(data?.userId, data?.employeeId);
  }

  reload(){
    window.location.reload();
  }

  async checkPossibilityToGoOffline(userId, employeeId)
  {

    if(typeof userId === 'undefined') userId = null
    if(typeof employeeId === 'undefined') employeeId = null

    if(userId == null) {
      const decodedToken = decodeToken(this._authService.token)
      userId = decodedToken.userId
      employeeId = decodedToken.employeeId
    }
    
    let db = new AppDB(userId, employeeId)
    db.transaction("r", db.userSettings, async () => {
      const availableUserSettings = await db.userSettings.toArray()
      if(!availableUserSettings) return
      const settings = availableUserSettings.find(x=>x.userData.userId == userId)
      if(!settings) return
      this.canGoOffline = true
    }).finally(() => {
      db.close()
    })
  }

  runInSafeMode(){
    if(!this.canGoOffline) return;
    // alert('Tryb awaryjny...');
    this.ics.offlineModeOn()
    this.dialog.close({
      offlineMode: true
    })
  }

  
}
