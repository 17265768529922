<fuse-card class="flex flex-col items-center w-full p-8">
    <div class="flex items-center justify-center">
        <mat-icon class="notranslate icon-size-16 text-red-500" [svgIcon]="'heroicons_outline:signal-slash'"></mat-icon>
    </div>
    <div class="text-2xl font-semibold leading-tight text-center mt-6">
        {{'brak_polaczenia_z_serwerem' | transloco}}
    </div>
    <div class="text-center text-secondary mt-3">
        {{'nie_udalo_sie_nawiazac_polaczenia' | transloco}}
        <ng-container *ngIf="canGoOffline">
            {{'informacja_o_trybie_offline' | transloco}}
        </ng-container>
    </div>
    <div class="text-center mt-3">
        <code>500 Internal Server Error</code>
    </div>

    <button class="px-6 mt-8 w-full" mat-flat-button color="primary" (click)="reload()">{{"odswiez_strone" | transloco}}</button>
    <button *ngIf="canGoOffline" class="px-6 mt-4 w-full" mat-stroked-button color="primary" (click)="runInSafeMode()">{{"uruchom_w_trybie_offline" | transloco}}</button>
</fuse-card>