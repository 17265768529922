import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { PushNotificationData } from '../models/push-notification-data';
import { ToastComponent } from '../components/toast/toast.component';
import { SettingsService } from './settings.service';
import { UserSettings } from '../models/UserSettings';
import { Utils } from '../others/utils';
import { ApplicationData } from '../data/application-data';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private _portalHost: DomPortalOutlet | null = null;
  private toastStack: any | ComponentRef<ToastComponent>[] = [];

  private _audio = null
  private _soundsOn = false
  private _userSettings: UserSettings = null
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private _settingsService: SettingsService
  )
  {
    

    this._settingsService.currentUserSettings.subscribe({
      next: (userSettings: UserSettings) => {
        this._userSettings = userSettings

        if(!Utils.isDefined(this._userSettings,"settings.notifySound")) {
          this._soundsOn = false
          return
        }

        if(Utils.isNullOrEmpty(this._userSettings.settings.notifySound)) {
          this._soundsOn = false
          return
        }

        this._soundsOn = true

        const settingsSound = this._userSettings.settings.notifySound
        if(!settingsSound) return
        const sound = ApplicationData.NotificationSounds.find(x=>x.value == settingsSound)
        if(!sound) return
        this._audio = new Audio(`assets/sounds/${sound.fileName}`);
        
      }
    })
  }

  showToast(n:PushNotificationData) {

    const container = document.getElementById('notifications-container')

    if(!container) {
      console.warn("Notifications container not found. Can't display notification.");
      return;
    }

    // Initialize portalHost if not already initialized
    const portalHost = new DomPortalOutlet(container, this.componentFactoryResolver, this.appRef, this.injector);
    const portal: any = new ComponentPortal(ToastComponent);
    
    let componentRef: any = null;
    // Check if there's an existing portal attached
    componentRef = portalHost.attach(portal);
    this._portalHost = portalHost

    if(typeof n.type !== 'undefined') {
      componentRef.instance.type = n.type;
    }

    if(n.showDate === undefined) n.showDate = true
    if(n.timeToClose === undefined) n.timeToClose = 3000
    if(n.added === undefined) n.added = new Date()
    componentRef.instance.data = n;
   
    componentRef.instance.portalHost = portalHost;
    componentRef.instance.portal = portal;

    this.toastStack.unshift(portal);
    const maxToasts = 50; // Adjust as needed
    if (this.toastStack.length > maxToasts) {
      const removedPortal: any = this.toastStack.pop();
      removedPortal.detach();
    }

    this._playSound()
    if (n.timeToClose > 0) this._scheduleDetachment(portalHost, portal, n.timeToClose);
    return componentRef
  }

  clearAll() {
    if (this._portalHost) {
      // Detach and destroy all components in portalHost
      this._portalHost.dispose();
      this._portalHost = null; // Reset the portalHost so it can be re-initialized if needed
    }
  }

  private _scheduleDetachment(host:DomPortalOutlet, portal: ComponentPortal<ToastComponent>, timeToClose: number): void {
    setTimeout(() => {
      host.detach();
      const index = this.toastStack.indexOf(portal);
      if (index !== -1) {
        this.toastStack.splice(index, 1);
      }
    }, timeToClose);
  }

  private _playSound() {

    console.log(this._soundsOn, this._audio)
    // Sounds off
    if(this._soundsOn == false) {
      return
    }

    // Invalid audio sound file
    if(!this._audio) {
      console.log("[ToastService]: Can't load audio notification sound.")
      return
    }

    this._audio.play();
  }
}