import { CommonModule, NgIf } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserSettings } from 'app/core/shared/models/UserSettings';
import { SettingsService } from 'app/core/shared/services/settings.service';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { SynchronizationComponent } from 'app/layout/common/synchronization/synchronization.component';
import { UserSettingsComponent } from 'app/layout/common/user-settings/user-settings.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { DrawerRouterOutletComponent } from 'app/layout/drawer-router-outlet/drawer-router-outlet.component';
import { UserAvatarImageComponent } from 'app/core/shared/components/user-avatar-image/user-avatar-image.component';
import { CollisionsComponent } from 'app/layout/common/collisions/collisions.component';
import { PermissionsService } from 'app/core/shared/services/permissions.service';
import { UserPermissions } from 'app/core/shared/models/UserPermissions';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { SettingsComponent } from 'app/layout/common/settings/settings.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { SubscriptionActionStateAlertComponent } from 'app/core/shared/components/alerts/subscription-action-state-alert/subscription-action-state-alert.component';
import { TranslocoModule } from '@ngneat/transloco';
import { InternetConnectionService } from 'app/core/shared/services/internet-connection.service';
import { environment } from 'environments/environment';
@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone   : true,
    imports      : [CommonModule, RouterModule, LocalizeRouterModule, DrawerRouterOutletComponent, CollisionsComponent, UserAvatarImageComponent, FuseLoadingBarComponent, FuseVerticalNavigationComponent, NotificationsComponent, UserComponent, NgIf, MatIconModule, MatButtonModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, RouterOutlet, QuickChatComponent, SynchronizationComponent, UserSettingsComponent, SettingsComponent, FuseAlertComponent, SubscriptionActionStateAlertComponent, TranslocoModule],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{

    isScreenSmall: boolean;
    navigation: Navigation;
    user: UserSettings = new UserSettings({});
    public permissions = new UserPermissions({})
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public isOfflineMode = false

    public version = environment.version
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _settingsService: SettingsService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _permissionsService:PermissionsService,
        private _ics:InternetConnectionService
    )
    {
        this._permissionsService.currentPermissions
        .pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (data) => {
                this.permissions = data
            }
        })

        this._ics.offlineMode
        .pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (data) => {
                this.isOfflineMode = data
            }
        })
    }

    

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) =>
            {
                this.navigation = navigation;
            });
     

        // Subscribe to the user service
         this._settingsService.getUserSettings()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
            next: (data)=> {
                this.user = data
            }
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) =>
            {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
