<!-- Notifications toggle -->
<button
    *ngIf="permissions.has('notifications')"
    mat-icon-button
    (click)="openPanel()"
    #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{unreadCount > 99 ? '99+' : unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">{{'powiadomienia' | transloco}}</div>
            <div class="ml-auto">
                <button
                    mat-icon-button
                    [matTooltip]="'oznacz_jako_wykonane' | transloco"
                    [disabled]="unreadCount === 0"
                    (click)="markAllAsRead()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:envelope-open'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 h-full divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <cdk-virtual-scroll-viewport 
            [ngClass]="{'h-32': notifications.length == 1, 'h-64': notifications.length == 2, 'h-90': notifications.length == 3, 'h-120': notifications.length >= 4}"
            [itemSize]="5">
                <ng-container *cdkVirtualFor="let notification of notifications; trackBy: trackByFn">
                    <div 
                        class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 border-b"
                        [ngClass]="{'unread': !notification.read}">
    
                        <!-- Notification with a link -->
                        <ng-container *ngIf="notification.link">
                            <!-- Normal links -->
                            <ng-container *ngIf="!notification.useRouter">
                                <a
                                    class="flex flex-auto py-5 pl-6 cursor-pointer"
                                    [href]="notification.link">
                                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                                </a>
                            </ng-container>
                            <!-- Router links -->
                            <ng-container *ngIf="notification.useRouter">
                                <a
                                    class="flex flex-auto py-5 pl-6 cursor-pointer"
                                    [routerLink]="notification.link">
                                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                                </a>
                            </ng-container>
                        </ng-container>
    
                        <!-- Notification without a link -->
                        <ng-container *ngIf="!notification.link">
                            <div class="flex flex-auto py-5 pl-6 cursor-pointer">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </div>
                        </ng-container>
    
                        <!-- Actions -->
                        <div class="relative flex flex-col my-5 mr-6 ml-2">
                            <!-- Indicator -->
                            <button
                                class="w-6 h-6 min-h-6"
                                mat-icon-button
                                (click)="markAsReaded(notification)">
                                <span class="w-2 h-2 rounded-full" [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.readed == 1, 'bg-primary': notification.readed == 0}"></span>
                            </button>

                            <!-- Remove -->
                            <button
                                class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                                mat-icon-button
                                (click)="deleteNotification(notification)"
                                [matTooltip]="'Remove'">
                                <mat-icon
                                    class="icon-size-4"
                                    [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                            </button>
                        </div>
                    </div>
    
                    <!-- Notification content template -->
                    <ng-template #notificationContent>
                        <!--  -->
                        <subscription-info-notification *ngIf="notification.type == 1" [notification]="notification"></subscription-info-notification>
                        
                        <!-- Pre-booking reservation  -->
                        <pre-booking-reservation-notification (click)="showDetails(notification)" *ngIf="notification.type == 3" [notification]="notification"></pre-booking-reservation-notification>
                        
                        <!-- Paid reservation -->
                        <reservation-paid-notification (click)="showDetails(notification)" *ngIf="notification.type == 4" [notification]="notification"></reservation-paid-notification>

                        <!-- Canceled reservation -->
                        <canceled-reservation-notification (click)="showDetails(notification)" *ngIf="notification.type == 5" [notification]="notification"></canceled-reservation-notification>
                        
                        <!--  -->
                        <channel-manager-import-notification (click)="showDetails(notification)" *ngIf="notification.type == 6" [notification]="notification"></channel-manager-import-notification>

                        <!--  -->
                        <ical-notification (click)="showDetails(notification)" *ngIf="notification.type == 7" [notification]="notification"></ical-notification>

                        <!-- Booking engine - Canceled reservation by client -->
                        <booking-engine-client-cancelation-notification (click)="showDetails(notification)" *ngIf="notification.type == 8" [notification]="notification"></booking-engine-client-cancelation-notification>

                    </ng-template>
                </ng-container>
            </cdk-virtual-scroll-viewport>
           

            <!-- No notifications -->
            <ng-container *ngIf="!notifications || !notifications.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon
                            class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">{{'brak_powiadomien' | transloco}}</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{'gdy_otrzymasz_powiadomienie_pojawi_sie_ono_tutaj' | transloco}}</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>

<ng-template>

</ng-template>
