<div matTooltip="{{source?.name | transloco}}" matTooltipPosition="above" class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
    
    <!-- Source exists in database -->
    <ng-container *ngIf="source">
        <img *ngIf="source?.logo" class="w-8 h-8"
        [src]="'assets/images/sources/' + source?.logo +'.png'"
        [alt]="'Source image'">
        <div *ngIf="!source?.logo" class="w-8 h-8 mr-4 text-xl rounded-full uppercase flex justify-center items-center">
            {{source?.name?.charAt(0)}}
        </div> 
    </ng-container>

    <!-- Source doesnt exists -->
    <ng-container *ngIf="!source">
        <div class="w-8 h-8 mr-4 text-xl rounded-full uppercase flex justify-center items-center">
            {{('inne' | transloco).charAt(0)}}
        </div> 
    </ng-container>

</div>
