
<div class="flex flex-auto">

    <ng-container *ngIf="source">
        <app-source-avatar class="mr-4" [sourceId]="notification?.body?.sourceId"></app-source-avatar>
    </ng-container>

    <div class="flex flex-col flex-auto">

        <div class="font-semibold line-clamp-2 pr-2">
            {{source?.name}}
        </div>

        <!--NEW-->
        <div *ngIf="notification?.body?.data?.status == 'new'">
            <span [innerHTML]="'dokonano_rezerwacji_przez' | transloco: { name: (notification?.body?.data?.client?.forename + ' ' + notification?.body?.data?.client?.name), 'arrival' : notification?.body?.data?.reservation?.arrival, 'nights' : 3 }"></span>
        </div>
        
        <!--MODIFIED-->
        <div *ngIf="notification?.body?.data?.status == 'modified'">
            <span [innerHTML]="'zmodyfikowano_rezerwacji_przez' | transloco: { name: (notification?.body?.data?.client?.forename + ' ' + notification?.body?.data?.client?.name), 'reservationNumber' : '#'+notification?.body?.data?.reservation?.reservationNumber}"></span>
        </div>

        <!--CANCELLED-->
        <div *ngIf="notification?.body?.data?.status == 'cancelled'">
            <span [innerHTML]="'anulowano_rezerwacje_przez' | transloco: { name: (notification?.body?.data?.client?.forename + ' ' + notification?.body?.data?.client?.name), 'reservationNumber' : '#'+notification?.body?.data?.reservation?.reservationNumber}"></span>
        </div>

        <!--COLLISION-->
        <div *ngIf="notification?.body?.data?.isCollision" class="line-clamp-1 text-red-600 flex items-center">
            <mat-icon class="icon-size-4 mr-0 text-red-600" [svgIcon]="'heroicons_solid:exclamation-triangle'"></mat-icon>
            <span>{{'wystapila_kolizja_rezerwacji' | transloco}}</span>
        </div>




    
        <!-- {{notification?.body | json}} -->
        <div class="mt-2 text-sm leading-none text-secondary">
            <date-formatter [date]="notification.addDate" [showTime]="true"></date-formatter>
        </div>
        
    </div>
</div>
