import { AfterViewInit, SimpleChanges, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output  } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserSettings } from '../../models/UserSettings';
import { SettingsService } from '../../services/settings.service';
import { Utils } from '../../others/utils';
import { CommonModule } from '@angular/common';
import { DbEmployee } from '../../models/DbEmployee';

@Component({
  standalone: true,
  selector: 'user-avatar-image',
  imports: [CommonModule],
  templateUrl: './user-avatar-image.component.html',
  styleUrls: ['./user-avatar-image.component.scss']
})
export class UserAvatarImageComponent implements OnDestroy, OnChanges, OnInit, AfterViewInit {

  private readonly _subscriptions: Subscription[] = []
  private _defaultAvatar = '/assets/images/avatars/user.png'
  public avatarPath = '/assets/images/avatars/user.png'
  public isLogoCustom = false
  defaultLetter = ''

  @Input('user') user:DbEmployee
  @Input('customClass') customClass : string
  @Output('onLogoImageUpdates') onLogoImageUpdates = new EventEmitter()

  userSettings:UserSettings
  constructor(
    private _settingsService:SettingsService,
    private _cdr:ChangeDetectorRef
    ) {

     
  }
  
  ngOnChanges(changes:SimpleChanges): void {
    this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (userSettings:UserSettings) => {
          this.userSettings = userSettings
          this._setAvatar()
    }}))
  }

  ngOnInit(): void {
    this._subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (userSettings:UserSettings) => {
          this.userSettings = userSettings

          this._setAvatar()
    }}))
  }

  ngAfterViewInit(): void {

  

    this._setAvatar()
  }

  private _setAvatar() {

    // Try to set by employee avatar
    if(!Utils.isNullOrEmpty(this.user)) {
      this.defaultLetter = this._getDefaultLogoLetter(this.user.forename, this.user.email)
      this._getUserAvatar(this.user)
      this._cdr.markForCheck()
      return
    }

    const employeeId = Utils.getNestedValue(this.user, "employeeId")
    if(employeeId > 0) return
    // Try to set from user settings
    this.defaultLetter = this._getDefaultLogoLetter(this.userSettings.userData.forename, this.userSettings.userData.email)
    this._getUserAvatar(this.userSettings.userData)
    this._cdr.markForCheck()
  }

  private _getUserAvatar(user) {

    if(!Utils.isDefined(user,"avatar") || Utils.isNullOrEmpty(user.avatar)) {
      this.avatarPath = this._defaultAvatar
      this.isLogoCustom = false
      this.onLogoImageUpdates.emit({
        isLogoCustom: false
      })
      this._cdr.markForCheck()
      return
      
    } 

    this.avatarPath = user.avatar
    this.isLogoCustom = true
    this.onLogoImageUpdates.emit({
      isLogoCustom: true
    })
    this._cdr.markForCheck()
  }

  private _getDefaultLogoLetter(forename,email) {
    if(!Utils.isNullOrEmpty(forename)) return forename.charAt(0)
    if(!Utils.isNullOrEmpty(email)) return email.charAt(0)
    return 'u'
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
