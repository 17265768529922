import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoaderDialogService {

    public title = new BehaviorSubject<string>(null)
    public currentTitle: Observable<string> = this.title.asObservable()

    public content = new BehaviorSubject<string>(null)
    public currentContent: Observable<string> = this.content.asObservable()

}