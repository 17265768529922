import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  private firstDayOfWeekSubject = new BehaviorSubject<0 | 1>(0)
  public firstDayOfWeek$ = this.firstDayOfWeekSubject.asObservable()

  constructor() { }

  public setFirstDayOfWeek(firstDayOfTheWeek: 'MON' | 'SUN') {
    if(firstDayOfTheWeek == 'SUN') this.firstDayOfWeekSubject.next(0)
    if(firstDayOfTheWeek == 'MON') this.firstDayOfWeekSubject.next(1)
  }

  public getFirstDayOfWeek() {
    return this.firstDayOfWeekSubject.value
  }

}
