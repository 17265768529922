<mat-form-field [appearance]="appearance" class="w-full" (click)="checkFilters()">
  <mat-label>{{label | transloco}}</mat-label>
  <mat-icon *ngIf="matPrefixIcon != ''" matPrefix [svgIcon]="matPrefixIcon"></mat-icon>

  <ng-container *ngIf="displayImagePrefix && control?.value != '' && control?.value?.img != null">
    <span class="hidden sm:flex w-6 h-6 mr-1 overflow-hidden"
      [style.background]="'url('+control?.value?.img+') no-repeat 0 0'"
      [style.backgroundSize]="'contain'"
      [style.backgroundPosition]="'left'"></span>
  </ng-container>

  <mat-select #selector no-space [formControl]="control" [disableOptionCentering]="true" [required]="isRequired">
    <div class="px-3 mt-2">
      <mat-form-field [appearance]="appearance" class="fuse-mat-dense w-full">
        <mat-icon matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"
          (click)="$event.stopPropagation(); searchText?.focus()"></mat-icon>
        <input matInput #searchText type="text" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
          (keyup)="filterOptions(searchText.value)" />
          <mat-icon matSuffix [svgIcon]="'heroicons_solid:x-mark'"
          (click)="resetSearchText()"></mat-icon>
      </mat-form-field>
    </div>
    <mat-option *ngFor="let option of filteredOptions" [value]="option" (click)="selectOption(option)">
      <ng-container *ngIf="displayImagePrefix">
        <span class="flex items-center">
          <span class="w-6 h-6 overflow-hidden" [style.background]="'url(' + option.img +') no-repeat 0 0'"
             [style.backgroundPosition]="'left'" [style.backgroundSize]="'contain'"></span>
          <span class="ml-2">{{option?.[displayProperty]}}</span>
        </span>
      </ng-container>
      <ng-container *ngIf="!displayImagePrefix">
        {{ option?.[displayProperty] }}
      </ng-container>
    </mat-option >
    <mat-option [disabled]="true" *ngIf="filteredOptions.length == 0">{{'nie_znaleziono_wynikow' | transloco}}</mat-option>
  </mat-select>
</mat-form-field>