import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { AppDB } from "../models/db";
import { Utils } from "../others/utils";

@Injectable({
    providedIn: 'root'
})
export class DbService implements OnDestroy {

    private _subscriptions: Subscription[] = [];

    private _db = new BehaviorSubject<AppDB>(null);
    currentDb = this._db.asObservable();

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe())
    }

    constructor() {

    }

    public closeConnection() {
        
        if(!Utils.isDefined(this._db,"value")) return

        this._db.value.close()
        this._db.next(null)

    }

    getDb() {
        return this._db.value
    }
    
    getDatabase(): Observable<AppDB> {
        return this._db;
    }

    setDatabaseByName(dbName) {
        let userId = dbName.split('-')[0]
        let rest = dbName.split('-')[1]
        let employeeId = rest.split('@')[0]

        // this.collision = new Collision();
        // this.collisionService.collision.next(this.collision);
        this._db.next(new AppDB(userId, employeeId));
    }

    async setDatabase(userId: number, employeeId:number | null) {
        // this.collision = new Collision();
        // this.collisionService.collision.next(this.collision);
        return new Promise((resolve)=> {
            this._db.next(new AppDB(userId, employeeId));
            resolve(true)
        })
    }

    resetDatabases(userId, employeeId): Observable<any> {
        let toReturn = new BehaviorSubject(null);
        this._db.value.delete().then(() => {
            console.warn("Database deleted successfully");
            toReturn.next(true)

        }).catch((err) => {
            toReturn.next(false)
            console.error("Could not delete database");
        }).finally(() => {
            this.setDatabase(userId, employeeId);
            // Do what should be done next...
        });

        return toReturn.asObservable();
    }

}