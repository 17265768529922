<div class="flex flex-row items-center justify-between">
    <div class="flex flex-row items-center justify-start px-0.5">
        <div class="min-w-0">
            <div class="font-medium leading-5 truncate">
                <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
                    <span class="flex items-center">
                        <mat-icon *ngIf="client?.clientType == 1" class="icon-size-5 mr-0"
                            [svgIcon]="'heroicons_solid:check-badge'" color="primary"></mat-icon>
                        <mat-icon *ngIf="client?.clientType == 2" class="icon-size-5 mr-0"
                            [svgIcon]="'heroicons_solid:no-symbol'" color="warn"></mat-icon>
                        {{client?.forename}}
                        {{client?.name}}
                    </span>
                </div>
            </div>
            <div class="leading-2 truncate text-secondary">
                <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
                    <div class="flex items-center" *ngIf="client?.phone != ''">
                        <mat-icon class="icon-size-3 text-hint mr-1" [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                        <div class="text-md text-secondary">
                            {{client?.phone}}
                        </div>
                    </div>
                    <div class="flex items-center" *ngIf="client?.email != ''">
                        <mat-icon class="icon-size-3 text-hint mr-1" [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
                        <div class="text-md text-secondary">
                            {{client?.email}}
                        </div>
                    </div>
                </div>
                <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
                    <div class="flex items-center" *ngIf="client?.comments != ''">
                        <mat-icon class="icon-size-3 text-hint mr-1"
                            [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                        <div class="text-md text-secondary">
                            {{client?.comments}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>