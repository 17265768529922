import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NotificationsService, PaginatedNotifications } from 'app/core/shared/services/notifications.service';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { Subject, take, takeUntil } from 'rxjs';
import { IcalNotificationComponent } from './ical-notification/ical-notification.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ApiService } from 'app/core/shared/services/api.service';
import { SynchronizationService } from 'app/core/shared/services/synchronization.service';
import { DbNotification } from 'app/core/shared/models/DbNotification';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDetailsDialogComponent } from 'app/core/shared/dialogs/notification-details-dialog/notification-details-dialog.component';
import { ChannelManagerImportNotificationComponent } from './channel-manager-import-notification/channel-manager-import-notification.component';
import { SubscriptionInfoNotificationComponent } from './subscription-info-notification/subscription-info-notification.component';
import { Utils } from 'app/core/shared/others/utils';
import { PanelService } from 'app/core/shared/services/panel.service';
import { ReservationsService } from 'app/core/shared/services/reservations.service';
import { PreBookingReservationNotificationComponent } from './pre-booking-reservation-notification/pre-booking-reservation-notification.component';
import { ReservationPaidNotificationComponent } from './reservation-paid-notification/reservation-paid-notification.component';
import { CanceledReservationNotificationComponent } from './canceled-reservation-notification/canceled-reservation-notification.component';
import { BookingEngineClientCancelationNotificationComponent } from './booking-engine-client-cancelation-notification/booking-engine-client-cancelation-notification.component';
import { SettingsService } from 'app/core/shared/services/settings.service';
import { UserPermissions } from 'app/core/shared/models/UserPermissions';
import { UserSettings } from 'app/core/shared/models/UserSettings';

const notificationComponents = [
    IcalNotificationComponent,
    ChannelManagerImportNotificationComponent,
    SubscriptionInfoNotificationComponent,
    PreBookingReservationNotificationComponent,
    ReservationPaidNotificationComponent,
    CanceledReservationNotificationComponent,
    BookingEngineClientCancelationNotificationComponent
]

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications',
    standalone     : true,
    imports        : [MatButtonModule, NgIf, TranslocoModule, ScrollingModule, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe, notificationComponents],
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: DbNotification[] = []
    unreadCount: number = 0;

    public permissions = new UserPermissions({})

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _notificationsService: NotificationsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _apiService:ApiService,
        private _synchronizationService:SynchronizationService,
        private _dialog:MatDialog,
        private _panelService:PanelService,
        private _reservationsService:ReservationsService,
        private _settingsService: SettingsService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

       this._notificationsService.currentNotifications
       .pipe(takeUntil(this._unsubscribeAll))
       .subscribe({
            next: (data) => {
                this.notifications = data
                this.notifications = this.notifications.sort((a,b)=> b.notificationId - a.notificationId)
                this._calculateUnreadCount()
            }
       })

       this._settingsService.currentUserSettings.subscribe({
            next: (data:UserSettings) => {
                if(Utils.isDefined(data, "permissions")) {
                    this.permissions = data.permissions
                    this._changeDetectorRef.markForCheck()
                }
            }
       })

    }

    async showDetails(notification) {

        this.markAsReaded(notification)

        if(notification.type == 3 || notification.type == 4 || notification.type == 5 || notification.type == 8) {
            const reservationId = Utils.getNestedValue(notification,"body.data.reservationId")
            
            const reservation = this._reservationsService.getById(reservationId)
            if(!reservation) return

            await this._panelService.openUpdateReservationPanel(reservation)
            return
        }

        // If notification is booking notification just show reservation in panel
        if(notification.type == 6) {
            const reservationId = Utils.getNestedValue(notification,"body.data.reservation.reservationId")
            
            const reservation = this._reservationsService.getById(reservationId)
            if(!reservation) return

            await this._panelService.openUpdateReservationPanel(reservation)
            return
        }

        this._dialog.open(NotificationDetailsDialogComponent, { 
            data: { 
                notification: notification
            },
            panelClass: 'mobile-calendar-responsive-dialog'
        })
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        // Return if the notifications panel or its origin is not defined
        if ( !this._notificationsPanel || !this._notificationsOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    async markAllAsRead()
    {
        // Mark all as read
        this._apiService.markNotificationsAsRead('all')
        .pipe(take(1))
        .subscribe({
            next: async (response:any) => {
                if(response.status == 'success') {
                    await this._synchronizationService.newSynchronize('After marking as readed')
                    this.notifications.forEach(notification=> {
                        notification.readed = 1
                    })
                    this._calculateUnreadCount()
                }
            }
        })
    }

    /**
     * Toggle read status of the given notification
     */
    async markAsReaded(notification:DbNotification)
    {
        if(notification.readed == 1) return
        /// Mark all as read
        this._apiService.markNotificationsAsRead(notification.notificationId)
        .pipe(take(1))
        .subscribe({
            next: async (response:any) => {
                if(response.status == 'success') {
                    await this._synchronizationService.newSynchronize('After marking as readed')
                    notification.readed = 1
                    this._calculateUnreadCount()
                }
            }
        })
    }

    /**
     * Delete the given notification
     */
    async deleteNotification(notification)
    {
        // Delete the notification
        this._apiService.deleteNotifications(notification.notificationId)
        .pipe(take(1))
        .subscribe({
            next: async (response:any) => {
                if(response.status == 'success') {
                    await this._synchronizationService.newSynchronize('After marking as readed')
                    notification.status = 0
                    this._calculateUnreadCount()
                }
            }
        })
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX : 'start',
                        originY : 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX : 'end',
                        originY : 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX : 'end',
                        originY : 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() =>
        {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter(notification => notification.readed == 0).length;
        }

        this.unreadCount = count;
        this._changeDetectorRef.detectChanges()
    }
}
