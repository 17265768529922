<fuse-card class="flex flex-col items-center max-w-90 w-full p-8 filter-info">
    <div class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600">
        <mat-icon
            class="icon-size-10 text-primary-500 dark:text-primary-300"
            [svgIcon]="'heroicons_outline:arrow-down-tray'"></mat-icon>
    </div>
    <div class="text-2xl font-semibold leading-tight text-center mt-6">
        {{'import_danych' | transloco}}
    </div>
    <div class="text-center text-secondary mt-3">
        {{"nie_zamykaj_przegladarki_w_trakcie_synchronizacji_danych" | transloco}}
    </div>
    <div class="w-full">
        <!-- import progress -->
        <ng-container *ngIf="progress < 100 && error == null">
            <div class="flex flex-col mt-4">
            <div class="flex items-center my-2">
                <div class="whitespace-nowrap mr-4">{{'pobrano' | transloco}}</div>
                <mat-progress-bar
                    class="my-2"
                    mode="determinate" [value]="progress"></mat-progress-bar>
                <div class="max-w-10 w-full text-right mx-1">{{progress.toFixed(2)}}%</div>
            </div>
        </div>
        </ng-container>

        <ng-container *ngIf="savingStatus != null">
            <div class="flex flex-col mt-4">
                <div class="flex items-center my-2">
                    <div class="whitespace-nowrap mr-4">{{'zapisano' | transloco}}</div>
                    <mat-progress-bar
                        class="my-2"
                        mode="determinate" [value]="savingStatusProgress"></mat-progress-bar>
                    <div class="max-w-10 w-full text-right mx-1">{{savingStatusProgress.toFixed(2)}}%</div>
                </div>
            </div>
        </ng-container>

        <!-- Saving data -->
        <ng-container *ngIf="progress == 100 && error == null && isSynchronizing == true">
            <fuse-alert [type]="'info'" [title]="'jeszcze_chwila' | transloco" [appearance]="'outline'" class="mt-6">
                {{"zapisujemy_twoje_dane_prosimy_o_chwile_cierplowosci" | transloco}}
            </fuse-alert>
        </ng-container>

        <!-- success -->
        <ng-container *ngIf="progress == 100 && error == null  && isSynchronizing == false">
            <fuse-alert [type]="'success'" [appearance]="'outline'" class="mt-6">
                {{"synchronizacja_udana" | transloco}}
            </fuse-alert>
        </ng-container>

        <!-- error -->
        <ng-container *ngIf="error != null">
            <fuse-alert [type]="'error'" [appearance]="'outline'" class="mt-6">
                {{"wystapil_blad_podczas_pobierania_danych" | transloco}}
            </fuse-alert>
            <button mat-flat-button class="px-6 mt-8 w-full" color="primary" (click)="reSync()">{{"synchronizuj_ponownie" | transloco}}</button>
        </ng-container>

    </div>
</fuse-card>
