import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'dexie';
import { Source } from '../../models/Source';
import { DialogService } from '../../services/dialog.service';
import { SnackbarService } from '../../services/snackbar.service';
import { MatListModule } from '@angular/material/list';
import { SynchronizationService } from '../../services/synchronization.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SourceAvatarComponent } from '../source-avatar/source-avatar.component';
import { SourcesService } from '../../services/sources.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { NgxColorsModule } from 'ngx-colors';
import { FormsModule } from '@angular/forms';
import { OrderByPipe } from '../../pipes/order-by.pipe';
import { Utils } from '../../others/utils';
import { SourcesApiService } from '../../services/api/sources.api.service';
import { IAPIResponse } from '../../services/app-init.service';
import { finalize, take } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-source-manage',
  templateUrl: './source-manage.component.html',
  styleUrls: ['./source-manage.component.scss'],
  imports: [CommonModule, MatButtonModule, OrderByPipe, MatIconModule, MatTooltipModule, MatDialogModule, TranslocoModule, FormsModule, MatListModule, SourceAvatarComponent, MatFormFieldModule, MatSelectModule, MatOptionModule, MatInputModule, MatTooltipModule, NgxColorsModule]
})
export class SourceManageComponent implements OnInit, OnDestroy {
  
  private _subscriptions: Subscription[] = [];
  sources: Source[] = [];
  builtInSources: Source[] = [];

  isProcessing = false
  defaultSourceColor = '#e2e8f0'
  sourceName: ''

  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private translate: TranslocoService,
    private dialog: MatDialogRef<SourceManageComponent>,
    private _sourcesService:SourcesService,
    private _sourcesApiService:SourcesApiService,
    private _synchronizationService: SynchronizationService
  ) 
  {
  
  }

  public onSourceNameChange(source, ev) {
    const name = ev.target.value
    source.name = name
    this._updateSource(source)
  }

  public onSourceColorChange(source, color) {
    this._updateSource(source)
  }

  public addSource() {
    if(Utils.isNullOrEmpty(this.sourceName)) return

    this.isProcessing = true

    const data = {
      name: this.sourceName,
      color: this.defaultSourceColor
    }

    this._sourcesApiService.create(data)
    .pipe(take(1))
    .pipe(finalize(()=> {
      this.isProcessing = false
    }))
    .subscribe({
      next: (response: IAPIResponse) => {
        if(response.status != 'success') {
          this.snackbarService.showSnackBar(this.translate.translate('nie_udalo_sie_utworzyc_zrodla'), "error")
          return
        }

        this.snackbarService.showSnackBar(this.translate.translate('utworzono_nowe_zrodlo'), "success")
        this.sourceName = ''
        this.defaultSourceColor = '#e2e8f0'

        // Synchronize
        this._synchronizationService.newSynchronize('Source manager')
      }
    })
  }

  public showConfirmDeleteSource(source) {
    if(!source.isCustom) return
    this.dialogService.confirmDeleteSource(source.sourceId, source.name).subscribe(
      {
        next: (data) => {
          if (data == true) {
            this.snackbarService.showSnackBar(this.translate.translate('zrodlo_zostalo_usuniete'), "success")
            this._synchronizationService.newSynchronize('Source manager')
          }
        }
      }
    )
  }

  public close() {
    this.dialogService.clearSubscriptions();
    this.dialog.close()
  }

  private _updateSource(source) {

    this.isProcessing = true
    const data = {name: source.name, color: source.color}
    this._sourcesApiService.update(source.sourceId, data)
    .pipe(take(1))
    .pipe(finalize(()=> {
      this.isProcessing = false
    }))
    .subscribe({
      next: (response: IAPIResponse) => {

        if(response.status != 'success') {
          this.snackbarService.showSnackBar(this.translate.translate('nie_udalo_sie_zmodyfikowac_zrodla'), "error")
          return
        }

        this.snackbarService.showSnackBar(this.translate.translate('zrodlo_zostalo_zmodyfikowane'), "success")
        // Synchronize
        this._synchronizationService.newSynchronize('Source manager')
      }
    })
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this._sourcesService.currentSources.subscribe({
        next: (data) => {
          setTimeout(() => {
            this.sources = data.filter(x=>x.isCustom)
            this.builtInSources = data.filter(x=>!x.isCustom)
          },10)
        }
    }))
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }
}
