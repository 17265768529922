import { Component, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'dexie';
import { finalize, take } from 'rxjs';
import { ApplicationData } from '../../data/application-data';
import { DbClient } from '../../models/DbClient';
import { DbReservation } from '../../models/DbReservation';
import { DbRoom } from '../../models/DbRoom';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { Utils } from '../../others/utils';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { HandlingService } from '../../services/handling.service';
import { PanelService } from '../../services/panel.service';
import { SnackbarService } from '../../services/snackbar.service';
import { SynchronizationService } from '../../services/synchronization.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { ClientsService } from '../../services/clients.service';
import { clientFilter } from '../../filters/client.filter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SettingsService } from '../../services/settings.service';
import { UserSettings } from '../../models/UserSettings';
import { ReservationHistoryComponent } from '../../components/reservation-history/reservation-history.component';
import { MatSearchSelectComponent } from '../../components/mat-search-select/mat-search-select.component';
import { DataChangeTrackingService } from '../../services/data-change-tracking.service';
import { ReservationsService } from '../../services/reservations.service';
import { ReservationRowComponent } from '../../components/reservation-row/reservation-row.component';
import { RoomsService } from '../../services/rooms.service';
import { DataSynchronizerService } from '../../services/data-synchronizer.service';

@Component({
  standalone: true,
  selector: 'app-create-or-update-client-dialog',
  templateUrl: './create-or-update-client-dialog.component.html',
  styleUrls: ['./create-or-update-client-dialog.component.scss'],
  imports: [CommonModule, MatTabsModule, MatSlideToggleModule, MatRadioModule, forwardRef(() => ReservationRowComponent), MatAutocompleteModule, MatBadgeModule, MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatIconModule, TranslocoModule, MatTooltipModule, ReactiveFormsModule, FormsModule, MatSearchSelectComponent]
})
export class CreateOrUpdateClientDialogComponent implements OnDestroy{
  
  subscriptions:Subscription[] = [];
  mode: 'create' | 'update' = 'create';

  clientForm = new FormGroup({
    clientId: new FormControl(null),
    name: new FormControl('', Validators.required),
    forename: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    countryId: new FormControl(null),
    countryName: new FormControl(''),
    idCard: new FormControl(''),
    companyName: new FormControl(''),
    taxId: new FormControl(''),
    personalId: new FormControl(''),
    vehicleRegistrationNumber: new FormControl(''),
    address: new FormControl(''),
    comments: new FormControl(''),
    lang: new FormControl(''),
    clientType: new FormControl(0)
  })

  tabIndex = 0
  show = {
    more_client_data: false,
  }

  countries = ApplicationData.Countries.map((c) => {
    return {
      id: c.id,
      name: this.translate.translate('country_' + c.id)
    }
  });

  languages = ApplicationData.Languages

  formFields: any = [];
  client;
  clientReservations: DbReservation[] = [];

  alertData = {
    title: this.translate.translate('usuniety_klient'),
    status: 'warning',
    message: this.translate.translate('usuniety_klient_info'),
    canDismiss: false
  }
  clientTypes = ApplicationData.ClientTypes


  processing = false;

  userSettings:UserSettings
  summary = {};
  summaryInCurrencies = [];
  reservations = []
  constructor(
    @Inject(DATA_INJECTION_TOKEN) public data: any,
    private dialogService: DialogService,
    private handlingService: HandlingService,
    private panelService: PanelService,
    public translate:TranslocoService,
    private apiService:ApiService,
    private snackbar:SnackbarService,
    private _synchronizationService:SynchronizationService,
    private _settingsService:SettingsService,
    private _dataChangeTrackingService:DataChangeTrackingService,
    private _reservationsService: ReservationsService,
    private _roomsService:RoomsService,
    private _dataSynchronizerService: DataSynchronizerService
  )
  {
    if (typeof data.mode == 'undefined') {
      throw new Error('Mode not set.');
    }

    this.mode = data.mode;

    // Edit client
    if (typeof data.client != 'undefined') {
      this.client = data.client;
      this.loadFormData(data.client);
      this.browseReservations()
    }

    this.subscriptions.push(this.clientForm.controls.countryName.valueChanges.subscribe(
      (data) => {
        if (data != null) {
          this.browseCountries(data);
        }
      }
    ))

    this.subscriptions.push(this._settingsService.getUserSettings().subscribe(userSettings => {

      this.userSettings = userSettings;
      if (Utils.isDefined(userSettings, "settings.reservationField")) {
        this.formFields = userSettings.settings.reservationField;
      }

    }))
  }

  browseReservations() {

    this.reservations = []

    if(this.mode != 'update') return
    if(Utils.isNullOrEmpty(this.client.clientId)) return

    // Get client reservations
    this.reservations = this._reservationsService.getClientReservations('reservations',this.client.clientId)

    // Include removed reservations
    this.reservations.push(...this._reservationsService.getClientReservations('reservations',this.client.clientId))
  }
  
  filteredCountries = this.countries;
  browseCountries(input: string) {
    this.filteredCountries = this.countries.map((c) =>  { 
      return {
        id: c.id,
        name: this.translate.translate('country_'+c.id)
      }
    }).filter(x => x.name.toLowerCase().includes(input.toLowerCase()));
  }

  setLanguage(language) {
    if(language) this.clientForm.controls.lang.setValue(language.shortName)
  }

  setCountry(country) {
    if (country == null || typeof country == 'undefined') {
      this.clientForm.controls.countryId.setValue(null)
      this.clientForm.controls.countryName.setValue('')
      return
    }
    this.clientForm.controls.countryId.setValue(country.id)
    this.clientForm.controls.countryName.setValue(this.translate.translate('country_' + country.id))
  }

  loadFormData(client:DbClient)
  {
    this.clientForm.controls.clientId.setValue(client.clientId)
    this.clientForm.controls.forename.setValue(client.forename)
    this.clientForm.controls.name.setValue(client.name)
    this.clientForm.controls.phone.setValue(client.phone)
    this.clientForm.controls.email.setValue(client.email)

    if (Utils.isDefined(client, "countryId")) {

      this.clientForm.controls.countryId.setValue(client.countryId);
      this.clientForm.controls.countryId.updateValueAndValidity();
      const country = ApplicationData.Countries.find(x => x.id == this.client.countryId);

      if (country && typeof country != 'undefined') {
        this.clientForm.controls.countryName.setValue(this.translate.translate('country_'+country.id));
        this.clientForm.controls.countryName.updateValueAndValidity();
      }
    }

    this.clientForm.controls.idCard.setValue(client.idCard)
    this.clientForm.controls.taxId.setValue(client.taxId)
    this.clientForm.controls.companyName.setValue(client.companyName)
    this.clientForm.controls.personalId.setValue(client.personalId)
    this.clientForm.controls.vehicleRegistrationNumber.setValue(client.vehicleRegistrationNumber)
    this.clientForm.controls.address.setValue(client.address)
    this.clientForm.controls.comments.setValue(client.comments)
    this.clientForm.controls.clientType.setValue(client.clientType)
    this.clientForm.controls.lang.setValue(client.lang)

    this.clientReservations.push(...client.reservations)
    this.clientReservations.push(...client.canceledReservations)

    this.clientReservations.sort((a:DbReservation,b:DbReservation)=> new Date(a.arrival).getTime() - new Date(b.arrival).getTime())
    this.clientReservations.forEach(element => {
      this.summaryInCurrencies = []
      if(Number.isNaN(element.price)) element.price = 0;
      if(typeof this.summary[element.currency] == 'undefined') this.summary[element.currency] = 0;
      this.summary[element.currency] += element.price;
      Object.entries(this.summary).forEach(sum=>{
        this.summaryInCurrencies.push({
          currency: sum[0],
          summary: sum[1]
        })
      })
    });
  }

  changeTab($event) {
    this.tabIndex = $event;
  }

  createClient() {

    if(!this.clientForm.valid) return;
    if(this.mode != 'create') return

    this.processing = true;
    this.apiService.createClient(this.clientForm.value)
    .pipe(take(1))
    .subscribe({
        next: (response)=>{
          if (response.status == 'success') {
            this.handlingService.handleResponse(response, 'dodano_klienta');
            this._synchronizationService.synchronizeWithPendingCheck("Added client")
            this.panelService.close();
          }
          else {
            this.snackbar.showSnackBar(this.translate.translate('wystapil_blad'), 'error');
          }
          this.processing = false;
        },
        error: (err)=>{   
          this.processing = false;
        }
    })
  }

  public updateClient() {
    if(!this.clientForm.valid) return
    if(this.mode != 'update') return
    this.processing = true;
    this.apiService.updateClient(this.clientForm.value)
    .pipe(take(1))
    .subscribe({
        next: (response)=>{
          if (response.status == 'success') {
            this.handlingService.handleResponse(response, 'klient_zostal_zaktualizowany');
            this._synchronizationService.synchronizeWithPendingCheck("Added client")
            this.panelService.close();
          }
          else {
            this.snackbar.showSnackBar(this.translate.translate('wystapil_blad'), 'error');
          }
          this.processing = false;
        },
        error: (err)=>{   
          this.processing = false;
        }
    })
  }

  getRoom(roomId):DbRoom {
    return this._roomsService.getById(roomId)
  }
 
  showReservation(reservation) {
    this.panelService.openUpdateReservationPanel(reservation)
  }

  deleteClient() {
    if(this.mode != 'update') return;

    this.processing = true;
    this.subscriptions.push(this.dialogService.confirmDialog({
      confirmText: this.translate.translate('usun'),
      message: this.translate.translate('czy_na_pewno_chcesz_usunac_klienta_i_jego_wszystkie_rezerwacje'),
      title: this.translate.translate('uwaga'),
      type: 'danger',
      cancelText: this.translate.translate('anuluj'),
      showIcon: true
    })
    .pipe(finalize(()=> {
      this.processing = false
    }))
    .subscribe({
        next: (data)=>{
          if(data == true) {
            this.apiService.deleteClient(this.clientForm.controls.clientId.value)
            .pipe(take(1))
            .pipe(finalize(()=> {
              this.processing = false
            }))
            .subscribe({
              next: (response) => {
                if (response.status == 'success') {
                  this.snackbar.showSnackBar(this.translate.translate('usunieto_klienta'), 'success');
                  this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
                  this._synchronizationService.newSynchronize("Removed client")
                  this.panelService.close();
                } else {
                  this.snackbar.showSnackBar(this.translate.translate('wystapil_blad'), 'error');
                }
              },
            })
          }
        }
      }
    ))
  }

  close()
  {
    this.panelService.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
