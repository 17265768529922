import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-error-503',
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatIconModule, MatButtonModule],
  templateUrl: './error-503.component.html',
  styleUrl: './error-503.component.scss'
})
export class Error503Component {
  constructor(
    private dialog: MatDialogRef<Error503Component>
  ){}
  close(){
    this.dialog.close()
  }
}
