import { Injectable, OnDestroy, inject } from "@angular/core";
import { Source } from "../models/Source";
import { AppDB } from "../models/db";
import { DbSource } from "../models/DbSource";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { TranslocoService } from "@ngneat/transloco";
import { DbTemplate } from "../models/DbTemplate";
import { Utils } from "../others/utils";

@Injectable({
  providedIn: 'root'
})
export class TemplatesService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _templates = new BehaviorSubject<DbTemplateObject>({})
  public currentTemplates: Observable<DbTemplateObject> = this._templates.asObservable()

  private _db: AppDB

  private _counter = new BehaviorSubject<DbTemplateCounter>({
    valid: 0,
    deleted: 0
  })
  // Clean subscriptions
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService,
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
          this._templates.next({})
        }
      })
    )

    this._subscriptions.push(this.currentTemplates.subscribe({
      next: (data) => {
        this._countAll(data)
      }
    }))


    // Add reservation on synchronize
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: (data) => {
          if (data.table != 'templates') return
          // Update
          this._processTemplates(data.value)
          this._dataSynchronizerService.received()
          this._templates.next(this._templates.value)

        }
      }))
  }

  getTemplates(): DbTemplateObject {
    return this._templates.value
  }

  public getCounter(): Observable<DbTemplateCounter> {
    return this._counter.asObservable()
  }

  public browse({ allResults = [], filters = [] }): PaginatedDbTemplates {
    let t1 = new Date().getTime();


    let searchingResults = allResults.length > 0 ? allResults : Object.values(this._templates.value)
      .sort((a: DbTemplate, b: DbTemplate) => b.templateId - a.templateId)

    if (filters.length > 0) {
      filters.forEach(filter => {
        searchingResults = searchingResults.filter(filter)
      })
    }

    const paginationResult: PaginatedDbTemplates = {
      time: new Date().getTime() - t1,

      resultsCount: searchingResults.length,
      allResults: searchingResults
    }

    return paginationResult

  }

  getById(templateId: number): DbTemplate {
    if (templateId == null) return null
    const reservation = this._templates.value[templateId]
    if (reservation) return reservation
    else console.warn(`Template with id: ${templateId} not found.`)

  }

  getAll(): Observable<boolean> {


    this._templates.next({});
    let startTime = 0

    if(!this._db) return of(true)
    const loader = from(this._db.transaction("r", this._db.templates, () => {
      startTime = performance.now();
      return this._db.templates.toArray();
    }))
      .pipe(
        map((templates: DbTemplate[]) => {
          this._processTemplates(templates);

          this._templates.next(this._templates.value);
          return true;
        }),
        tap(() => console.log(`[Templates Service]: Built in ${performance.now() - startTime}ms.`))
      );

    return loader
  }

  private _processTemplates(templates: DbTemplate[]): DbTemplate[] {

    const updatedTemplates: DbTemplate[] = []
    templates.forEach(template => {

      template = new DbTemplate(template)

      this._templates.value[template.templateId] = template;

      updatedTemplates.push(template)
    })
    return updatedTemplates
  }

  private _countAll(data) {
    let valid = 0
    let deleted = 0
    Object.values(data).forEach((template: DbTemplate) => {
      if (template.status == 1) valid++
      else deleted++
    })

    this._counter.next({ valid, deleted })
  }
}

export interface DbTemplateObject {
  [key: number]: DbTemplate;
}

export interface PaginatedDbTemplates {
  allResults: DbTemplate[]
  resultsCount: number
  time: number,
}
export interface DbTemplateCounter {
  valid: number,
  deleted: number
}