<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div class="pt-6 px-6 pb-3">
        <!-- header -->
        <div class="flex items-center justify-end w-full max-w-3xl mx-auto">
            <p class="text-lg font-medium">
                {{'dodaj_nowe_konto' | transloco}}
            </p>
            <div class="ml-auto">
                <!-- close -->
                <button mat-icon-button (click)="close()">
                    <mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                </button>
            </div>
        </div>
    </div><!-- end p-6 -->
    <div class="relative flex flex-col items-center">
        <!-- flex-auto -->
        <div class="w-full max-w-3xl">
            <!-- tabs -->
            <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)"
                class="vertically-tabs p-0-content" mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight
                dynamicWidth>
                <mat-tab>
                    <ng-template matTabLabel>{{'dodaj' | transloco}}</ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template matTabLabel>{{'polaczone_konta' | transloco}}</ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <ng-container>
                <!-- body -->
                <div class="px-6 py-4">
                    <ng-container *ngIf="tabIndex === 0">
                        <ng-container *ngTemplateOutlet="addAccount"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="tabIndex === 1">
                        <ng-container *ngTemplateOutlet="existingAccounts"></ng-container>
                    </ng-container>

                </div>
                <!-- footer -->
                <div
                    class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
                    <div class="flex items-center">
                        <button mat-button (click)="close()" class="ml-auto">{{'anuluj' | transloco}}</button>
                        <button mat-flat-button *ngIf="tabIndex == 0" [disabled]="form.invalid" color="primary"
                            (click)="submit()">
                            {{'dodaj' | transloco}}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<ng-template #addAccount>
    <form [formGroup]="form">
        <div class="grid sm:grid-cols-4 gap-6 w-full">
            <ng-container *ngIf="false">
                <div class="col-span-4">
                    <mat-radio-group formControlName="account" color="primary">
                        <mat-radio-button class="example-radio-button" [value]="0">{{'dodaj_istniejace_konto' |
                            transloco}}</mat-radio-button>
                        <br>
                        <mat-radio-button class="example-radio-button" [value]="1">
                            {{'zaloz_i_dodaj_nowe_konto' | transloco}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>
           
            <div class="col-span-4">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
                    <mat-label>{{'podaj_adres_email' | transloco}}</mat-label>
                    <input matInput type="text" formControlName="email">
                    <mat-error *ngIf="form.controls.email.hasError('required')">{{"to_pole_jest_wymagane" | transloco}}</mat-error> 
                     <mat-error *ngIf="form.controls.email.hasError('invalidEmail')">{{"nieprawidlowy_adres_email" | transloco}}</mat-error> 
                </mat-form-field>
            </div>
            <div class="col-span-4">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'podaj_haslo' | transloco}}</mat-label>
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:lock-closed'"></mat-icon>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
                    <button mat-icon-button matSuffix (click)="hide = !hide">
                        <mat-icon matPrefix
                            [svgIcon]="hide ? 'heroicons_solid:eye' : 'heroicons_solid:eye-slash'"></mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('required') || form.get('password').hasError('minlength')">
                        {{'haslo_powinno_zawierac_info' | transloco}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>





    </form>
</ng-template>


<ng-template #existingAccounts>
    <ng-container *ngIf="connectedAccounts?.length > 0; else noAccounts">
        <ng-container *ngFor="let account of connectedAccounts">
            <div class="z-20 flex items-center py-4 border-b">
                <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
                    <ng-container *ngIf="0">
                        <img class="object-cover w-full h-full" [src]="contact.avatar" alt="Contact avatar" />
                    </ng-container>
                    <ng-container *ngIf="1">
                        <div
                            class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                            {{(account.email).charAt(0)}}
                        </div>
                    </ng-container>
                </div>
                <div class="min-w-0 ml-4">
                    <div class="font-medium leading-5 truncate">{{account.email}}</div>
                    <div class="leading-5 truncate text-secondary">{{account.hotelName || 'brak' | transloco}}</div>
                </div>
                <div class="flex flex-col items-end justify-end min-w-18 ml-auto pl-2">
                    <div>
                        <button [disabled]="ics.offlineMode.value == true && !account.isOfflineModeAvailable"
                        (click)="relogin(account)" mat-icon-button
                        [matTooltip]="ics.offlineMode.value == true && account?.isOfflineModeAvailable == false ? ('konto_niedostepne_w_trybie_offline' | transloco) : ('logowanie' | transloco)"
                        matTooltipPosition="above">
                        <mat-icon [svgIcon]="'heroicons_solid:arrow-long-right'"></mat-icon>
                    </button>

                    <button [disabled]="ics.offlineMode.value == true && !account.isOfflineModeAvailable"
                        (click)="unlinkAccount(account)" mat-icon-button
                        [matTooltip]="'odlacz_konto' | transloco" matTooltipPosition="above">
                        <mat-icon [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                    </button>
                    </div>
                    

                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #noAccounts>
    <fuse-alert [type]="'info'" [appearance]="'outline'">
        {{"brak_polaczonych_kont" | transloco}}
    </fuse-alert>
</ng-template>