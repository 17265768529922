import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  standalone:true,
  selector: '[integerOnly][integerOnlyMinValue]'
})
export class IntegerOnlyDirective {

  @Input('integerOnlyMinValue') minValue: number = null
  @Input('integerOnlyMaxValue') maxValue: number = null

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = this.el.nativeElement.value;
    let newValue = initialValue.replace(/[^0-9]/g, '');

    if(typeof newValue != 'number') {
      newValue = Number(newValue)
    }

    // Is not a number
    if(Number.isNaN(newValue)) {
      this.control.control.setValue(this.minValue)
      return
    }

    // If is lower
    if(this.minValue && newValue < this.minValue) {
      this.control.control.setValue(this.minValue)
      return
    }

    // If is higher
    if(this.maxValue && newValue > this.maxValue) {
      this.control.control.setValue(this.maxValue)
      return
    }


    if (newValue !== '0') {
      this.control.control.setValue(Number(newValue));
    } else {
      this.control.control.setValue(this.minValue);
    }

    // if(this.maxValue != null && this.maxValue > 0 && newValue > this.maxValue){
    //   this.control.control.setValue(this.maxValue);
    // }
  }
}