import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Custom validator function for URL validation
export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const urlPattern = /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
    if (!urlPattern.test(control.value)) {
      return { 'invalidUrl': { value: control.value } };
    }
    return null;
  };
}

export function priceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const pattern = /^\d+([.,]\d{1,2})?$/;
    if (control.value !== '' && !pattern.test(control.value)) {
      return { 'invalidPrice': true };
    }
    return null;
  };
}

export function positiveNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const positiveNumberPattern = /^[1-9]\d*$/; // Regular expression to match numbers greater than 0
    const isValid = positiveNumberPattern.test(value);
    return isValid ? null : { 'notPositiveNumber': { value: control.value } };
  };
}

export function notEqualValidator(notEqualValue: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue = control.value;

    if (inputValue === notEqualValue) {
      return { notEqual: true }; // Validation failed
    }

    return null; // Validation passed
  };
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const valid = emailRegex.test(control.value);
    return valid ? null : { invalidEmail: true };
  };
}

export function smtpHostValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // If the value is empty, consider it valid (use required validator for non-empty validation)
    if (!value) {
      return null;
    }

    // Regular expression to match domain or subdomain with extension, but not allow http:// or any ://
    const domainPattern = /^(?!.*:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    // Test the value against the regular expression
    const valid = domainPattern.test(value);

    // Return validation error if the value is invalid
    return valid ? null : { invalidSmtpHost: true };
  };
}


export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Wyrażenie regularne dla numeru telefonu bez + i ()
    const phoneRegex = /^[0-9]{1,4}[-. ]?[0-9]{1,4}[-. ]?[0-9]{1,9}$/;

    // Sprawdzenie, czy numer telefonu pasuje do wzorca
    const valid = phoneRegex.test(control.value);
    return valid ? null : { invalidPhone: true };
  };
}

export function compareValidator(controlNameToCompare: string) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.parent == null) return null;
    const controlToCompare = control.parent.controls[controlNameToCompare];

    if (controlToCompare && controlToCompare.value !== control.value) {
      return { compare: true };
    }

    return null;
  };
}
