export class DbSynchronization {

    constructor(id, lastSync, migrationVersion){
        this.id = id;
        this.lastSync = lastSync
        this.migrationVersion = migrationVersion
    }

    public id:                number;
    public lastSync?:         string;
    public migrationVersion?:         string;
}