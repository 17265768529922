import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { api_v1 } from "environments/environment";

  @Injectable({
    providedIn: 'root'
})
export class SourcesApiService {
    constructor(
        private http: HttpClient,
      ) {
    
      }

      /**
       * Create source
       * @param sourceId 
       * @returns 
       */
      create(data) {
        return this.http.post<any>(api_v1 + `sources`, data);
      }
      /**
       * Update source
       * @param sourceId 
       * @returns 
       */
      update(sourceId, data) {
        return this.http.put<any>(api_v1 + `sources/${sourceId}`, data);
      }
      /**
       * Delete source by id
       * @param sourceId 
       * @returns 
       */
      delete(sourceId) {
        return this.http.delete<any>(api_v1 + `sources/${sourceId}`);
      }
    
    }