import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy,  Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';


@Component({
    selector       : 'user-settings',
    templateUrl    : './user-settings.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user-settings',
    standalone     : true,
    imports        : [MatButtonModule, RouterModule, LocalizeRouterModule, MatMenuModule, NgIf, MatIconModule, NgClass, MatDividerModule],
})
export class UserSettingsComponent
{
}
