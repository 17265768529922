import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  standalone: true,
  selector: 'history-key-to-text',
  templateUrl: './history-key-to-text.component.html',
  styleUrls: ['./history-key-to-text.component.scss'],
  imports: [TranslocoModule, CommonModule]
})
export class HistoryKeyToTextComponent implements OnChanges {

  @Input('key') key = null;
  @Input('color') color: 'default' | 'added' | 'updated' | 'deleted' = 'default';
  @Input('type') type: 'normal' = 'normal'
  @Input('hasFlag') hasFlag: boolean = false
  @Input('flag') flag: '' = null

  @Input('lowercase') lowercase = true;
  keyResult = '';
  translations = [
    { key: 'adultsPortion', translation: 'dorosli_porcje' },
    { key: 'childrenPortion', translation: 'dzieci_porcje' },
    { key: 'isLocked', translation: 'zamkniety' },
    { key: 'checkIn', translation: 'godzina_przyjazdu' },
    { key: 'checkOut', translation: 'godzina_wyjazdu' },
    { key: 'paymentStatus', translation: 'status_platnosci' },
    { key: 'clientId', translation: 'klient' },
    { key: 'clientType', translation: 'status_klienta' },
    { key: 'vehicleRegistrationNumber', translation: 'nr_rejestracyjny_pojazdu' },
    { key: 'prepaymentDeadline', translation: 'termin_zadatku' },
    { key: 'additionalService=>price', translation: 'cene_uslugi_dodatkowej' },
    { key: 'additionalService=>status', translation: 'status_platnosci_za_usluge_dodatkowa' },
    { key: 'pricePerMeal', translation: 'cena_za_wyzywienie' },
    { key: 'pricePerRoomPerNight', translation: 'cena_za_pokoj_za_dobe' },
    { key: 'phone', translation: 'telefon' },
    { key: 'name', translation: 'nazwa' },
    { key: 'description', translation: 'opis' },
    { key: 'discountType', translation: 'typ_rabatu' },
    { key: 'square', translation: 'metraz' },
    { key: 'kidsAge', translation: 'wiek_dzieci' },
    { key: 'adults', translation: 'liczba_doroslych' },
    { key: 'shareInOffer', translation: 'udostepnianie_w_ofercie' },
    { key: 'discount', translation: 'rabat' },
    { key: 'comments', translation: 'uwagi' },
    { key: 'forename', translation: 'imie' },
    { key: 'registration', translation: 'meldunek' },
    { key: 'type', translation: 'typ' },
    { key: 'meal', translation: 'wyzywienie' },
    { key: 'currency', translation: 'waluta' },
    { key: 'sourceId', translation: 'zrodlo' },
    { key: 'arrival', translation: 'przyjazd' },
    { key: 'departure', translation: 'wyjazd' },
    { key: 'prepayment', translation: 'zaliczka' },
    { key: 'doorCode', translation: 'kod_do_drzwi' },
    { key: 'service', translation: 'status_czystosci' },
    { key: 'color', translation: 'kolor' },
    { key: 'serviceInfo', translation: 'zadania' },
    { key: 'additionalService', translation: 'usluge_dodatkowa' },
    { key: 'additionalInfo', translation: 'dodatkowe_informacje' },
  ]

  constructor(
    public translate: TranslocoService
  ) {

  }

  updateKeyResult(key) {
    if (key == '' || typeof key == 'undefined' || key == null) return;
    const element = this.translations.find(x => x.key == key);
    if (typeof element == 'undefined') { this.keyResult = this.translate.translate(key); return; }


    this.keyResult = this.translate.translate(element.translation);

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.key) {
      this.updateKeyResult(changes.key.currentValue)
    }
  }
}
