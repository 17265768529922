import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { DateFormatterComponent } from 'app/core/shared/components/date-formatter/date-formatter.component';
import { DbNotification } from 'app/core/shared/models/DbNotification';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'subscription-info-notification',
  standalone: true,
  imports: [CommonModule, DateFormatterComponent, JsonPipe, MatIconModule, TranslocoModule],
  templateUrl: './subscription-info-notification.component.html',
  styleUrl: './subscription-info-notification.component.scss'
})
export class SubscriptionInfoNotificationComponent {
  
  @Input() notification:DbNotification = null
  constructor() {

  }

 
  
}
