class Guest {
    address: string;
    cc_cvc: string;
    cc_expiration_date: string;
    cc_name: string;
    cc_number: string;
    cc_type: string;
    city: string;
    company: string[];
    countrycode: string;
    dc_issue_number: string[];
    dc_start_date: string[];
    email: string;
    first_name: string;
    last_name: string;
    remarks: string[];
    telephone: string;
    zip: string[];

    constructor(data: any) {
        this.address = data.address;
        this.cc_cvc = data.cc_cvc;
        this.cc_expiration_date = data.cc_expiration_date;
        this.cc_name = data.cc_name;
        this.cc_number = data.cc_number;
        this.cc_type = data.cc_type;
        this.city = data.city;
        this.company = data.company || [];
        this.countrycode = data.countrycode;
        this.dc_issue_number = data.dc_issue_number || [];
        this.dc_start_date = data.dc_start_date || [];
        this.email = data.email;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.remarks = data.remarks || [];
        this.telephone = data.telephone;
        this.zip = data.zip || [];
    }
}

class PriceDetail {
    amount: string;
    currency: string;
    included: string;
    per_night: string;
    per_person: string;
    percentage: string;
    text: string;

    constructor(data: any) {
        this.amount = data.amount;
        this.currency = data.currency;
        this.included = data.included;
        this.per_night = data.per_night;
        this.per_person = data.per_person;
        this.percentage = data.percentage;
        this.text = data.text;
    }
}

class PriceDetailsSubject {
    guest: PriceDetails
    hotel: PriceDetails

    constructor(data) {
        this.guest = new PriceDetails(data.guest)
        this.hotel = new PriceDetails(data.hotel)
    }
}

class PriceDetailAttributes {
    "@attributes": FeeAttributes

    constructor(obj) {
        this["@attributes"] = new FeeAttributes(obj['@attributes'])
    }
}
class PriceAttributes {
    "@attributes": RateAttributes

    constructor(obj) {
        this["@attributes"] = new RateAttributes(obj['@attributes'])
    }
}

class RateAttributes {
    date : string
    genius_rate : string
    nodeValue : string
    rate_id : string
    rewritten_from_id : string
    rewritten_from_name : string

    constructor(obj) {
        Object.keys(obj).forEach(key=> {
            this[key] = obj[key]
        })  
    }
    
}

class FeeAttributes {
    amount : string
    currency: string
    included : string
    per_night: string
    per_person: string
    percentage: string
    text: string

    constructor(obj) {
        Object.keys(obj).forEach(key=> {
            this[key] = obj[key]
        })
    }
}

class PriceDetails {
    extracomponent: PriceDetailAttributes[];
    total: string;

    constructor(data: any) {

        Object.keys(data).forEach(key => {

            // if(typeof data[key] == 'undefined') {
            //     console.log(`[BookingReservationResponse] Object doesn have field: ${key}`)
            //     return
            // }

            if (key == 'extracomponent') {
                if (Array.isArray(data.extracomponent)) this.extracomponent = data.extracomponent.map((item: any) => new PriceDetailAttributes(item));
                else console.log(`[BookingReservationResponse] PriceDetails extracomponent is not array: ${key}`, data.extracomponent)
            }
            else this[key] = data[key]
        })
    }
}

export class BookingRoomReservation {
    arrival_date: string;
    cancel_penalties: { cancel_penalty: any[] };
    commissionamount: string;
    currencycode: string;
    departure_date: string;
    extra_info: string;
    facilities: string;
    guest_name: string;
    id: string;
    info: string;
    max_children: string;
    meal_plan: string;
    name: string;
    numberofguests: string;
    occupancy: string;
    price: any;
    price_details: any;
    remarks: string[];
    roomreservation_id: string;
    smoking: string;
    totalprice: string;

    constructor(data: any) {

        Object.keys(data).forEach(key => {
            // if(typeof data[key] == 'undefined') {
            //     console.log(`[BookingReservationResponse] Object doesn have field: ${key}`)
            //     return
            // }
            // if (key == 'price_details') {
            //     this.price_details = new PriceDetailsSubject(data.price_details);
            // }
            // else if (key == 'price') {
            //     if (Array.isArray(data.price)) this.price = data.price.map((price: any) => new PriceAttributes(price));
            // } else 
            this[key] = data[key]
        })

    }
}

class Booking {
    booked_at: string;
    commissionamount: string;
    currencycode: string;
    date: string;
    guest_counts: { guest_count: { "@attributes": { count: string; type: string } } };
    hotel_id: string;
    hotel_name: string;
    id: string;
    modified_at: string;
    reservation_extra_info: { flags: { flag: { "@attributes": { name: string } } } };
    status: string;
    time: string;
    totalprice: string;
    customer: Guest;
    room: BookingRoomReservation[];

    constructor(data: any) {

        Object.keys(data).forEach(key => {
            // if(typeof data[key] == 'undefined') {
            //     console.log(`[BookingReservationResponse] Object doesn have field: ${key}`)
            //     return
            // }
            if (key == 'customer') {
                this.customer = new Guest(data.customer);
            }
            else if (key == 'room') {
                if (Array.isArray(data.room)) this.room = data.room.map((room: any) => new BookingRoomReservation(room));
                else console.log(`[BookingReservationResponse] Room is not array: ${key}`, data.room)
            }
            else this[key] = data[key]
        })

    }
}

class RoomBooking {
    arrival_date: string;
    cancel_penalties: { cancel_penalty: any[] };
    commissionamount: string;
    currencycode: string;
    departure_date: string;
    extra_info: string;
    facilities: string;
    guest_name: string;
    id: string;
    info: string;
    max_children: string;
    meal_plan: string;
    name: string;
    numberofguests: string;
    occupancy: string;
    price: { "@attributes": any }[];
    price_details: PriceDetails;
    remarks: string[];
    roomreservation_id: string;
    smoking: string;
    totalprice: string;

    constructor(data: any) {
        Object.keys(data).forEach(key => {

            // if(typeof data[key] == 'undefined') {
            //     console.log(`[BookingReservationResponse] Object doesn have field: ${key}`)
            //     return
            // }
            if (key == 'price_details') {
                this.price_details = new PriceDetails(data.price_details);
            }

            else this[key] = data[key]
        })
    }
}

export class BookingReservationResponse {
    booking: Booking
    room_booking: RoomBooking

    constructor(obj) {
        this.booking = new Booking(obj.booking)
        this.room_booking = new RoomBooking(obj.room_booking)
    }
}
