<div class="relative flex flex-col w-full sm:max-w-240 sm:min-w-120 max-h-full sm:max-h-screen">
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <p class="text-lg font-medium" *ngIf="mode == 'create'">
            {{'dodaj_usluge_dodatkowa' | transloco}}
        </p>
        <p class="text-lg font-medium" *ngIf="mode == 'update'">
            {{'edytuj_usluge_dodatkowa' | transloco}}
        </p>
        <div class="ml-auto text-white">
            <!-- close -->
            <button mat-icon-button (click)="close()"  mat-dialog-close>
                <mat-icon class="text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-scroll">
            <div class="grid sm:grid-cols-4 gap-6 w-full">

                <div class="sm:col-span-4">
                    <mat-checkbox color="primary" [formControl]="form.controls.showOtherLanguages" class="-ml-2">{{'pokaz_inne_jezyki' | transloco}}</mat-checkbox>
                    
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'"
                    *ngIf="form.controls.showOtherLanguages.value === true">
                    <mat-label>{{'jezyk' | transloco}}</mat-label>
                    <mat-select [formControl]="form.controls.language">
                        <mat-option value="default">
                            {{'domyslny' | transloco}} <small class="text-secondary ms-2">
                                ({{'wymagane' | transloco}})
                            </small>
                        </mat-option>
                        <mat-option [value]="lang.shortName" *ngFor="let lang of languages">
                            <div class="flex items-center">
                                <img [src]="lang.img" alt="" class="me-2">
                                {{lang?.name}}
                                <small *ngIf="name?.[lang.shortName]?.length == 0 || name?.[lang.shortName] == null" class="text-secondary ms-2">
                                    ({{'brak' | transloco}})
                                </small>
                            </div>
                        </mat-option>
                    </mat-select>
                  
                </mat-form-field>
                </div>
                <div class="sm:col-span-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                        <mat-label>{{'nazwa' | transloco}}</mat-label>
                        <input [formControl]="form.controls.nameTemp" matInput type="text" />
                        <mat-error *ngIf="name?.default?.length == 0 || name?.default == null">
                            {{'uzupelnij_domyslna_nazwe' | transloco}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="sm:col-span-4">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                        <mat-label>{{'opis' | transloco}}</mat-label>
                        <textarea [formControl]="form.controls.descriptionTemp" matInput rows="3"></textarea>
                        
                    </mat-form-field>
                </div>

                <div class="sm:col-span-4">
                    <hr class="my-6">
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:clock'"></mat-icon>
                        <mat-label>{{'okres' | transloco}}</mat-label>
                        <mat-select [formControl]="form.controls.period" matInput>
                            <mat-option [value]="0">{{'za_dobe' | transloco}}</mat-option>
                            <mat-option [value]="1">{{'za_pobyt' | transloco}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-icon matPrefix [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                        <mat-label>{{'platnosc' | transloco}}</mat-label>
                        <mat-select matInput [formControl]="form.controls.type">
                            <mat-option [value]="0">{{'za_usluge' | transloco}}</mat-option>
                            <mat-option [value]="1">{{'za_osobe' | transloco}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{form.controls.type.value == 1 ? ('cena_dorosli' | transloco) : ('cena' | transloco)}}</mat-label>
                        <input replaceCommaByDot matInput [formControl]="form.controls.priceAdult" type="text" />
                        <span matSuffix>{{currency}}</span>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2" *ngIf="form.controls.type.value == 1">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'cena_dzieci' | transloco}}</mat-label>
                        <input replaceCommaByDot matInput [formControl]="form.controls.priceChildren" type="text" />
                        <span matSuffix>{{currency}}</span>
                    </mat-form-field>
                </div>

                <div class="sm:col-span-2">
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>{{'stawka_vat' | transloco}}</mat-label>
                        <input matInput [formControl]="form.controls.taxRate" type="text" />
                    </mat-form-field>
                </div>
                

            </div>
        </div>
    </div>

    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">
        <div class="flex items-end">
          <div class="ml-auto">
                <button [disabled]="isProcessing" (click)="confirmDelete()" color="warn" class="me-2" mat-button *ngIf="mode == 'update'">
                    {{"usun" | transloco}}
                </button>

                <button mat-button (click)="close()" class="mc-button ml-auto">{{'anuluj' | transloco}}</button>

                <button mat-raised-button color="primary" [disabled]="form.invalid || isProcessing" (click)="createOrUpdate()">
                    {{mode == 'create' ? ( 'dodaj' | transloco ) : ('zapisz_zmiany' | transloco)}}
                </button>
            </div>
        </div>
    </div>
</div>