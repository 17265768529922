<div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
    <div class="w-full sm:w-auto py-8 px-4 sm:p-12 sm:rounded-2xl sm:bg-card">
        <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
            <div class="w-12">
                <img src="/assets/images/logo/logo.svg">
            </div>
            <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">{{'brak_dostepu' | transloco}}</div>
            <div class="mt-0.5">
                <ng-container *ngIf="reason == 'PRO_REQUIRED'">
                    {{'ta_funkcja_wymaga_rozszerzenia_subskrypcji_do_wyzszej_wersji' | transloco}}
                    <span class="px-2 py-1 bg-primary text-white rounded-full text-sm font-medium">PRO</span>
                </ng-container>
                <ng-container *ngIf="reason == 'ROOM_LIMIT_EXCEED'">
                    {{'aby_zwiekszyc_liczbe_pokoi_musisz_rozszerzyc_subskrypcje' | transloco}}
                </ng-container>
            </div>
            <div class="mt-8">
                <button
                    (click)="onExtendSubscriptionButtonClick.emit(true)"
                    [@shake]="true"
                    class="fuse-mat-button-large w-full mt-3"
                    mat-flat-button
                    type="button"
                    [color]="'primary'" [routerLink]="'/app/my-account/subscription' | localize">
                    {{'rozszerz_subskrypcje' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>
