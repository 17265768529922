import { Component, OnDestroy } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { DialogService } from '../../services/dialog.service';
import { HandlingService } from '../../services/handling.service';
import { InternetConnectionService } from '../../services/internet-connection.service';
import { PanelService } from '../../services/panel.service';
import { SettingsService } from '../../services/settings.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { ReloginService } from '../../services/relogin.service';
import { emailValidator } from '../../directives/validators.directive';
import { SnackbarService } from '../../services/snackbar.service';
import { IAPIResponse } from '../../services/app-init.service';

@Component({
  standalone: true,
  selector: 'app-add-new-account',
  templateUrl: './add-new-account.component.html',
  styleUrls: ['./add-new-account.component.scss'],
  imports: [MatTabsModule, MatIconModule, MatInputModule, MatDividerModule,MatButtonModule, MatTooltipModule, TranslocoModule, CommonModule, MatRadioModule, MatFormFieldModule, FuseAlertComponent, ReactiveFormsModule, FuseAlertComponent]
})
export class AddNewAccountComponent implements OnDestroy {

  subscriptions: Subscription[] = []
  hide = true;

  tabIndex = 0;

  changeTab($event) {
    this.tabIndex = $event;
  }

  form = new UntypedFormGroup({
    account: new UntypedFormControl(0, [ Validators.required ]),
    email: new UntypedFormControl('', [ Validators.required, emailValidator() ]),
    password: new UntypedFormControl('', [ Validators.required, Validators.minLength(8) ]),
  })

  connectedAccounts = []
  constructor(
    public ics: InternetConnectionService,
    public translate: TranslocoService,
    private _panelService: PanelService,
    private _settingsService: SettingsService,
    private _dialogService: DialogService,
    private _apiService: ApiService,
    private _handlingService: HandlingService,
    private _reloginService:ReloginService,
    private _snackbarService:SnackbarService
  ) {
    this.subscriptions.push(this._settingsService.getUserSettings().subscribe({
      next: (data) => {
        this.connectedAccounts = data.connectedAccounts;
      }
    }))
  }


  relogin(user) {
    this._reloginService.relogin(user)
  }


  public unlinkAccount(account) {

    this._dialogService.confirmDialog({
      title: this.translate.translate('odlaczenie_konta'),
      message: this.translate.translate('czy_na_pewno_chcesz_odlaczyc_to_konto'),
      type: 'info',
      confirmText: this.translate.translate('tak'),
      cancelText: this.translate.translate('anuluj')
    }).subscribe({
      next: (data) => {

        if (data != true) return

        this._apiService.unlinkAccount(account.userId)
          .pipe(take(1))
          .subscribe({
            next: (data: any) => {

              if (data.status == 'error') {
                this._handlingService.handleErrorsInSnackbar(data)
                return
              }

              if (data.status == 'success') {
                this._handlingService.handleResponse(data, "konto_zostalo_odloczone")
              }

              this._apiService.getUserSettings().pipe(take(1)).subscribe({
                next: (response:IAPIResponse) => { 
                if(response.status == 'success') {
                  this._settingsService.updateUserSettings(response.data)
              }}})
          }})
      }

    })
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  close() {
    this._panelService.close();
  }

  submit() {

    if(this.form.invalid) return

    const action = this.form.controls.account.value
    if(action != 0 && action != 1) return

    // 0 = just connect
    // 1 = connect and register new account
    if(action == 0) {
      this._connetctToAccount()
      return
    }

    if( action == 1) {
      this._addNewAccount()
      return
    }

  }

  private _connetctToAccount() {
    this._apiService.linkAccount(this.form.value).pipe(take(1))
    .subscribe({
      next: (response:IAPIResponse) => {

        if(response.status != 'success') {
          this._snackbarService.showSnackBar(this.translate.translate('nie_udalo_sie_dodac_konta'), 'error')
          return
        }

        this.tabIndex = 1
        this._snackbarService.showSnackBar(this.translate.translate('konto_zostalo_dodane'), 'success')

        this._apiService.getUserSettings().pipe(take(1)).subscribe({
          next: (response:IAPIResponse) => { 
          if(response.status == 'success') {
            this._settingsService.updateUserSettings(response.data)
        }}})

        this.form.controls.password.reset()
      }
  })}

  private _addNewAccount() {
    if(this.form.invalid) return
    this._apiService.linkAndCreateAccount(this.form.value).pipe(take(1))
    .subscribe({
      next: (response:IAPIResponse) => {

        if(response.status != 'success') {
          this._snackbarService.showSnackBar(this.translate.translate('nie_udalo_sie_dodac_konta'), 'error')
          return
        }

        this.tabIndex = 1
        this._snackbarService.showSnackBar(this.translate.translate('konto_zostalo_dodane'), 'success')

        this._apiService.getUserSettings().pipe(take(1)).subscribe({
          next: (response:IAPIResponse) => { 
          if(response.status == 'success') {
            this._settingsService.updateUserSettings(response.data)
        }}})

        this.form.controls.password.reset()
      }
  })}

}
