import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataLoaderService } from './core/shared/services/data-loader.service';
import { ResolverStateService } from './core/shared/services/resolver-state.service';
import { AuthService } from './core/auth/auth.service';
import { DbService } from './core/shared/services/db.service';
import { ResolveFn } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DataResolver {

    private _dataLoader = inject(DataLoaderService)
    private _authService = inject(AuthService)
    private _dbService = inject(DbService)
    private _resolverStateService = inject(ResolverStateService)
    
    resolve: ResolveFn<any> = (): Observable<any> | Promise<any> | any => {

        if (!this._resolverStateService.isResolverEnabled()) {
            return of(null); // Skip resolver
        }

        // If user is not logged in do not try to get strategy
        if(!this._authService.isLoggedIn()) {
            return of(null)
        }

        // If database is null do not try to get strategy
        if(!this._dbService.getDb() == null) {
            return of(null)
        }

        // Your existing logic to load data
        return this._dataLoader.getStrategy();
    }
}