import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { NgxMatColorPickerComponent } from '@angular-material-components/color-picker';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmDialogData } from '../../models/confirm-dialog-data';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NgxColorsModule } from 'ngx-colors';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  standalone: true,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  imports: [MatTabsModule, MatInputModule, NgxColorsModule, MatDialogModule, ReactiveFormsModule, MatCheckboxModule, MatIconModule, NgIf, NgFor, NgSwitch, NgSwitchCase, MatButtonModule, MatIconModule, NgClass]
})
export class ConfirmDialogComponent {

  @ViewChild('colorPicker') colorPicker: NgxMatColorPickerComponent;
  colorPickerControls: "default" | "only-alpha" | "no-alpha" = "no-alpha";

  public form: FormGroup = new FormGroup({

  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private dialog: MatDialogRef<ConfirmDialogComponent>,
    public translate:TranslocoService
    ) {
    if (typeof data.showIcon == 'undefined') { data.showIcon = true; }

    if (typeof data.inputs != 'undefined' && data.inputs.length > 0) {
      for (let control of data.inputs) {
        let validators: ValidatorFn[] = [];
        if (control.validators) {
          validators = control.validators;
        }
        
        this.form.addControl(control.name, new FormControl(control.value, validators));

        this.form.controls[control.name].updateValueAndValidity()
        if(control.type == 'color') {
          this.form.addControl(control.name+'Pckr', new FormControl(control.value, validators));
          // this.form.controls[control.name].valueChanges.subscribe(data=>this.form.controls[control.name+'Pckr'].setValue(data))
        }
      }
      this.form.updateValueAndValidity()
    }

  }

  updateColor(ev) {
    this.form.controls.color.setValue(ev)
    this.form.controls.colorPckr.setValue(ev)
  }

  close(action) {
    this.dialog.close({
      action: action,
      form: this.form
    })

  }
}
