import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';

@Injectable({
  providedIn: 'root'
})
export class CalendarNavigationService implements OnDestroy{

  subscriptions: Subscription[] = [];
  calendarNavigation = new BehaviorSubject<CalendarNavigation>(null);
  currentCalendarNavigation = this.calendarNavigation.asObservable();
  

  constructor(
    private router:Router,
    private localize:LocalizeRouterService
  )
  {
    
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  navigateToReservation(roomId, reservationId) {

    if(!roomId || !reservationId) return
    this.calendarNavigation.next({
      isWaitingToNavigate:true,
      isNavigated: false,
      roomId : roomId,
      reservationId: reservationId
    })

    const trasnlatedPath = this.localize.translateRoute('/app/calendar')
    this.router.navigate([trasnlatedPath])
  }
  
}

export interface CalendarNavigation
{
  isWaitingToNavigate:boolean;
  isNavigated: boolean;
  roomId: number;
  reservationId: number;
}
