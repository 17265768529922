<div class="relative flex flex-col w-full md:min-w-180 max-h-full sm:max-h-screen overflow-hidden">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{"szczegoly" | transloco}} - {{'Booking.com' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-scroll">
            <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)"
                class="vertically-tabs m-0-content p-0-content" mat-stretch-tabs="false" mat-align-tabs="start"
                dynamicHeight dynamicWidth>
                <mat-tab>
                    <ng-template matTabLabel>
                        {{'szczegoly' | transloco}}
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template matTabLabel>
                        {{'karta_kredytowa' | transloco}}
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template matTabLabel>
                        {{'ceny_i_podatki' | transloco}}
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

            <ng-container *ngIf="tabIndex === 0">
                <ng-container *ngTemplateOutlet="details"></ng-container>
            </ng-container>
            <ng-container *ngIf="tabIndex === 1">
                <ng-container *ngTemplateOutlet="vcc"></ng-container>
            </ng-container>
            <ng-container *ngIf="tabIndex === 2">
                <ng-container *ngTemplateOutlet="prices"></ng-container>
            </ng-container>
        </div>
    </div>
    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <!-- Confirm -->
                <button mat-dialog-close mat-stroked-button>
                    {{'zamknij' | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Details -->
<ng-template #details>
    <!--<pre>
    {{b?.booking | json}}
    </pre>-->
    <div class="grid sm:grid-cols-4 gap-6 w-full mt-6">
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>Booking ID</mat-label>
                <input matInput type="text" readonly [value]="b?.booking?.id" />
                <button mat-icon-button matSuffix>
                    <mat-icon [svgIcon]="'heroicons_solid:document-duplicate'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'data_dodania' | transloco}}</mat-label>
                <input matInput type="text" readonly [value]="b?.booking?.booked_at" />
                <mat-icon matSuffix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
            </mat-form-field>
        </div>
        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'cena_calkowita_z_podatkami_i_oplatami' | transloco}}</mat-label>
                <input matInput type="text" readonly [value]="sum" />
                <span matSuffix>{{b?.booking?.currencycode}}</span>
                <mat-hint>{{'rezerwacje' | transloco}}: {{b?.booking?.room?.length}}</mat-hint>
            </mat-form-field>
        </div>

        <div class="sm:col-span-4">
            <div class="my-2 border-t"></div>
        </div>

        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>Room reservation ID</mat-label>
                <input matInput type="text" readonly [value]="bookingRoomReservation?.roomreservation_id" />
                <button mat-icon-button matSuffix>
                    <mat-icon [svgIcon]="'heroicons_solid:document-duplicate'"></mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'pokoj' | transloco}} / ID</mat-label>
                <input matInput type="text" readonly [value]="bookingRoomReservation?.name + ' / ' + bookingRoomReservation?.id" />
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'przyjazd' | transloco}} / {{'wyjazd' | transloco}}</mat-label>
                <input matInput type="text" readonly [value]="bookingRoomReservation?.arrival_date + ' - ' + bookingRoomReservation?.departure_date" />
            </mat-form-field>
        </div>
        <div class="sm:col-span-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>
                    {{'nazwisko' | transloco}}
                </mat-label>
                <input matInput type="text" readonly [value]="bookingRoomReservation?.guest_name" />
                <span matSuffix *ngIf="b?.booking?.reservation_extra_info?.flags?.flag['@attributes']['name']" [matTooltip]="'Booker is genious'" matTooltipPosition="above">
                    <mat-icon [svgIcon]="'heroicons_solid:check-badge'"></mat-icon>
                </span>
                <mat-hint *ngIf="b?.booking?.reservation_extra_info?.flags?.flag['@attributes']['name']">Booker is genius</mat-hint>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <span matPrefix>
                    <mat-icon [svgIcon]="'mat_outline:fastfood'"></mat-icon>
                </span>
                <mat-label>
                    {{'wyzywienie' | transloco}}
                </mat-label>
                <textarea matInput type="text" readonly>{{bookingRoomReservation?.meal_plan}}</textarea>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <span matPrefix>
                    <mat-icon [svgIcon]="'heroicons_solid:star'"></mat-icon>
                </span>
                <mat-label>
                    {{'dodatkowe_informacje' | transloco}}
                </mat-label>
                <textarea matInput type="text" readonly>{{bookingRoomReservation?.extra_info}}</textarea>
            </mat-form-field>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>
                    {{'palenie' | transloco}}
                </mat-label>
                <input matInput type="text" readonly [value]="bookingRoomReservation?.smoking == 0 ? ('nie' | transloco) : ('tak' | transloco)" />
                <span matPrefix>
                    <mat-icon [svgIcon]="'mat_outline:smoke_free'"></mat-icon>
                </span>
            </mat-form-field>
        </div>
        <div class="sm:col-span-1">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>
                    {{'cena_za_pobyt' | transloco}}
                </mat-label>
                <input matInput type="text" readonly [value]="bookingRoomReservation?.price_details?.hotel.total" />
                <span matSuffix>{{bookingRoomReservation?.currencycode}}</span>
            </mat-form-field>
        </div>
        <div class="sm:col-span-2">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{'cena_calkowita_z_podatkami_i_oplatami' | transloco}}</mat-label>
                <input matInput type="text" readonly [value]="bookingRoomReservation?.price_details?.guest.total" />
                <span matSuffix>{{bookingRoomReservation?.currencycode}}</span>
            </mat-form-field>
        </div>
    </div>
</ng-template>
<!-- Virtual credit card -->
<ng-template #vcc>
    <div class="w-96 h-56 m-auto bg-primary rounded-xl relative text-white shadow-xl transition-transform transform hover:scale-110 hover:shadow-2xl mt-12 mb-12 cursor-pointer"
        [matTooltip]="'kliknij_aby_pokazac_dane_karty' | transloco" matTooltipPosition="above" (click)="showVCC()">
        <img class="relative object-cover w-full h-full rounded-xl" src="https://i.imgur.com/kGkSg1v.png">

        <div class="w-full px-8 absolute top-8">
            <div class="flex justify-between">
                <div class="">
                    <p class="font-light">
                        Name
                    </p>
                    <p class="font-medium tracking-widest">
                        {{b?.booking?.customer?.cc_name}}
                    </p>
                </div>
                <div class="h-14">
                    <mat-icon *ngIf="cards[b?.booking?.customer?.cc_type] == null" svgIcon="heroicons_solid:credit_card" class="w-14 h-14"></mat-icon>
                    <img *ngIf="cards[b?.booking?.customer?.cc_type] != null"class="w-14" [src]="cards[b?.booking?.customer?.cc_type]" />
                </div>
               
            </div>
            <div class="pt-1">
                <p class="font-light">
                    Card Number
                </p>
                <p class="font-medium tracking-more-wider">
                    {{b?.booking?.customer?.cc_number}}
                </p>
            </div>
            <div class="pt-6 pr-6">
                <div class="flex justify-between">
                    <!-- <div class="">
                        <p class="font-light text-xs">
                            Valid
                        </p>
                        <p class="font-medium tracking-wider text-sm">
                            -
                        </p>
                    </div> -->
                    <div class="">
                        <p class="font-light text-xs">
                            Expiry
                        </p>
                        <p class="font-medium tracking-wider text-sm">
                            {{b?.booking?.customer?.cc_expiration_date}}
                        </p>
                    </div>

                    <div class="">
                        <p class="font-light text-xs">
                            CVV
                        </p>
                        <p class="font-bold tracking-more-wider text-sm">
                            {{b?.booking?.customer?.cc_cvc}}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <fuse-alert *ngIf="displayVCC" @shake class="mt-4" [type]="'warning'" [appearance]="'outline'">
        <span fuseAlertTitle>{{'uwaga' | transloco}}</span>
        {{'ze_wzgledow_bezpieczenstwa_nie_przechowywujemy_danych_kart_kredytowych' | transloco}}
    </fuse-alert>
</ng-template>
<!-- Prices, fees and cancellation-->
<ng-template #prices>
    <div class="text-lg font-medium mt-6">{{'plan_cenowy' | transloco}}</div>
    <ng-container *ngFor="let rate of ratesDays">
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-6">

            <div class="sm:col-span-4">
                <span class="px-2 py-1 bg-primary rounded-full text-white font-sm">Standard rate</span>
            </div>

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'data' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="rate.date" />
                </mat-form-field>
            </div>

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'id_planu' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="rate.rate_id" />
                </mat-form-field>
            </div>

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'cena' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="rate.nodeValue" />
                    <span matSuffix>{{bookingRoomReservation?.currencycode}}</span>
                </mat-form-field>
            </div>

            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        Genius rate
                    </mat-label>
                    <input matInput type="text" readonly [value]="rate.genius_rate == 'no' ? ('nie' | transloco) : ('tak' | transloco)" />
                </mat-form-field>
            </div>

        </div>
    </ng-container>
    <div class="text-lg font-medium mt-10">{{'podatki_i_oplaty' | transloco}}</div>
    <ng-container *ngFor="let fee of taxesAndFees">
        <div class="grid sm:grid-cols-6 gap-6 w-full mt-6">
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'nazwa' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="fee.text" />
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'kwota' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="fee.amount" />
                    <span matSuffix>{{fee.currency}}</span>
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'zawiera' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="fee.included == 'no' ? ('nie' | transloco) : ('tak' | transloco)" />
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'za_noc' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="fee.per_night == 'no' ? ('nie' | transloco) : ('tak' | transloco)" />
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'za_osobe' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="fee.per_person == 'no' ? ('nie' | transloco) : ('tak' | transloco)" />
                </mat-form-field>
            </div>
            <div class="sm:col-span-1">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>
                        {{'procent' | transloco}}
                    </mat-label>
                    <input matInput type="text" readonly [value]="fee.percentage == 'no' ? ('nie' | transloco) : ('tak' | transloco)" />
                </mat-form-field>
            </div>
            
        </div>
    </ng-container>
    <div class="text-lg font-medium mt-10">
        {{'kary_za_anulowanie' | transloco}}
    </div>
    <div class="grid sm:grid-cols-4 gap-6 w-full mt-6">
        <ng-container *ngFor="let cancelPenalty of cancelPenalties">
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'od' | transloco}}</mat-label>
                    <input matInput type="text" readonly [value]="cancelPenalty.date" />
                    <mat-icon matSuffix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                </mat-form-field>
            </div>
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label>{{'kwota' | transloco}}</mat-label>
                    <input matInput type="text" readonly [value]="cancelPenalty.value" />
                    <span matSuffix>{{cancelPenalty.currency}}</span>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
    
</ng-template>

<!--
<h2 mat-dialog-title>
    {{"szczegoly" | transloco}} - {{'Booking.com' | transloco}}
    <button class="mt-2 float-end" mat-dialog-close mat-icon-button matTooltip="{{'zamknij_okno' | transloco}}">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" *ngIf="b != null">
    <div class="row">
        <div class="col-12 col-md-6">
            Booking ID:<br />
            <b>{{b?.booking?.id}}</b>
        </div>
        <div class="col-12 col-md-6" *ngIf="b?.booking?.status != null && b?.booking?.status != 'cancelled'">
            {{"data_dodania" | transloco}}: <br />

            <b *ngIf="!isNullOrEmpty(b?.booking?.booked_at)">
                <date-formatter [date]="b?.booking?.booked_at" [showTime]="true"></date-formatter>
            </b>
            <b *ngIf="isNullOrEmpty(b?.booking?.booked_at)">
                <date-formatter [date]="b?.booking?.date + ' ' + b?.booking?.time" [showTime]="true"></date-formatter>
            </b>
        </div>
        <div class="col-12 col-md-6" *ngIf="b?.booking?.total_cancellation_fee != null">
            {{"calkowita_oplata_za_anulowanie" | transloco}}:<br />
            <b>{{b?.booking?.total_cancellation_fee}} </b>{{b?.booking?.currencycode}}
        </div>
    </div>
    <div class="row my-1">
        <div class="col-12">
            {{"cena_calkowita_z_podatkami_i_oplatami" | transloco}} <br />
            {{"rezerwacje" | transloco}}: {{b?.booking?.room?.length}}x<br />
            <b>{{sum}} </b>{{b?.booking?.currencycode}}
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row">
        <div class="col-12">
            <h2>{{"karta_kredytowa" | transloco}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-7">
            {{"numer" | transloco}}:<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_number) ? b?.booking?.customer?.cc_number : '-'}}</b>
        </div>
        <div class="col-12 col-md-5">
            {{"nazwisko" | transloco}}<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_name) ? b?.booking?.customer?.cc_name : '-'}}</b>
        </div>
    </div>
    <div class="row my-2">
        <div class="col-12 col-md-4">
            {{"typ" | transloco}}:<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_type) ? b?.booking?.customer?.cc_type : '-'}}</b>
        </div>
        <div class="col-12 col-md-3">
            CVC<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_cvc) ? b?.booking?.customer?.cc_cvc : '-'}}</b>
        </div>
        <div class="col-12 col-md-5">
            {{"data_wygasniecia" | transloco}}<br />
            <b>{{!isNullOrEmpty(b?.booking?.customer?.cc_expiration_date) ? b?.booking?.customer?.cc_expiration_date :
                '-'}}</b>
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row">
        <div class="col-12">
            <h2>{{"szczegoly" | transloco}}</h2>
        </div>
    </div>

    <div class="col-12" *ngIf="!isNullOrEmpty(b?.booking?.status) && b?.booking?.status != 'cancelled' || b?.imported == 1">
        Room Reservation ID:<br />
        <b>{{b?.room_booking?.roomreservation_id}}</b>
    </div>
    <div class="row my-2">
        <div class="col-12 col-md-6">
            {{"pokoj" | transloco}} / ID<br />
            <b>{{isNullOrEmpty(b?.room_booking.name) ? '' : b?.room_booking.name+'/'}}{{b?.room_booking?.id}}</b>
        </div>
        <div class="col-12 col-md-6">
            {{"nazwisko"| transloco}}: <br />
            <b >
                {{!isNullOrEmpty(b?.room_booking?.guest_name) ?  b?.room_booking?.guest_name : b?.booking?.customer?.first_name +' '+b?.booking?.customer?.last_name}}
                <span *ngIf="!isNullOrEmpty(b?.booking?.reservation_extra_info?.flags?.flag['@attributes']?.name)" class="badge badge-info">
                    {{b?.booking?.reservation_extra_info?.flags?.flag['@attributes']?.name}}
                </span>
            </b>
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>


    <div class="row">
        <div class="col-12 col-md-6">
            <b>{{"wyzywienie"| transloco}}</b><br />
            {{!isNullOrEmpty(b?.room_booking?.meal_plan) ? b?.room_booking?.meal_plan : '-'}}
        </div>
        <div class="col-12 col-md-6">
            <b>{{"extra_info"| transloco}}</b><br />  
            <p>{{!isNullOrEmpty(b?.room_booking?.extra_info) ? b?.room_booking?.extra_info : '-'}}</p>   
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row">
        <div class="col-12 col-md-4">
            {{"palenie" | transloco}}:<br />
            <b>{{isNullOrEmpty(b?.booking?.smoking) ? translate.translate('nie') : translate.translate('tak')}}</b>
        </div>
        <div class="col-12 col-md-3">
            {{"cena_za_pobyt" | transloco}}<br />
            <b>{{b?.room_booking?.totalprice}} </b>{{b?.booking?.currencycode}}
        </div>
        <div class="col-12 col-md-5">
            {{"cena_calkowita_z_podatkami_i_oplatami" | transloco}}<br />
            <b *ngIf="!isNullOrEmpty(b?.room_booking?.price_details?.guest?.total)"> {{b?.room_booking?.price_details?.guest?.total}}</b>
            <b *ngIf="isNullOrEmpty(b?.room_booking?.price_details?.guest?.total)"> {{b?.room_booking?.totalprice}}</b>
            {{b?.booking?.currencycode}}
        </div>
    </div>

    <mat-divider class="my-3"></mat-divider>

    <div class="row" *ngIf="b?.room_booking?.addons?.length > 0">
        <div class="col-12">
            <h2>{{"dodatki" | transloco}}</h2>
        </div>
    </div>

    <div class="table-responsibe" *ngIf="b?.room_booking?.addons?.length > 0">
        <table class="table table-striped table-hovered">
            <thead>
                <tr>
                    <th>{{"nazwa" | transloco}}</th>
                    <th>{{"liczba_nocy" | transloco}}</th>
                    <th>{{"liczba_osob" | transloco}}</th>
                    <th>{{"cena_za_dobe" | transloco}}</th>
                    <th>{{"razem" | transloco}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let addon of b?.room_booking?.addons">
                    <td>{{addon?.name}}</td>
                    <td>{{addon?.nights}}</td>
                    <td>{{addon?.persons}}</td>
                    <td><b>{{addon?.price_per_unit?.toFixed(2)}}</b> {{b?.booking?.currencycode}}</td>
                    <td><b>{{addon?.totalprice?.toFixed(2)}}</b> {{b?.booking?.currencycode}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <div class="col-12">
            <h2>{{"plan_cenowy" | transloco}}</h2>
        </div>
    </div>


    <div class="table-responsibe">
        <table class="table table-striped table-hovered">
            <thead>
                <tr>
                    <th>{{"nazwa" | transloco}}</th>
                    <th>{{"data" | transloco}}</th>
                    <th>{{"id_planu" | transloco}}</th>
                    <th>{{"cena" | transloco}}</th>
                    <th>{{"wskaznik_geniuszu" | transloco}}</th>
                    <th>{{"nazwa_promocji" | transloco}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let price of b?.room_booking?.price">
                    <td>Plan cenowy: {{price?.['@attributes'].rate_id}} {{getRatePlanName(price?.['@attributes'].rate_id)}}</td>
                    <td>{{price?.['@attributes']?.date}}</td>
                    <td>{{price?.['@attributes']?.rate_id}}</td>
                    <td><b>{{price?.['@attributes']?.nodeValue}}</b> {{b?.booking?.currencycode}}</td>
                    <td><b>{{price?.['@attributes']?.genius_rate == 'no' ? translate.translate('nie'): translate.translate('tak')}}</b></td>
                    <td><b>{{isNullOrEmpty(price?.['@attributes']?.rewritten_from_name) ? '-' : price['@attributes']?.rewritten_from_name}}</b></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <div class="col-12">
            <h2>Tax and fee</h2>
        </div>
    </div>

    <div class="table-responsibe">
        <table class="table table-striped table-hovered">
            <thead>
                <tr>
                    <th>{{"nazwa" | transloco}}</th>
                    <th>{{"zawiera" | transloco}}</th>
                    <th>{{"za_noc" | transloco}}</th>
                    <th>{{"za_osobe" | transloco}}</th>
                    <th>{{"procent" | transloco}}</th>
                    <th>{{"kwota" | transloco}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tax of b?.room_booking?.price_details?.guest?.extracomponent">
                    <td>{{tax['@attributes']?.text}}</td>
                    <td>{{tax['@attributes']?.included == 'yes' ? translate.translate('tak') : translate.translate('nie')}}</td>
                    <td>{{tax['@attributes']?.per_night == 'yes' ? translate.translate('tak') : translate.translate('nie')}}</td>
                    <td>{{tax['@attributes']?.per_person == 'yes' ? translate.translate('tak') : translate.translate('nie')}}</td>
                    <td>{{tax['@attributes']?.percentage == 'no' ? translate.translate('nie') : tax['@attributes']?.percentage}}</td>
                    <td><b>{{tax['@attributes']?.amount}}</b> {{tax['@attributes']?.currency}}</td>
                </tr>
            </tbody>
        </table>
    </div>


    <div *ngIf="!isNullOrEmpty(b?.room_booking?.cancel_penalties.cancel_penalty)">

        <div class="row">
            <div class="col-12">
                <h2>{{"kary_za_anulowanie" | transloco}}</h2>
            </div>
        </div>

        <div class="table-responsibe">
            <table class="table table-striped table-hovered">
                <thead>
                    <tr>
                        <th>{{"od" | transloco}}</th>
                        <th>{{"do" | transloco}}</th>
                        <th>{{"ostateczny_termin" | transloco}}</th>
                        <th>{{"kwota" | transloco}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let penalty of b?.room_booking?.cancel_penalties.cancel_penalty">
                        <td>
                            <date-formatter [date]="penalty?.['@attributes']?.from"></date-formatter>
                        </td>
                        <td>
                            <date-formatter [date]="penalty?.['@attributes']?.until"></date-formatter>
                        </td>
                        <td>
                            <date-formatter *ngIf="!isNullOrEmpty(penalty?.deadline?.['@attributes']?.time)" [date]="penalty?.deadline?.['@attributes']?.time"></date-formatter>
                            <p *ngIf="isNullOrEmpty(penalty?.deadline?.['@attributes']?.time)">-</p>
                        </td>
                        <td>{{penalty?.amount_percent?.['@attributes']?.amount}} {{penalty?.amount_percent?.['@attributes']?.currency_code}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
   

    <div class="mb-3"></div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="bg-gray-50">
    <button mat-stroked-button mat-dialog-close class="mc-button">{{'zamknij_okno' | transloco}}</button>
</mat-dialog-actions>
-->