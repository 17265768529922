import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseAlertComponent } from '@fuse/components/alert';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { SettingsService } from 'app/core/shared/services/settings.service';
import { UserSettings } from 'app/core/shared/models/UserSettings';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'subscription-action-state-alert',
  standalone: true,
  imports: [CommonModule, TranslocoModule, FuseAlertComponent, MatIconModule, MatButtonModule, RouterModule, LocalizeRouterModule],
  templateUrl: './subscription-action-state-alert.component.html',
  styleUrl: './subscription-action-state-alert.component.scss'
})
export class SubscriptionActionStateAlertComponent implements OnDestroy {

  private readonly _subscriptions:Subscription[] = []
  
  subscriptionState: {
    isTestMode: boolean
    expiredInDays: number,
    expiredIn: string
  } | null = null

  constructor(private _settingsService:SettingsService) {
    this._subscriptions.push(this._settingsService.currentUserSettings.subscribe({
      next: (userSettings:UserSettings) => {
        this.subscriptionState = userSettings.userData.subscriptionState
      }
    }))
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }
}
