import { Component, forwardRef } from '@angular/core';
import { PanelService } from '../../services/panel.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { DataChangeTrackingService } from '../../services/data-change-tracking.service';
import { CommonModule } from '@angular/common';
import { ReservationRowComponent } from '../reservation-row/reservation-row.component';
import { MatButtonModule } from '@angular/material/button';
import { OrderByPipe } from '../../pipes/order-by.pipe';
import { DateFormatterComponent } from '../date-formatter/date-formatter.component';

@Component({
  standalone: true,
  selector: 'app-display-new-changes',
  templateUrl: './display-new-changes.component.html',
  styleUrls: ['./display-new-changes.component.scss'],
  imports: [CommonModule, MatButtonModule, forwardRef(() => ReservationRowComponent), DateFormatterComponent, MatIconModule, TranslocoModule, MatTooltipModule, OrderByPipe]
})
export class DisplayNewChangesComponent {

  constructor(
    private _panelService:PanelService,
    public dataChangeTrackingService:DataChangeTrackingService
  ) {  }

  close()
  {
    this._panelService.close();
    this._panelService.clearPanelPortal();
  }

  clearList() {
    this.dataChangeTrackingService.clearChanges()
    this.close()
  }
}
