<div class="grid grid-cols-12 gap-x-1 mt-4">
    <!-- Columns -->
    <div class="uppercase col-span-4 font-medium text-md text-secondary">{{'nazwa_towaru_lub_uslugi' |
        transloco}}</div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'symbol' |
        transloco}}</div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'jednostka' |
        transloco}}</div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'ilosc' | transloco}}
    </div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'cena_jedn_brutto' |
        transloco}}</div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'wartosc_netto' |
        transloco}}</div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'vat' | transloco}} %
    </div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'kwota_vat' |
        transloco}}</div>
    <div class="uppercase col-span-1 font-medium text-md text-center text-secondary">{{'wartosc_brutto' |
        transloco}}</div>

    <!-- Divider -->
    <div class="col-span-12 my-4 border-b"></div>

    <!-- Item -->
    <ng-container *ngFor="let item of invoice.products; let i = index;">
        <div class="col-span-4 text-lg font-medium">
            <ng-container>
                {{item.name}}
            </ng-container>
        </div>
        <div class="col-span-1 self-center text-center">
            <ng-container> {{item.symbol}}</ng-container>

        </div>
        <div class="col-span-1 self-center text-center">

            <ng-container>
                {{item.unit}}
            </ng-container>

        </div>
        <div class="col-span-1 self-center text-center">

            <ng-container>
                {{item.quantity}}
            </ng-container>

        </div>

        <div class="col-span-1 self-center text-center">

            <ng-container>
                {{item.grossUnitPrice}}
            </ng-container>

        </div>

        <div class="col-span-1 self-center text-center">

            <ng-container>
                {{item.nettValue}}
            </ng-container>

        </div>

        <div class="col-span-1 self-center text-center">

            <ng-container>
                {{item.rate | taxRate}}
            </ng-container>

        </div>

        <div class="col-span-1 self-center text-center">

            <ng-container>
                {{item.taxAmount}}
            </ng-container>

        </div>

        <div class="col-span-1 self-center text-center">

            <ng-container>
                {{item.grossValue}}
            </ng-container>

        </div>

        <!-- Divider -->
        <div class="col-span-12 my-4 border-b"></div>

    </ng-container>

    <ng-container *ngIf="invoice.products.length > 0">
        <!-- Item summary -->
        <ng-container *ngFor="let tax of invoice.taxes; let i = index;">
            <div class="col-span-7">

            </div>

            <div class="col-span-1 self-center text-center">
                <b *ngIf="i == 0">{{'w_tym' | transloco}}</b>
            </div>

            <div class="col-span-1 self-center text-center">
                {{tax.nettValue.toFixed(2)}}
            </div>

            <div class="col-span-1 self-center text-center">
                {{tax.rate | taxRate}}
            </div>

            <div class="col-span-1 self-center text-center">
                {{tax.taxAmount.toFixed(2)}}
            </div>

            <div class="col-span-1 self-center text-center">
                {{tax.grossValue.toFixed(2)}}
            </div>


        </ng-container>

        <div class="col-span-7">

        </div>

        <div class="col-span-1 self-center text-center">
            <b>{{'razem' | transloco}}</b>
        </div>

        <div class="col-span-1 self-center text-center">
            {{invoice.taxesSummaryNettValue.toFixed(2)}}
        </div>

        <div class="col-span-1 self-center text-center">

        </div>

        <div class="col-span-1 self-center text-center">
            {{invoice.taxesSummaryTaxAmount.toFixed(2)}}
        </div>

        <div class="col-span-1 self-center text-center">
            {{invoice.taxesSummaryGrossValue.toFixed(2)}}
        </div>
    </ng-container>

</div>