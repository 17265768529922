import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { LocalizeRouterModule } from '@penleychan/ngx-transloco-router';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PanelService } from '../../services/panel.service';
import { DATA_INJECTION_TOKEN } from '../../others/data-injection-token';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseAlertComponent } from '@fuse/components/alert';
import { FuseHighlightComponent } from '@fuse/components/highlight';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-channel-manager-log-dialog',
  standalone: true,
  imports: [CommonModule, MatIconModule, LocalizeRouterModule, RouterModule,  MatTooltipModule, MatButtonModule, TranslocoModule, MatTabsModule, FuseAlertComponent, FuseHighlightComponent, MatInputModule, MatFormFieldModule],
  templateUrl: './channel-manager-log-dialog.component.html',
  styleUrl: './channel-manager-log-dialog.component.scss'
})
export class ChannelManagerLogDialogComponent {

  constructor(
    @Inject(DATA_INJECTION_TOKEN) public data: any,
    private _panelService:PanelService) {

      if (typeof data.log == 'undefined') {
          throw new Error('[ChannelManagerLogDialog] - Invalid log data.');
      }
  }

  tabIndex = 0;

  prevTab() {
    if (this.tabIndex > 0)
      this.tabIndex--;
  }

  nextTab() {
    this.tabIndex++;
  }

  changeTab($event) {
    this.tabIndex = $event;
  }

  close() {
    this._panelService.close()
  }
  obj1 = JSON.parse('{"room":[{"date":[{"@attributes":{"from":"2024-04-30","to":"2024-05-10"},"rate":{"@attributes":{"id":14288403}},"price":260}],"@attributes":{"id":489323604}}]}');
  obj2 = JSON.parse('{"fault":{"@attributes":{"code":"401","string":"Authorization Required"}}}');
}
