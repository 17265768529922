import * as moment from "moment";
import { DbInvoiceItem } from "./DbInvoiceItem";
import { Utils } from "../others/utils";
import { cloneDeep } from "lodash";

export class DbInvoice {
    invoiceId: number;
    userId: string;
    reservationId: number;
    status: number;
    invoiceNo: string;
    extraChar: string = '';
    format: string = 'NUMBER/MM/YYYY';
    invoiceType: number;
    // Buyer
    companyNameBuyer: string;
    addressBuyer: string;
    taxIdBuyer: string;
    // Seller
    companyNameSeller: string;
    addressSeller: string;
    taxIdSeller: string;
    // Receiver
    hasReceiver: boolean = false
    companyNameReceiver: string;
    addressReceiver: string;
    taxIdReceiver: string;

    bankNoSeller: string;
    paymentType: number;
    paymentStatus: number;
    issueDate: string;
    saleDate: string;
    town: string;
    paymentDate: string;
    currency: string;
    additionalInfo: string;
    clientId: number;
    gtuCodes: string;
    uid: null;

    addDate: string;
    editDate: string;
    
    // summary: number;
    customNumber: string;
    fullInvoiceName: string;
    personCollect: string;
    personIssue: string;
    products: DbInvoiceItem[] = []

    // taxes podstawowych produktów
    taxes?: TaxSum | any = []
    taxesSummaryGrossValue = 0
    taxesSummaryTaxAmount = 0
    taxesSummaryNettValue = 0

    // Identyfikator korygowanej faktury
    correctedInvoiceId?
    correctedInvoice:DbInvoice
    // taxes produktów po ewentualnej korekcie
    taxesBeforeCorrection?: TaxSum | any = []
    taxesBeforeCorrectionSummaryGrossValue = 0
    taxesBeforeCorrectionSummaryTaxAmount = 0
    taxesBeforeCorrectionSummaryNettValue = 0


    // Prepayments
    prepaymentsNettValue = 0
    prepaymentTaxAmount = 0
    prepaymentGrossValue = 0

    // Suma totalna dokumentu
    grossValue?: number = 0
    taxAmount?: number = 0
    nettValue?: number = 0

    vatRates?= new Set()
    taxSettlement = [];

    prepayments: DbInvoice[] = []
    constructor(object: any) {
        Object.keys(object).forEach((key: string) => {
            if (key == 'paymentType') object[key] = parseFloat(object[key])
            this[key] = object[key]
        })

        if(this.companyNameReceiver?.length > 0 || this.taxIdReceiver?.length > 0 || this.addressReceiver?.length > 0) {
            this.hasReceiver = true
        }
        this.count();
    }

    setField(field, value) {
        this[field] = value;

        if (field == 'invoiceType')  {
            // Nie wiem po co to bylo, zostalo zakomentowane
            // // Dodaj automatycznie pozycje do faktury korygujacej
            // if(value == 6 || value == 7) {
            //     this.products = [];
            // }
            // Tylko faktura rozliczeniowa moze zawierać zaliczkę
            // Usun zaliczke, jezeli nie jest typ 7 a była ustawiona
            if(value == 3 || value == 5 || value == 6 || value == 7) this.prepayments = []
            this.count()
        }
    }

    addPrepayment(invoice:DbInvoice)
    {
        if(typeof this.prepayments === 'undefined') this.prepayments = [];
        const existingInvoiceIndex = this.prepayments.findIndex(item => item.invoiceId === invoice.invoiceId);

        if (existingInvoiceIndex !== -1) {
            this.prepayments[existingInvoiceIndex] = invoice;
        } else {
            this.prepayments.push(invoice);
        }
        this.count()
    }

    removePrepayment(indexToRemove:number)
    {
        if (indexToRemove >= 0 && indexToRemove < this.prepayments.length) {
            this.prepayments.splice(indexToRemove, 1);
            this.count()
        } 
    }

    addProducts(productOrProducts: DbInvoiceItem | DbInvoiceItem[]) {
        
        if (Array.isArray(productOrProducts)) {
            this.products.push(...productOrProducts);
        }
        else {
            this.products.push(productOrProducts);
        }

        this.count();
    }

    removeCorrectedInvoice() {
        this.correctedInvoiceId = null
        this.correctedInvoice = null
    }
    
    removeProduct(index: number, main = true) {
        if (this.products.length == 1) return
        this.products.splice(index, 1)
        this.count();
    }

    copyProducts(fromInvoice:DbInvoice) {

        if(fromInvoice && fromInvoice.products.length > 0) {

            this.products = []

            const products = cloneDeep(fromInvoice.products)
            products.forEach(product=> {
                product.positionId = null
                product.invoiceId = this.invoiceId
            })
            this.addProducts(products)
        }
    }
    
    setCorrectedDoc(correctedInvoice:DbInvoice)
    {

        if(correctedInvoice == null) {
            this.correctedInvoice = null
            this.correctedInvoiceId = null
            this.count()
            return
        }

        this.correctedInvoiceId = correctedInvoice.invoiceId, 
        this.correctedInvoice = correctedInvoice

        if(!Utils.isDefined(correctedInvoice,"products")) return
        this.correctedInvoice.products = this.correctedInvoice.products.filter(x=>x.status == 1)
       
        this.count()
    }

    count() {
             

        // Clear old values before counting
        this.taxes = [];
        this.taxesBeforeCorrection = [];

        this.grossValue = 0
        this.taxAmount = 0
        this.nettValue = 0
        this.vatRates = new Set()

        this.taxesSummaryGrossValue = 0
        this.taxesSummaryTaxAmount = 0
        this.taxesSummaryNettValue = 0

        this.taxesBeforeCorrectionSummaryGrossValue = 0
        this.taxesBeforeCorrectionSummaryTaxAmount = 0
        this.taxesBeforeCorrectionSummaryNettValue = 0

        this.products = this.products.filter(x=>x.status == 1)
        this.products.forEach((product: DbInvoiceItem) => {

            if(Utils.isNullOrEmpty(product.nettValue)) product.nettValue = 0
            if(Utils.isNullOrEmpty(product.taxAmount)) product.taxAmount = 0
            if(Utils.isNullOrEmpty(product.grossValue)) product.grossValue = 0
            // Register product rate and verify if number contains % sign
            if(Utils.isFieldParseableToFloat(product.rate) && !product.rate.includes('%')) {
                product.rate = product.rate + '%'
            }

            this.vatRates.add(product.rate)

            const taxSum = this.taxes.find(x => x.rate == product.rate);
            if (typeof taxSum == 'undefined') {
                this.taxes.push(
                    new TaxSum(
                        product.rate,
                        parseFloat(product.nettValue.toString()),
                        parseFloat(product.taxAmount.toString()),
                        parseFloat(product.grossValue.toString())
                    ))
            }
            else {
                taxSum.nettValue += parseFloat(product.nettValue.toString())
                taxSum.taxAmount += parseFloat(product.taxAmount.toString())
                taxSum.grossValue += parseFloat(product.grossValue.toString())
            }
        });

        // Only for corrected invoice
        if(this.invoiceType == 6 && typeof this.correctedInvoice !== 'undefined' && this.correctedInvoice ) {
            this.correctedInvoice.products.forEach((product: DbInvoiceItem) => {
                // Register product rate
                this.vatRates.add(product.rate)
                const taxSum = this.taxesBeforeCorrection.find(x => x.rate == product.rate);
                if (typeof taxSum == 'undefined') {
                    this.taxesBeforeCorrection.push(
                        new TaxSum(
                            product.rate,
                            parseFloat(product.nettValue.toString()),
                            parseFloat(product.taxAmount.toString()),
                            parseFloat(product.grossValue.toString())
                        ))
                }
                else {
                    taxSum.nettValue += parseFloat(product.nettValue.toString())
                    taxSum.taxAmount += parseFloat(product.taxAmount.toString())
                    taxSum.grossValue += parseFloat(product.grossValue.toString())
                }
            });
        }

        // Sumuj podatki faktury
        this.taxes.forEach((tax: TaxSum) => {

            this.taxesSummaryGrossValue += parseFloat(tax.grossValue.toString())
            this.taxesSummaryTaxAmount += parseFloat(tax.taxAmount.toString())
            this.taxesSummaryNettValue += parseFloat(tax.nettValue.toString())
        })

        // Sumuj taxes korekta
        if (this.invoiceType == 6 || this.invoiceType == 7) {
            this.taxesBeforeCorrection.forEach((tax: TaxSum) => {
                this.vatRates.add(tax.rate)
                this.taxesBeforeCorrectionSummaryGrossValue += parseFloat(tax.grossValue.toString())
                this.taxesBeforeCorrectionSummaryTaxAmount += parseFloat(tax.taxAmount.toString())
                this.taxesBeforeCorrectionSummaryNettValue += parseFloat(tax.nettValue.toString())
            })
        }

        // Dodaj vatRates z zaliczki
        if(typeof this.prepayments != 'undefined' && this.prepayments != null && this.prepayments.length > 0) {
            this.prepayments.forEach((prepayment:DbInvoice)=>{
                prepayment.taxes.forEach((tax: TaxSum) => {
                    this.vatRates.add(tax.rate)
                })
            }) 
        }

        // Dodaj stawki z faktury korygującej
        if(typeof this.correctedInvoice != 'undefined' && this.correctedInvoice != null) {
            this.correctedInvoice.taxes.forEach((tax: TaxSum) => {
                    this.vatRates.add(tax.rate)
            })
        }


       
        this.taxSettlement = [];

        this.prepaymentGrossValue = 0;
        this.prepaymentsNettValue = 0;
        this.prepaymentTaxAmount = 0;

        // Oblicz rozliczenie VAT
        Array.from(this.vatRates).forEach(rate => {

            let beforeCorrectionGrossValue = 0
            let beforeCorrectionNettValue = 0
            let beforeCorrectionTaxAmount = 0

            if(typeof this.correctedInvoice !== 'undefined' && this.correctedInvoice)
            {
                beforeCorrectionGrossValue = this.correctedInvoice.taxes.filter(x=>x.rate == rate).map(x=>x.grossValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val)}, 0);
                beforeCorrectionNettValue = this.correctedInvoice.taxes.filter(x=>x.rate == rate).map(x=>x.nettValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
                beforeCorrectionTaxAmount = this.correctedInvoice.taxes.filter(x=>x.rate == rate).map(x=>x.taxAmount).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            }
           
            let grossValue = this.taxes.filter(x=>x.rate == rate).map(x=>x.grossValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            let nettValue = this.taxes.filter(x=>x.rate == rate).map(x=>x.nettValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            let taxAmount = this.taxes.filter(x=>x.rate == rate).map(x=>x.taxAmount).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);

            // Korekty (faktura i rachunek)
            if(this.invoiceType == 6 || this.invoiceType == 7)
            {
                grossValue = grossValue - beforeCorrectionGrossValue
                nettValue = nettValue - beforeCorrectionNettValue 
                taxAmount = taxAmount - beforeCorrectionTaxAmount 
            }
           
            // Sumuj stawki zaliczek
            if(typeof this.prepayments != 'undefined' && this.prepayments != null && this.prepayments.length > 0) {

                let zaliczka_taxes_grossValue = 0;
                let zaliczka_taxes_nettValue = 0;
                let zaliczka_taxes_taxAmount = 0;

                this.prepayments.forEach((prepayment:DbInvoice)=> {
                    zaliczka_taxes_grossValue += prepayment.taxes.filter(x=>x.rate == rate).map(x=>x.grossValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
                    zaliczka_taxes_nettValue += prepayment.taxes.filter(x=>x.rate == rate).map(x=>x.nettValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
                    zaliczka_taxes_taxAmount += prepayment.taxes.filter(x=>x.rate == rate).map(x=>x.taxAmount).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
                })
              
                grossValue = grossValue - zaliczka_taxes_grossValue
                nettValue = nettValue - zaliczka_taxes_nettValue
                taxAmount = taxAmount - zaliczka_taxes_taxAmount

                this.prepaymentGrossValue += zaliczka_taxes_grossValue;
                this.prepaymentsNettValue += zaliczka_taxes_nettValue;
                this.prepaymentTaxAmount += zaliczka_taxes_taxAmount;
            }


            this.taxSettlement.push({
                rate: rate,
                grossValue: grossValue,
                taxAmount: taxAmount,
                nettValue: nettValue
            })    
        });

        this.taxSettlement = this.taxSettlement.sort((a,b)=> a.rate - b.rate)

        // Prepayments
        if(this.invoiceType == 3 || (typeof this.prepayments != 'undefined' && this.prepayments.length > 0)) {
            this.grossValue = this.taxSettlement.map(x=>x.grossValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            this.taxAmount = this.taxSettlement.map(x=>x.taxAmount).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            this.nettValue = this.taxSettlement.map(x=>x.nettValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
        }
        // Correction invoice
        else if((this.invoiceType == 6 && this.correctedInvoice != null) || (this.invoiceType == 7 && this.correctedInvoice != null)) {
            let correctedGrossValue = this.correctedInvoice.taxSettlement.map(x=>x.grossValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            let taxAmount = this.correctedInvoice.taxSettlement.map(x=>x.taxAmount).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            let nettValue = this.correctedInvoice.taxSettlement.map(x=>x.nettValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
        
            this.grossValue = this.taxes.map(x=>x.grossValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0) - correctedGrossValue
            this.nettValue = this.taxes.map(x=>x.nettValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0) - nettValue
            this.taxAmount = this.taxes.map(x=>x.taxAmount).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0) - taxAmount
        }
        else {
            this.grossValue = this.taxes.map(x=>x.grossValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            this.nettValue = this.taxes.map(x=>x.nettValue).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
            this.taxAmount = this.taxes.map(x=>x.taxAmount).reduce((acc:any,val:any)=>{return parseFloat(acc) + parseFloat(val);}, 0);
        }
    }

    asRequest()
    {
        // Korygujaca
        let pozycje = this.products
        let correctedInvoiceId = null;
        let id_zaliczka = [];

        if(this.invoiceType === 6 || this.invoiceType === 7) {
            correctedInvoiceId = this.correctedInvoiceId
        }

        
        if(this.prepayments != null) {
            this.prepayments.forEach(zal=>{
                id_zaliczka.push(zal.invoiceId)
            })
        }
        
        
        
        let extra_znak = typeof this.extraChar !== 'undefined' ? this.extraChar : '';
        return {
            id_faktury: this.invoiceId,
            customNumber: this.customNumber,
            clientId: this.clientId,
            reservationId: this.reservationId,
            data_wystawienia: moment(this.issueDate).format('YYYY-MM-DD'),
            miejscowosc: this.town,
            data_sprzedazy: moment(this.saleDate).format('YYYY-MM-DD'),
            invoiceType: this.invoiceType,
            extra_znak: extra_znak,
            nazwa_firma: this.companyNameBuyer,
            address: this.addressBuyer,
            taxId: this.taxIdBuyer,
            status_platnosci: this.paymentStatus,
            currency: this.currency,
            paymentType: this.paymentType,
            termin_platnosci: moment(this.paymentDate).format('YYYY-MM-DD'),
            informacje_dodatkowe: this.additionalInfo,
            nazwa_firma_sprzedawca: this.companyNameSeller,
            adres_sprzedawca: this.addressSeller,
            nip_sprzedawca: this.taxIdSeller,
            nr_banku_sprzedawca: this.bankNoSeller,
            personCollect: this.personCollect,
            personIssue: this.personIssue,
            kody_gtu: this.gtuCodes,
            pozycje: pozycje,

            id_zaliczka: id_zaliczka,
            correctedInvoiceId: correctedInvoiceId
        }
    }
}

export class TaxSum {

    symbol: string = '';
    rate: string | number = 0;
    nettValue = 0;
    grossValue = 0;
    taxAmount = 0;

    constructor(rate: string | number, nettValue: number, taxAmount: number, grossValue: number) {
        this.rate = rate;
        if (Utils.isFieldParseableToFloat(rate)) { this.symbol = '%' }
        this.nettValue = nettValue;
        this.taxAmount = taxAmount;
        this.grossValue = grossValue;

    }
}
