import { ApplicationData } from "../data/application-data";
import { Utils } from "../others/utils";

export class Source {
    sourceId: number
    userId: number
    
    isCustom: boolean
    name: string
    initial: string
    logo: string | null
    color: string = ApplicationData.DefaultSourceBackgroundColor;
    textColor: string
    status: number
    addDate: string
    editDate: string

    displayName? :string = '' 
    constructor({
        sourceId = null,
        userId,
        name,
        logo,
        color,
        addDate,
        editDate,
        status
    }:{
        sourceId?,
        userId?,
        name?,
        logo?,
        color?,
        addDate?,
        editDate?,
        status?
    }) 
    {
        this.sourceId = sourceId
        this.userId = userId
        this.name = name
        this.displayName = name
        this.addDate = addDate
        this.editDate = editDate
        this.logo = logo
        if(name) {
            this.initial = name.slice(0, 1) || ''
        } else {
            this.initial = ''
        }
        this.setBackground(color || ApplicationData.DefaultSourceBackgroundColor);

        this.isCustom = this.userId == null ? false : true
        this.status = status
    }

    setBackground(bgColor: string) {
        this.color = bgColor;
        this.textColor = Utils.setTextColorBasedOnBackground(this.color || '#ffffff');
    }
}