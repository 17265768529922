import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { UserPermissions } from "../models/UserPermissions";

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    permissions = new BehaviorSubject<UserPermissions>(new UserPermissions({}));
    currentPermissions = this.permissions.asObservable();
}