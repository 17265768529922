<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar' && permissions.has('searchReservation')" >
    <button mat-icon-button *ngIf="!opened" (click)="open()" [matTooltip]="('szukaj' | transloco)">
        <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
    </button>
    <div class="absolute inset-0 flex items-center shrink-0 z-99 bg-card" *ngIf="opened" @slideInTop @slideOutTop>
        <mat-icon class="absolute ml-6 sm:ml-8" [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
        <input class="w-full h-full px-16 sm:px-18" [formControl]="searchControl" [matAutocomplete]="matAutocomplete"
            [placeholder]="'szukaj' | transloco" (keydown)="onKeydown($event)" #barSearchInput>
        <mat-autocomplete class="max-h-128 sm:px-2 border-t rounded-b shadow-md" [autoSelectActiveOption]="true" (optionSelected)="chooseOption($event)"
            [disableRipple]="true" #matAutocomplete="matAutocomplete">
            <mat-option *ngFor="let result of resultSets?.reservations" [value]="{type: 'reservation', obj: result}"
                class="p-2 search-item">
                <ng-container>
                    <ng-container *ngTemplateOutlet="reservationResult; context: {$implicit: result}"></ng-container>
                </ng-container>
            </mat-option>
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="searchControl?.value?.length > 0 && resultSets && resultSets?.reservations?.length == 0">
                {{'nie_znaleziono_wynikow' | transloco}}
            </mat-option>
        </mat-autocomplete>
        <button class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5" mat-icon-button (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic' && permissions.has('searchReservation')" >
    <div class="w-full sm:min-w-80">
        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
            <mat-icon matPrefix [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
            <input matInput [formControl]="searchControl" [matAutocomplete]="matAutocomplete"
                [placeholder]="'szukaj' | transloco" >
        </mat-form-field>
        <mat-autocomplete class="max-h-128 mt-1 rounded"  [disableRipple]="true"
            #matAutocomplete="matAutocomplete" (optionSelected)="chooseOption($event)">
            <mat-option *ngFor="let result of resultSets?.reservations" [value]="{type: 'reservation', obj: result}"
                class="p-2 search-item">
                    <ng-container *ngTemplateOutlet="reservationResult; context: {$implicit: result}"></ng-container>
            </mat-option>
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="searchControl?.length > 0 && resultSets && !resultSets.length">
                {{'nie_znaleziono_wynikow' | transloco}}
            </mat-option>
        </mat-autocomplete>
    </div>
</ng-container>

<!-- Reservation template -->
<ng-template #reservationResult let-result>
    <div class="px-6">
        <app-reservation-row [reservation]="result"></app-reservation-row>
    </div>
    
    <!-- <mat-chip class="sm"><b>#{{result.reservationNo}}</b></mat-chip>
    <span *ngIf="result.client != null">
        <span class="bolder ms-2">
            {{result?.client?.forename}} {{result?.client?.name}}
            <mat-icon *ngIf="result?.client?.clientType == '2'" color="warn"
                [matTooltip]="translate.translate('niechciany_klient')" svgIcon="x-circle"></mat-icon>
            <mat-icon *ngIf="result?.client?.clientType == '1'" color="primary"
                [matTooltip]="translate.translate('staly_klient')" svgIcon="chceck-circle"></mat-icon>
        </span>
        <div class="sub-text">
            <div class="d-flex text-secondary">
                <small>
                    <mat-icon class="me-1" svgIcon="calendar"></mat-icon>
                    <date-formatter [date]="result.arrival"></date-formatter>
                    <span class="mx-2">-</span>
                    <date-formatter [date]="result.departure"></date-formatter>

                    <span class="ms-2">
                        <span class="room-square" [style.background-color]="result?.room?.color"></span>
                        {{ (result?.room?.name?.length>35)? (result?.room?.name |
              slice:0:35)+'...':(result?.room?.name) }}
                    </span>
                </small>
            </div>
            <div class="d-flex mt-1 text-secondary">
                <small>
                    <mat-icon class="me-1" svgIcon="user"></mat-icon>
                    {{result.client.email}}
                    {{result.client.phone}}
                    <span *ngIf="result.client.email == '' && result.client.phone == ''">{{'brak_informacji' |
              transloco}}</span>
                </small>
            </div>
        </div>
    </span> -->
</ng-template>

<!-- Contact result template -->
<ng-template #contactResult let-result>
    <div class="flex items-center">
        <div
            class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
            <img *ngIf="result.avatar" [src]="result.avatar">
            <mat-icon class="m-0 icon-size-5 text-primary dark:text-primary-400" *ngIf="!result.avatar"
                [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </div>
        <div class="ml-3 truncate">
            <span [innerHTML]="result.name"></span>
        </div>
    </div>
</ng-template>

<!-- Page result template -->
<ng-template #pageResult let-result>
    <div class="flex flex-col">
        <div class="truncate leading-normal" [innerHTML]="result.title"></div>
        <div class="truncate leading-normal text-sm text-secondary">
            {{result.link}}
        </div>
    </div>
</ng-template>

<!-- Task result template -->
<ng-template #taskResult let-result>
    <div class="flex items-center">
        <ng-container *ngIf="result.completed">
            <mat-icon class="mr-0 text-primary dark:text-primary-400" [svgIcon]="'heroicons_outline:check-circle'">
            </mat-icon>
        </ng-container>
        <ng-container *ngIf="!result.completed">
            <mat-icon class="mr-0 text-hint" [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
        </ng-container>
        <div class="ml-3 truncate leading-normal" [ngClass]="{'line-through text-hint': result.completed}"
            [innerHTML]="result.title"></div>
    </div>
</ng-template>
