import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { DbNotification } from 'app/core/shared/models/DbNotification';
import { Source } from 'app/core/shared/models/Source';
import { Utils } from 'app/core/shared/others/utils';
import { SourcesService } from 'app/core/shared/services/sources.service';
import { TranslocoModule } from '@ngneat/transloco';
import { DateFormatterComponent } from 'app/core/shared/components/date-formatter/date-formatter.component';
import { SourceAvatarComponent } from 'app/core/shared/components/source-avatar/source-avatar.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'channel-manager-import-notification',
  standalone: true,
  imports: [CommonModule, TranslocoModule, SourceAvatarComponent, DateFormatterComponent, JsonPipe, MatIconModule],
  templateUrl: './channel-manager-import-notification.component.html',
  styleUrl: './channel-manager-import-notification.component.scss'
})
export class ChannelManagerImportNotificationComponent implements OnChanges {

  @Input() notification:DbNotification = null
  source:Source
  constructor(private _sourcesService:SourcesService) { }

  ngOnChanges(changes:SimpleChanges) {
    if(Utils.isDefined(changes,"notification.currentValue.body.sourceId")) {
      this.source = this._sourcesService.getSourceById(changes.notification.currentValue.body.sourceId)
    }
  }
}
