import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { AvailabilityService } from '../../services/availability.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
// import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { InvoicesService } from '../../services/invoices.service';
import { invoiceFilter } from '../../filters/invoice.filter';
import { DbInvoice } from '../../models/DbInvoice';
import { InvoiceTypeToNameComponent } from '../../components/invoice-type-to-name/invoice-type-to-name.component';

@Component({
  standalone:true,
  selector: 'app-select-invoice-dialog',
  templateUrl: './select-invoice-dialog.component.html',
  styleUrls: ['./select-invoice-dialog.component.scss'],
  imports: [CommonModule, MatDialogModule, InvoiceTypeToNameComponent, MatIconModule, MatFormFieldModule, TranslocoModule, MatDividerModule, MatButtonModule, MatInputModule, MatCheckboxModule, MatRippleModule]
})
export class SelectInvoiceDialogComponent {

  invoices = [];
  selected = [];
  filters = []
  title = '';
  confirmText = '';
  disabled = [];
  range: {start:Date, end: Date} = { start: null, end: null };
  isMultiple = true
  @ViewChild('selectedList') selectedList
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog: MatDialogRef<SelectInvoiceDialogComponent>,
    private _invoicesService:InvoicesService,
    public translate:TranslocoService
  ) {

    if (data.selectedIds != null && typeof data.selectedIds != 'undefined') this.selected = data.selectedIds || []
    // Create array from single value if not array
    if(!Array.isArray(data.selectedIds)) this.selected = [data.selectedIds]
    if (data.title != null) this.title = data.title
    if (data.confirmText != null) this.confirmText = data.confirmText
    if (data.disabled != null) this.disabled = data.disabled
    if (data.filters != null) this.filters = data.filters
    if (data.isMultiple != null) this.isMultiple = data.isMultiple
    if (data.range != null) {
      this.range = data.range;
    }

    this.search('');
  }

  toggle(id) {

    if(!this.isMultiple) {
      this.selected = [id]
      return
    }

    if (typeof this.selected == 'undefined') return;
    const indexOf = this.selected.indexOf(id)
    if (indexOf != -1) { this.selected.splice(indexOf, 1) }
    else {
      if (!this.disabled.includes(id)) this.selected.push(id);
    }
  }

  isSelected(id) {
    if (typeof this.selected == 'undefined') return;
    const indexOf = this.selected.indexOf(id);
    if (indexOf == -1) return false;
    return true;
  }

  isDisabled(id) {
    return this.disabled.includes(id);
  }


  search(value) {

    if(this.selected == null) this.selected = [];
    if(typeof this.selected == 'undefined') this.selected = [];
  
    const filters = []

    filters.push(...this.filters)
    filters.push((invoice:DbInvoice) => invoiceFilter({invoice: invoice, value: value.toLowerCase()}))
    const pagedResult = this._invoicesService.browseInvoices({
      allResults: [],
      filters: filters
    })

    this.invoices = pagedResult.allResults
  }


  clear() {
    let value = "";
    this.search(value);
  }

  close(option) {
    // const selected = this.selectedRoomsList.selectedOptions.selected.map(x=>x.value)
    // console.log(selected);
    this._dialog.close({ action: option, selected: this.selected });
  }
}
