<div class="relative flex flex-col max-w-180 min-h-full md:min-h-auto md:min-w-180 max-h-full sm:max-h-screen overflow-hidden">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'wysylanie_potwierdzen' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-auto">

            <mat-tab-group class="vertically-tabs" mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight
                (selectedIndexChange)="onTabChanged($event)" dynamicWidth>
                <mat-tab label="{{'wyslij_potwierdzenie' | transloco}}">

                    <div class="w-full mb-4">
                        <p class="font-medium">
                            {{'rodzaj_wiadomosci' | transloco}}
                        </p>
                        <mat-radio-group [formControl]="form.controls.messageType" class="-ml-2" color="primary">
                            <mat-radio-button [value]="'EMAIL'">E-mail</mat-radio-button>
                            <mat-radio-button [value]="'SMS'">SMS</mat-radio-button>
                        </mat-radio-group>

                    </div>
                    <mat-form-field class="w-full" *ngIf="form.controls.messageType.value == 'EMAIL'">
                        <mat-label>{{'nadawca' | transloco}}</mat-label>
                        <mat-select matInput [formControl]="form.controls.smtpId">
                            <mat-option [value]="sender.smtpId" *ngFor="let sender of senders">
                                {{sender.email}} 
                                <span class="text-orange-500"  *ngIf="sender.isVerified == false">({{'nieaktywne' | transloco}})</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="w-full" *ngIf="form.controls.messageType.value == 'EMAIL' && senders.length == 0">
                        <fuse-alert type="warning" class="mt-2 mb-4" appearance="outline">
                            {{'nie_utworzyles_jeszcze_zadnego_konta_pocztowego_mozesz_to_zrobic_w_zakladce_komunikacja_konto_pocztowe'
                            | transloco}}
                        </fuse-alert>
                    </div>

                    <fuse-alert *ngIf="senderAccount != null && senderAccount?.isVerified == false && form.controls.messageType.value == 'EMAIL'" type="warning"
                        class="mt-2 mb-4" appearance="outline">
                        <span fuseAlertTitle>{{'uwaga' | transloco}}</span>
                        {{'konto_pocztowe_nie_zostalo_zweryfikowane_nie_mozesz_wyslac_wiadomosci_bezposrednio_z_konta_pocztowego'
                        | transloco}}
                    </fuse-alert>

                    <ng-container *ngIf="form.controls.messageType.value == 'EMAIL'">
                        <p class="font-medium">{{'odbiorcy' | transloco}}: {{form.controls.recipients.value.length}}</p>
                        <mat-form-field class="w-full mt-1" [subscriptSizing]="'dynamic'" appearance="outline">
                            <mat-chip-grid #chipGrid aria-label="Reciver selection"
                                [formControl]="form.controls.recipients">
                                @for (receiver of form.controls.recipients.value; track receiver) {
                                <mat-chip-row (removed)="removeReceiver(receiver)">
                                    {{receiver}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + receiver">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip-row>
                                }

                            </mat-chip-grid><br />
                            <input [placeholder]="translate.translate('nowy_odbiorca')+'...'"
                                [formControl]="receiverInputControl" [matChipInputFor]="chipGrid"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addReceiver($event)" (focusout)="handleFocusOutAndChange()" />
                        </mat-form-field>
                        <p class="text-red-600" *ngIf="receiverAlreadyAdded">
                            {{'ten_odbiorca_zostal_juz_dodany' | transloco}}
                        </p>
                        <p class="text-red-600" *ngIf="invalidEmailFormat">
                            {{'nieprawidlowy_adres_email' | transloco}}
                        </p>
                    </ng-container>

                    <mat-form-field class="w-full" *ngIf="form.controls.messageType.value == 'SMS'">
                        <mat-label>{{'odbiorca' | transloco}}</mat-label>
                        <input matInput [formControl]="form.controls.recipientSms" phoneNumberDirective>
                    </mat-form-field>

                    <div class="w-full mt-3">
                        <mat-search-select [valueProperty]="'templateId'" [displayProperty]="'templateName'"
                            [label]="'szablon' | transloco" [options]="templates"
                            [value]="form.controls.templateId.value"
                            (valueChanges)="setTemplate($event)"></mat-search-select>
                    </div>


                    <div class="w-full" *ngIf="templates.length == 0">
                        <fuse-alert type="info" class="mt-2 mb-4" appearance="outline">
                            <span fuseAlertTitle>{{'szablony_wiadomosci' | transloco}}</span>
                            {{'nie_utworzyles_jeszcze_zadnego_szablonu_mozesz_to_zrobic_w_zakladce_komunikacja_szablony_wiadomosci'
                            | transloco}}
                        </fuse-alert>
                    </div>

                    <mat-form-field class="w-full" *ngIf="form.controls.messageType.value == 'EMAIL'">
                        <mat-label>{{'temat' | transloco}}</mat-label>
                        <input matInput [formControl]="form.controls.subject" type="text" />
                    </mat-form-field>

                    <ng-container class="w-full" *ngIf="form.controls.messageType.value == 'EMAIL'">
                        <p class="font-medium">{{'tresc_wiadomosci' | transloco}}</p>
                        <quill-editor #quillEditor [modules]="quillModules"  [formControl]="form.controls.message" class="mt-2 w-full"  (onContentChanged)="onContentChanged($event)"
                            [bounds]="'self'"></quill-editor>
                    </ng-container>

                    <mat-form-field class="w-full" *ngIf="form.controls.messageType.value == 'SMS'">
                        <mat-label>{{'tresc_wiadomosci' | transloco}}</mat-label>
                        <textarea matInput [formControl]="form.controls.smsMessage" cdkAutosizeMinRows="4" rows="4">
                        </textarea>
                    </mat-form-field>

                    <ng-container *ngIf="form.controls.messageType.value == 'SMS'">
                        <fuse-alert type="info" class="mt-2 mb-4" appearance="outline">
                            {{'wiadomosc_sms_mozesz_wyslac_tylko_z_urzadzenia_ktore_obsluguje_wysylanie_sms' |
                            transloco}}
                        </fuse-alert>
                    </ng-container>

                    <mat-checkbox class="-ml-2" *ngIf="form.controls.messageType.value == 'EMAIL' && senderAccount?.isVerified == true"
                        [formControl]="form.controls.sendCopyToSender" color="primary">
                        {{'wyslij_kopie_rowniez_do_mnie' | transloco}}
                    </mat-checkbox>

                    <div class="w-full" *ngIf="filesList.length > 0 && form.controls.messageType.value == 'EMAIL'">

                        <div class="flex items-center mt-12">
                            <mat-icon [svgIcon]="'heroicons_solid:paper-clip'"></mat-icon>
                            <div class="ml-2 font-semibold">({{filesList.length}}/{{filesLimit}}) {{'zalaczone_pliki' |
                                transloco}}</div>
                        </div>

                        <div class="flex flex-wrap -m-3 mt-3">

                            <ng-container *ngFor="let file of filesList; let i = index">
                                <!-- {{filesList | json}} -->
                                <div class="flex">
                                    <app-file-attachment [name]="file.name" [type]="file.type" [url]="file.path"
                                        [size]="file.size" [extension]="file.extension"></app-file-attachment>
                                    <button (click)="removeAttachment(i)" class="w-8 h-8 min-h-8 self-center"
                                        mat-icon-button [matTooltip]="'usun' | transloco">
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="form.controls.messageType.value == 'EMAIL' && senderAccount?.isVerified == true"
                        (click)="imageInput.click()" (drop)="onDrop($event)" (dragover)="preventDefaults($event)"
                        class="flex justify-center h-40 min-h-40 max-h-40 border-2 border-dashed border-gray-300 rounded-2xl mt-6 cursor-pointer">
                        <div class="text-2xl text-center font-bold text-hint mt-16">
                            {{'kliknij_lub_przeciagnij_i_zrzuc_tutaj_plik' | transloco}}
                        </div>
                    </div>
                    <input #imageInput type="file" style="display:none;" multiple (change)="handleFileInput($event)">





                </mat-tab>
                <mat-tab label="{{'wyslane_wiadomosci' | transloco}}({{messages?.length}})">
                    <div class="p-3">
                        <ng-container *ngIf="messages.length > 0; else noMessages">
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let message of messages;">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <date-formatter [date]="message.addDate" [showTime]="true"></date-formatter>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{ message?.topic }}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div>
                                        <b>{{'odbiorca' | transloco}}:</b> {{message?.recipient}}
                                    </div>

                                    <div class="mt-11 mb-10 border-t dark:border-gray-800"></div>

                                    <div [innerHTML]="message?.contents">
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <!--Footer-->
    <div
        class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <button mat-stroked-button mat-dialog-close class="me-2">{{'anuluj' | transloco}}</button>
                <button *ngIf="senderAccount?.isVerified == true && form.controls.messageType.value == 'EMAIL'"
                    [disabled]="form.invalid || processing || form.controls.recipients.value.length == 0"
                    mat-flat-button color="primary" (click)="sendConfirmation()">
                    {{'wyslij' | transloco}}
                </button>

                <a *ngIf="(senderAccount?.isVerified == false || senderAccount == null) && form.controls.messageType.value == 'EMAIL'" (click)="openOutlook()">
                    <button [disabled]="form.invalid" mat-flat-button color="primary">
                        {{'wyslij' | transloco}}
                    </button>
                </a>

                <a *ngIf="form.controls.messageType.value == 'SMS'" (click)="sendSMS()">
                    <button mat-flat-button color="primary">
                        {{'wyslij_sms' | transloco}}
                    </button>
                </a>

            </div>
        </div>
    </div>
</div>

<ng-template #noMessages>
    <fuse-alert [type]="'primary'" [appearance]="'outline'">
        {{'brak_wyslanych_wiadomosci' | transloco}}
    </fuse-alert>
</ng-template>