<div class="relative flex flex-col w-full sm:max-w-240 sm:min-w-120 max-h-full sm:max-h-screen">
    
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'zrodlo_rezerwacji' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto mb-18 p-6 overflow-y-scroll">

            <!-- New label -->
            <div class="flex items-center justify-start w-full">
                <mat-form-field class="w-full my-2" subscriptSizing="dynamic">
                    <mat-label>{{'nowe_zrodlo_rezerwacji' | transloco}}</mat-label>
                    <ngx-colors #colorPicker  ngx-colors-trigger matPrefix [(ngModel)]="defaultSourceColor"></ngx-colors>
                    <input matInput class="ps-2" [(ngModel)]="sourceName"/>
                    <button mat-icon-button (click)="addSource()" matSuffix [matTooltip]="'dodaj' | transloco" matTooltipPosition="above">
                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <!-- Labels -->
            <div class="flex flex-col w-full  mt-4">

                <!-- Divider -->
                <div class="w-full border-t my-4"></div>

                <div class="text-md font-semibold">{{'wlasne_zrodla' | transloco}}</div>
                <!-- Label -->
                <ng-container *ngFor="let source of sources">
                    <mat-form-field class="w-full my-2" subscriptSizing="dynamic">
                        <input matInput class="ps-2" (change)="onSourceNameChange(source, $event)" [value]="source.displayName" [readonly]="source.isCustom ? false : true">
                        <ngx-colors *ngIf="source.isCustom" [format]="'hex'" ngx-colors-trigger matPrefix (input)="onSourceColorChange(source, $event)" [(ngModel)]="source.color"></ngx-colors>
                        <button *ngIf="source.isCustom" (click)="showConfirmDeleteSource(source)" mat-icon-button matSuffix [matTooltip]="'usun' | transloco" matTooltipPosition="above">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                        </button>
                    </mat-form-field>
                </ng-container>

                <!-- Divider -->
                <div class="w-full border-t my-4"></div>

                <div class="text-md font-semibold">{{'wbudowane_zrodla' | transloco}}</div>

                <ng-container *ngFor="let source of builtInSources">
                    <mat-form-field class="w-full my-2" subscriptSizing="dynamic">
                        <input matInput class="ps-2" (change)="onSourceNameChange(source, $event)" [value]="source.displayName" [readonly]="source.isCustom ? false : true">
                        <app-source-avatar matPrefix *ngIf="source.isCustom == false" [sourceId]="source.sourceId" size="sm"></app-source-avatar>
                       
                    </mat-form-field>
                </ng-container>
            </div>

        </div>
    </div>

    <!--Footer-->
    <div class="absolute inset-x-0 bottom-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 border-t z-10 bg-gray-50 dark:bg-gray-900">
        <div class="flex items-center">
            <button mat-stroked-button mat-dialog-close class="mc-button ml-auto">{{'zamknij' | transloco}}</button>
        </div>
    </div>
</div>
