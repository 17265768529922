// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const version  =  require('../../package.json').version+ '.dev';
export const environment = {
  production: false,
  version: version,
  ACCESS_TOKEN: "a_token",
  REFRESH_TOKEN: "r_token",
  staticServerUrl: 'https://mobile-calendar-sandbox.s3.eu-north-1.amazonaws.com',
  facebookAppId: 'TUTAJ_TWÓJ_APP_ID',
  googleClientId: '846098853012-al66j9dgf24nv2h7eb9qt3t3uqcuc10e.apps.googleusercontent.com',
  firebase: {
    apiKey: "AIzaSyDET86TuKbTzx11AcaYD0rsXfOlIhVCNtc",
    authDomain: "mobile-calendar-push.firebaseapp.com",
    databaseURL: "https://mobile-calendar-push.firebaseio.com",
    projectId: "mobile-calendar-push",
    storageBucket: "mobile-calendar-push.appspot.com",
    messagingSenderId: "1043883092002",
    appId: "1:1043883092002:web:f78f8405741e6f14df027b",
    vapidKey: "BI50jKRIPgWji8WBd5OjBh0E1saRi3gAf93ALRXeI8_AtTJsbvgfXcHS8u6jJ180-eM8OYvIwgz_KxMr8x9eAiY"
  },
  host: "https://appsandbox.mobile-calendar.com"
};
export const api_v1 = 'https://apisandbox.mobile-calendar.com/v1/';

export const languages = [
  { id: 'pl', name: 'Polski'},
  { id: 'en', name: 'English'},
  { id: 'de', name: 'Deutsch'},
  { id: 'es', name: 'Español'},
  { id: 'fr', name: 'Français'},
  { id: 'it', name: 'Italiano'},
  { id: 'ru', name: 'Pyccкий'}
];

export const short_languages = ['en', 'pl', 'de', 'fr', 'es', 'it', 'ru'];
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
