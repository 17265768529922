<div class="flex flex-col max-w-180 max-h-screen">
    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{'drukuj_grafik' | transloco}}</div>
        <button mat-icon-button [tabIndex]="-1" mat-dialog-close>
            <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
        </button>
    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <div class="flex flex-col flex-auto sm:mb-18 p-6 sm:overflow-y-auto">
            <form [formGroup]="selectDatesForm">

                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'" (click)="picker.open()" required>
                    <mat-label>{{'od' | transloco}} - {{'do' | transloco}}</mat-label>
                    <mat-icon matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="from" readonly>
                        <input matEndDate formControlName="to" readonly (dateChange)="changeDateRange()">
                    </mat-date-range-input>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <mat-error
                        *ngIf="selectDatesForm?.controls?.from.hasError('required') || selectDatesForm.controls?.to.hasError('required')">
                        {{'to_pole_jest_wymagane' | transloco}}
                    </mat-error>
                </mat-form-field>
                <fuse-alert *ngIf="invalidRange" class="mt-6" [type]="'error'" [appearance]="'outline'">
                    {{'zakres_pomiedzy_datami_wynosi_od_7_do_31_dni' | transloco}}
                </fuse-alert>

            </form>
        </div>
    </div>
    <!--Footer-->
    <div
        class="sm:absolute sm:inset-x-0 py-4 pr-4 pl-1 sm:pr-8 sm:pl-7 sm:bottom-0 border-b sm:border-t sm:border-b-0 dark:border-gray-800 z-10 bg-gray-50 dark:bg-transparent">
        <div class="flex items-end">
            <div class="ml-auto">
                <!-- Confirm -->
                <button mat-dialog-close mat-stroked-button class="me-2">
                    {{'anuluj' | transloco}}
                </button>
                <button mat-flat-button [disabled]="!selectDatesForm.valid" (click)="close(true)" [color]="'primary'">
                    {{"wybierz" | transloco}}
                </button>
            </div>
        </div>
    </div>
</div>








