import { TranslocoService } from "@ngneat/transloco";
import { Source } from "./Source";
import * as moment from 'moment';
import { SettingsService } from "../services/settings.service";
import { Utils } from "../others/utils";
import { inject } from "@angular/core";
import { ApplicationData } from "../data/application-data";
import { DbSource } from "./DbSource";
import { AdditionalServicesService } from "../services/additional-services.service";
import { HTMLUtils } from "../utils/html.utils";


export class HistoryUtils {

    private readonly _translate: TranslocoService
    private readonly _settings: SettingsService
    private readonly _sources: Source[] = [];
    private readonly _additionalServicesService: AdditionalServicesService;
    private readonly _clients: {};
    private readonly _rooms: {};

    private readonly dateFormat: string = "YYYY-MM-dd HH:mm:ss";


    constructor(
        settings: SettingsService,
        translate: TranslocoService,
        sources: Source[],
        additionalServicesService: any,
        clients: any,
        rooms: any,
        dateFormat: string
    ) {
        this._settings = settings;
        this._translate = translate;
        this._sources = sources;
        this._additionalServicesService = additionalServicesService;
        this._clients = clients;
        this._rooms = rooms;
        this.dateFormat = dateFormat;
    }

    public PrepareChanges(changes) {

        for (let i = 0; i <= changes.length - 1; i++) {
            let c = changes[i];
            c.type = 'normal';

            if (c.key != "editDate"
                && c.key != "cena_za_nocleg"
                && c.key != "pricePerRoom"
                && c.key != "cena_za_dodatki"
                && c.key != "price") {

                c.action = 'updated';
                if (Utils.isNullOrEmpty(c.previous)) c.action = 'added';

                // Set valid action type
                if (c.key == 'roomId') {

                    c.action = 'moved';

                    if (!Utils.isNullOrEmpty(c.previous)) {
                        const prevRoom = this._rooms['"' + c.previous + '"'];
                        if (typeof prevRoom != 'undefined') {
                            c.previous = prevRoom.name
                        }
                    }
                    if (!Utils.isNullOrEmpty(c.previous)) {
                        const nextRoom = this._rooms['"' + c.current + '"'];
                        if (typeof nextRoom != 'undefined') {
                            c.current = nextRoom.name
                        }
                    }
                }

                else if (!Utils.isNullOrEmpty(c.previous) &&
                    c.previous != '00:00:00' &&
                    c.previous != 0) {

                    if (Utils.isNullOrEmpty(c.action)) {
                        c.action = 'updated';
                    }
                }
                else {
                    if (Utils.isNullOrEmpty(c.action)) {
                        c.action = 'added';
                    }
                }

                if (c.key == 'additionalServices') {
                    changes.splice(i, 1);
                    changes.push(...this.detectChangesOfAdditionalServices(c.previous, c.previousSuffix, c.current, c.currentSuffix));
                }

                if (c.key == 'arrival') {

                    if (typeof c.dateTransformed == 'undefined') {
                        let prev = moment.utc(c.previous).locale(this._translate.getActiveLang()).local().format(this.dateFormat)
                        let cur = moment.utc(c.current).locale(this._translate.getActiveLang()).local().format(this.dateFormat)

                        c.current = cur;
                        c.previous = prev;

                        c.dateTransformed = true
                    }

                }


               

                if (c.key == 'departure') {

                    if (typeof c.dateTransformed == 'undefined') {
                        let prev = moment.utc(c.previous).locale(this._translate.getActiveLang()).local().format(this.dateFormat)
                        let cur = moment.utc(c.current).locale(this._translate.getActiveLang()).local().format(this.dateFormat)

                        c.current = cur;
                        c.previous = prev;

                        c.dateTransformed = true
                    }
                }

                if (c.key == 'description') {
                    // if (Utils.isJSON(c.previous) && Utils.isJSON(c.current)) {


                        let text = '';
                        let prevDesc = c.previous;
                        let currDesc = c.current;

                        let changedFields = [];
                        Object.entries(currDesc).forEach(element => {
                            // console.log(element, element[1], prevDesc[element[0]])
                            if (element[1] != prevDesc[element[0]]) {
                                changedFields.push({ field: element[0], from: prevDesc[element[0]], to: element[1] })
                                // console.log("pushed change: ",{ field: element[0], from: prevDesc[element[0]], to: element[1] })
                            }
                        });

                        changes.splice(i, 1);
                        changedFields.forEach(e => {

                            let action = 'added'
                            if (!Utils.isNullOrEmpty(e.from)) action = 'updated'

                            let key = 'room_description_default'
                            let hasFlag = false
                            let flag = null

                            if(e.field != 'default') { 
                                action = 'added_nested'
                                if (!Utils.isNullOrEmpty(e.from)) action = 'updated_nested'
                                key = 'room_description'
                                flag = ApplicationData.AppFlagCodes[e.field]
                                if(flag) {
                                    hasFlag = true
                                }
                            }

                            e.from = HTMLUtils.getText(e.from)
                            e.to = HTMLUtils.getText(e.to)
                            
                            changes.push(
                                { key: key, name: e.field, type: 'normal', hasFlag: hasFlag, flag: flag, action: action, previous: e.from, current: e.to }
                            )
                           
                            // console.log("pushed changes",  { key: 'room_description', name: e.field, type: 'flag', action: action, previous: e.from, current: e.to })
                            // text += `Wersja ${e.field} z ${e.from} na ${e.to},`
                        });

                    // }
                }
                // Fix display
                 
                
                // Share in offer
                if (c.key == 'shareInOffer') {
                    c.previous = this.getBoolean(c.previous);
                    c.current = this.getBoolean(c.current);
                }

                // Correct checkIn and checkOut displaying
                if (c.key == 'checkIn') {
                    c.previous = c.previous.slice(0, 5);
                    c.current = c.current.slice(0, 5);
                }

                if (c.key == 'checkOut') {
                    c.previous = c.previous.slice(0, 5);
                    c.current = c.current.slice(0, 5);
                }

                // Correct source name
                if (c.key == 'sourceId') {

                    if (typeof c.previous != 'undefined') {
                        const prevSource = this._sources.find(x => x.sourceId == c.previous);
                        if (typeof prevSource != 'undefined') c.previous = prevSource.name;
                    }

                    if (typeof c.current != 'undefined') {
                        const currentSource = this._sources.find(x => x.sourceId == c.current);
                        if (typeof currentSource != 'undefined') c.current = currentSource.name;
                    }
                }

                // Correct alimentation name
                if (c.key == 'meal') {
                    const prev = ApplicationData.AlimentationTypes.find(x => x.id == c.previous);
                    if (typeof prev != 'undefined') {
                        c.previous = this.tryTranslate(prev.translation);
                    }
                    const next = ApplicationData.AlimentationTypes.find(x => x.id == c.current);
                    if (typeof next != 'undefined') {
                        c.current = this.tryTranslate(next.translation);
                    }
                }

                // Correct payment status
                if (c.key == 'paymentStatus') {
                    c.action = 'updated';
                    c.previous = this.getPaymentStatus(c.previous);
                    c.current = this.getPaymentStatus(c.current);
                }

                // Correct payment status
                // (Adding currency back in time)
                if (c.key == 'price'
                    || c.key == 'prepayment'
                    || c.key == 'discount'
                    || c.key == 'pricePerRoomPerNight'
                    || c.key == 'pricePerRoom'
                    || c.key == 'pricePerMeal'
                    || c.key == 'pricePerAdditionalServices') {

                    c.previous = parseFloat(c.previous)?.toFixed(2) + ' ' + c.previousSuffix;
                    c.current = parseFloat(c.current)?.toFixed(2) + ' ' + c.currentSuffix;
                }

                else if (c.key == 'registration') {
                    c.action = 'updated';
                    c.previous = this.getCheckInOut(c.previous);
                    c.current = this.getCheckInOut(c.current);
                }


                // Add fake status change...
                else if (c.key == 'prepaymentDeadline') {
                    if (!Utils.isDateSet(c.previous)) {
                        c.action = 'added';
                    }
                    else if (!Utils.isDateSet(c.current)) {
                        c.action = 'deleted';
                    }

                    if (typeof c.dateTransformed == 'undefined') {
                        let prev = moment.utc(c.previous).locale(this._translate.getActiveLang()).local().format(this.dateFormat)
                        let cur = moment.utc(c.current).locale(this._translate.getActiveLang()).local().format(this.dateFormat)

                        c.current = cur;
                        c.previous = prev;

                        c.dateTransformed = true
                    }
                }

                else if (c.key == 'additionalService=>price') {
                    c.action = 'updated_nested';
                }

                else if (c.key == 'additionalService=>status') {
                    c.action = 'updated_nested';
                }

                else if (c.key == 'clientId') {
                    c.previous = this.getClientFullName(c.previous) + `(${c.previous})`;
                    c.current = this.getClientFullName(c.current) + `(${c.current})`;
                }

                else if (c.key == 'clientType') {
                    c.action = 'updated';
                    c.previous = this.getClientType(c.previous);
                    c.current = this.getClientType(c.current);
                }

                else if (c.key == 'discountType') {
                    c.previous = this.getDiscountTypeName(c.previous);
                    c.current = this.getDiscountTypeName(c.current);
                }

                else if (c.key == 'country') {
                    const prevCountry = ApplicationData.Countries.find(x => x.id == c.previous);
                    const nextCountry = ApplicationData.Countries.find(x => x.id == c.current);


                    if (typeof prevCountry != 'undefined') {
                        c.previous = this._translate.translate('country_' + prevCountry.id)
                        if (c.prevoius?.length == 0) c.previous = prevCountry.name;

                    }
                    if (typeof nextCountry != 'undefined') {
                        c.current = this._translate.translate('country_' + nextCountry.id)
                        if (c.current?.length == 0) c.current = nextCountry.name;

                    }
                }

                else if (c.key == 'service') {
                    c.previous = this.getRoomStateById(c.previous)
                    c.current = this.getRoomStateById(c.current)
                }

                else if (c.key == 'discount') {
                    // Jezeli następna wartość nie istnieje, zmień akcję na `usunięto`
                    if (Utils.isNullOrEmpty(c.current)) { c.action = 'deleted' };

                    c.previous = parseInt(c.previous) + ' ' + c.previousSuffix
                    c.current = parseInt(c.current) + ' ' + c.currentSuffix

                }
            }
        }
        return changes;
    }
    private detectChangesOfAdditionalServices(previous, previousSuffix, current, currentSuffix) {
        let results = [];
        // For each additional service check if exists in previous (if not, it has been added)
        if (Array.isArray(current)) {
            current.forEach(item => {
                const previousItem = previous.find(x => x.additionalServiceId == item.additionalServiceId)
                // Detect updates in price or status
                if (previousItem) {

                    let currentAdditionalService = this.getServiceById(item.additionalServiceId)
                    if (previousItem.status != item.status) {
                        results.push(
                            { key: 'additionalService=>status', name: currentAdditionalService.displayName, action: 'updated_nested', previous: this.getServicePaymentStatus(previousItem.status), current: this.getServicePaymentStatus(item.status) }
                        );
                    }

                    if (previousItem.price != item.price) {
                        results.push(
                            { key: 'additionalService=>price', name: currentAdditionalService.displayName, action: 'updated_nested', previous: previousItem.price + ' ' + previousSuffix, current: item.price + ' ' + currentSuffix }
                        );
                    }

                }
                // Detect added
                else {
                    const currentService = this.getServiceById(item.additionalServiceId)
                    results.push(
                        { key: 'additionalService', action: 'added_nested', previous: null, current: currentService.displayName }
                    );
                }
            })

        }

        // Detect deleting from previous
        if (Array.isArray(previous)) {
            previous.forEach(item => {
                const currentItem = current.find(x => x.additionalServiceId == item.additionalServiceId)
                if(!currentItem) {
                    const previousService = this.getServiceById(item.additionalServiceId)
                    results.push(
                        { key: 'additionalService', action: 'deleted', previous: previousService.displayName , current: null }
                    );
                }
            })
        }

        return results;
    }

    private getServiceById(serviceId: number) {

        const service = this._additionalServicesService.getById(serviceId)

        if(service) return service
        else return {
            additionalServiceId: serviceId,
            status: 0,
            price: 0,
            displayName: ''
        }
   
    }

    private getServicePaymentStatus(status): string {
        return (status == '0' || status == 0) ? this._translate.translate('brak_wplaty') : this._translate.translate('zaplacono');
    }

    private getBoolean(value: any) {
        let response = parseInt(value);
        switch (parseInt(value)) {
            case 0: response = this._translate.translate('nie'); break;
            case 1: response = this._translate.translate('tak'); break;
        }
        return response;
    }
    private getCheckInOut(registration: any) {
        let response = parseInt(registration);
        switch (parseInt(registration)) {
            case 0: response = this._translate.translate('brak'); break;
            case 1: response = this._translate.translate('zameldowany'); break;
            case 2: response = this._translate.translate('wymeldowany'); break;
        }

        return response;
    }

    private getDiscountTypeName(discountType: any) {
        let response = parseInt(discountType);
        switch (parseInt(discountType)) {
            case 0: response = this._translate.translate('procentowy'); break;
            case 1: response = this._translate.translate('walutowy'); break;
        }
        return response;
    }

    private getPaymentStatus(paymentStatus: number) {
        let response = paymentStatus;
        switch (paymentStatus) {
            case 0: response = this._translate.translate('brak_wplaty'); break;
            case 1: response = this._translate.translate('wplacony_zadatek'); break;
            case 2: response = this._translate.translate('wplacona_calosc'); break;
            case 3: response = this._translate.translate('oczekiwanie_na_platnosc'); break;
        }
        return response;
    }

    private getClientType(clientType: number | string) {

        if (typeof clientType != 'number')
            clientType = parseInt(clientType);
        let response = '';
        switch (clientType) {
            case 0: response = this._translate.translate('klient'); break;
            case 1: response = this._translate.translate('staly_klient'); break;
            case 2: response = this._translate.translate('niechciany_klient'); break;
        }
        return response;
    }
    private getRoomStateById(state: number | string) {
        let roomState = state
        if (typeof state != 'number') roomState = parseInt(state);
        let response = roomState;
        switch (roomState) {
            case 0: response = this._translate.translate('czysty'); break;
            case 1: response = this._translate.translate('brudny'); break;
            case 2: response = this._translate.translate('w_trakcie_sprzatania'); break;
        }
        return response;
    }

    private getClientFullName(clientId: number) {

        let client;
        if (Array.isArray(this._clients)) {
            client = this._clients.find(x => x.clientId == clientId);
        } else {
            client = this._clients[clientId]
        }

        if (typeof client != 'undefined') {
            return `${client.forename} ${client.name}`;
        }
        return this._translate.translate('brak_danych');
    }

    // public static getLatestChange() {
    //     return this.history[0];
    // }
    private tryTranslate(text) {
        if (typeof text != 'string' || text.length <= 0) { return; }

        const parts = text.split(' ');
        let translated = '';
        parts.forEach(part => {
            translated += this._translate.translate(part) + ' '
        })
        return translated;
    }

}
