import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SourceAvatarComponent } from 'app/core/shared/components/source-avatar/source-avatar.component';
import { DateFormatterComponent } from '../date-formatter/date-formatter.component';
import { DbReservation } from '../../models/DbReservation';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { CONST } from '../../data/const';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { ApiService } from '../../services/api.service';
import { Subscription, take } from 'rxjs';
import { SynchronizationService } from '../../services/synchronization.service';
import { RoomsService } from '../../services/rooms.service';
import { DbRoom } from '../../models/DbRoom';
import { DbClient } from '../../models/DbClient';
import { ClientsService } from '../../services/clients.service';
import { SnackbarService } from '../../services/snackbar.service';
import { DataChangeTrackingService } from '../../services/data-change-tracking.service';
import { DialogService } from '../../services/dialog.service';
import { PanelService } from '../../services/panel.service';
import { MatDialog } from '@angular/material/dialog';
import { ReservationHistoryComponent } from '../reservation-history/reservation-history.component';
import { Utils } from '../../others/utils';
import { UserPermissions } from '../../models/UserPermissions';
import { PermissionsService } from '../../services/permissions.service';
import { ReservationsService } from '../../services/reservations.service';
import { IAPIResponse } from '../../services/app-init.service';

@Component({
  standalone: true,
  selector: 'app-reservation-row',
  templateUrl: './reservation-row.component.html',
  styleUrl: './reservation-row.component.scss',
  imports: [CommonModule, MatIconModule, MatButtonModule, TranslocoModule, MatTooltipModule, DateFormatterComponent, SourceAvatarComponent, MatMenuModule, MatDividerModule]
})
export class ReservationRowComponent implements OnChanges, OnDestroy {

  private readonly _subscriptions: Subscription[] = []
  @Input('showMoreButton') showMoreButton = false;
  @Input('reservation') reservation: DbReservation = null

  @Input('reservationId') reservationId: number = null
  room:DbRoom = null;
  client:DbClient = null;
  days = 0
  checkIn
  checkOut

  permissions = new UserPermissions({})
  constructor(
    public translate: TranslocoService,
    private _roomsService: RoomsService,
    private _clientsService:ClientsService,
    private _reservationsService:ReservationsService,
    private _permissionsService:PermissionsService,
    private _panelService:PanelService,
    private _dialogService:DialogService,
    private _apiService:ApiService,
    private _snackbarService:SnackbarService,
    private _dialog:MatDialog,
    private _synchronizationService:SynchronizationService,
    private _dataChangeTrackingService:DataChangeTrackingService,
    
  ) {
    
    this._subscriptions.push(this._permissionsService.currentPermissions.subscribe({
      next: (data) => {
        this.permissions = data
      }
    }))
   
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub=>sub.unsubscribe())
  }

  ngOnChanges(changes: SimpleChanges): void {

      if (Utils.isDefined(changes,'reservationId.currentValue')) {
        this.reservation = this._reservationsService.getById(this.reservationId)
      }

      else if (Utils.isDefined(changes,'reservation.currentValue')) {
        this.reservation = this._reservationsService.getById(this.reservation.reservationId)
      }
  
      if (Utils.isDefined(this.reservation,'roomId')) {
        this.room = this._roomsService.getById(this.reservation.roomId)
      }
  
      if(!this.reservation) return
      
      if(this.reservation.clientId > 0) {
        this.client = this._clientsService.getById(this.reservation.clientId)
      }
      
      this.days = (new Date(this.reservation.departure).getTime() - new Date(this.reservation.arrival).getTime()) / CONST.DAYINMILLIS;
      // this.guests = parseInt(this.reservation?.adults)
      // this.children = parseInt(this.reservation?.children)
      // this.arrival = new Date(this.reservation.arrival);
      // this.departure = new Date(this.reservation.departure);
      this.checkIn = this.reservation.checkIn.slice(0, 5);
      this.checkOut = this.reservation.checkOut.slice(0, 5);
   

  }
  
  public showHistory() {
    this._dialog.open(ReservationHistoryComponent, {
      // width: '600px',
      data: {
        reservation: this.reservation
      }
    })
  }

  public showReservation() {
    this._panelService.openUpdateReservationPanel(this.reservation)
  }

  public showCancelReservationConfirmation() {

    this._dialogService.confirmDeletingReservation(this.reservation).subscribe({
      next: (data) => {
        if (data != true) return
        this._snackbarService.showSnackBar(this.translate.translate('anulowano_rezerwacje'), 'success')
        this._synchronizationService.synchronizeWithPendingCheck('Canceling reservation')
        
      }
    });
  }
  
  public setReservationAsCheckedInOrCheckedOut(reservationId, registration) {
    this._apiService.setReservationAsCheckedInOrCheckedOut(reservationId, registration)
      .pipe(take(1))
      .subscribe({
        next: (response: IAPIResponse) => {

          if (response.status == "error") {
            this._snackbarService.showSnackBar(this.translate.translate('nie_udalo_sie_zmienic_statusu'), "error")
            return
          }

          if (response.status == 'success') {
            if(Utils.isDefined(response,"data.reservationId")) {
              this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
            }
            this._snackbarService.showSnackBar(this.translate.translate('status_meldunku_zostal_zaktualizowany'), "success")
            this._synchronizationService.synchronizeWithPendingCheck('updated reservation status')
          }

        }
      })
  }

  public setReservationPaymentStatus(reservationId, paymentStatus) {
    this._apiService.setReservationPaymentStatus(reservationId, paymentStatus)
    .pipe(take(1))
      .subscribe({
        next: (response: IAPIResponse) => {

          if (response.status == "error") {
            this._snackbarService.showSnackBar(this.translate.translate('nie_udalo_sie_zmienic_statusu'), "error")
            return
          }

          if (response.status == 'success') {
            if(Utils.isDefined(response,"data.reservationId")) {
              this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
            }
            this._snackbarService.showSnackBar(this.translate.translate('status_platnosci_zostal_zaktualizowany'), "success")
            this._synchronizationService.synchronizeWithPendingCheck('updated reservation status')
          }
        }
      })
  }

  public copyReservation(): void {

    this._dialogService.openSelectRoomDialogComponent({
      selectedIds: [],
      disabled: [this.reservation.reservationId],
      title: this.translate.translate('kopiuj'),
      confirmText: this.translate.translate('kopiuj'),
      range: { start: new Date(this.reservation.arrival), end: new Date(this.reservation.departure) }
    })
    .subscribe(
      (data) => {
        if (data.status == true) {
          this._apiService.copyReservation(this.reservation.reservationId, data.results).subscribe(
            {
              next: (response: IAPIResponse) => {

                if (response.status == "error") {
                  this._snackbarService.showSnackBar(this.translate.translate("nie_udalo_sie_skopiowac_rezerwacji"), "error")
                  return
                }

                if (response.status == 'success') {
                  this._synchronizationService.synchronizeWithPendingCheck("Copying reservations");
                }

                if(Utils.isDefined(response,"data.reservationId")) {
                  this._dataChangeTrackingService.addInternalChange(response.data.reservationId)
                }
                 
              }
            }
          )
        }
      }
    )

  }
}
