import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';
import { TranslocoService } from '@ngneat/transloco';
import { MatDialog } from '@angular/material/dialog';
import { SynchronizationService } from './synchronization.service';

@Injectable({
  providedIn: 'root'
})
export class InternetConnectionService {
  private online$ = new BehaviorSubject<boolean>(true);
  public offlineMode = new BehaviorSubject<boolean>(false);

  constructor(
    private toastService:ToastService,
    private translate:TranslocoService,
    private _dialog:MatDialog

    ) 
  {
    this.online$ = new BehaviorSubject<boolean>(navigator.onLine);
    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
  }

  private updateOnlineStatus() {
    
    // console.log(this.online$.value,navigator.onLine)
    if(this.online$.value == navigator.onLine) return

    this.online$.next(navigator.onLine);

    if(this.online$.value == false) {
      this.toastService.showToast({
        icon: 'heroicons_solid:exclamation-triangle',
        status: 'danger',
        title: this.translate.translate('uwaga'),
        message: this.translate.translate('brak_polaczenia_z_internetem'),
        timeToClose: 3000
      })
      this.offlineMode.next(true)
    }
   
    else if(this.online$.value == true) {
      this.toastService.showToast({
        icon: 'heroicons_solid:check-circle',
        status: 'success',
        title: this.translate.translate('uwaga'),
        message: this.translate.translate('przywrocono_polaczenie_z_internetem'),
        timeToClose: 3000
      })
      this.offlineMode.next(false)
      this._dialog.closeAll()
    }
  }

  public isOnline(): boolean {
    return this.online$.getValue();
  }

  public getOnlineStatus(): Observable<boolean> {
    return this.online$.asObservable();
  }

  public offlineModeOn()
  {
    this.offlineMode.next(true)
  }

   public offlineModeOff()
  {
    this.offlineMode.next(false)
  }

  
}