<div class="flex flex-row items-center justify-between" *ngIf="reservation">
    <div class="flex flex-row items-center justify-start px-0.5 max-w-140">
        <div class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden">
            <app-source-avatar [sourceId]="reservation?.sourceId" size="sm"></app-source-avatar>
        </div>
        <div class="min-w-0 ml-4" [ngClass]="{'line-through': reservation?.status == 0}">
            <div class="font-medium leading-5 truncate">
                <span *ngIf="!(reservation?.groupId > 0)">#{{reservation?.reservationNo}} </span>
                <span *ngIf="reservation?.groupId > 0">{{"grupa" | transloco}}: #{{reservation?.groupId}}</span>
                
                <span *ngIf="client != null">
                    {{client?.forename}} {{client?.name}}
                    <mat-icon *ngIf="client.clientType == 1" class="icon-size-5 mr-0"
                                [svgIcon]="'heroicons_solid:check-badge'" color="primary" [matTooltip]="translate.translate('staly_klient')">
                    </mat-icon>
                    <mat-icon *ngIf="client?.clientType == 2" class="icon-size-5 mr-0" [matTooltip]="translate.translate('niechciany_klient')"
                        [svgIcon]="'heroicons_solid:no-symbol'" color="warn">
                    </mat-icon>
                </span>
                <span *ngIf="client == null">
                    {{'blokada' | transloco}}
                </span>
                <span *ngIf="reservation?.registration == 1" class="rounded-full bg-primary-100 px-2 py-1 text-sm text-on-primary-100">
                    {{'zameldowano' | transloco}}
                </span>
                <span *ngIf="reservation?.registration == 2" class="rounded-full bg-primary-100 px-2 py-1 text-sm text-on-primary-100">
                    {{'wymeldowano' | transloco}}
                </span>
                
            </div>
            <div class="leading-5 truncate text-secondary">
                <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
                    <div class="flex items-center">
                        <span class="w-2 h-2 rounded-full" [style.background-color]="room?.color"></span>
                        <div class="ml-1.5 text-md text-secondary max-w-50 truncate">
                            {{room?.name}}
                        </div>
                    </div>
                    <div class="flex items-center">
                        <mat-icon class="icon-size-5 text-hint mr-0" [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                        <date-formatter class="ml-1.5 text-md text-secondary"
                            [date]="reservation?.arrival"></date-formatter>
                        &nbsp;-&nbsp;
                        <date-formatter class="text-md text-secondary" [date]="reservation?.departure"></date-formatter>
                    </div>
                    <div class="flex items-center">
                        <mat-icon class="icon-size-5 text-hint mr-0" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
                        <div class="ml-1.5 text-md text-secondary">
                            {{days}}x
                        </div>
                    </div>
                    <div class="flex items-center">
                        <mat-icon class="icon-size-5 text-hint mr-0" [svgIcon]="'heroicons_solid:user'"></mat-icon>
                        <div class="ml-1.5 text-md text-secondary">
                            {{reservation?.adults}}x
                        </div>
                        <div class="ml-1.5 text-md text-secondary" *ngIf="reservation?.children > 0">
                            {{reservation?.children}}x
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="showMoreButton">
        <button mat-icon-button [matMenuTriggerFor]="moreMenu">
            <mat-icon [svgIcon]="'heroicons_mini:chevron-right'"></mat-icon>
        </button>
        <mat-menu #moreMenu="matMenu">

            <button mat-menu-item 
            (click)="showReservation()"
            [disabled]="!permissions.has('editReservation')"
                [matTooltip]="!permissions.has('editReservation') ? translate.translate('brak_uprawnien'): ''">
              <mat-icon [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
              <span>{{'edytuj' | transloco}}</span>
            </button>

            <button mat-menu-item 
            (click)="showHistory()"
            [disabled]="reservation?.registration === '2' || !permissions.has('history')"
            [matTooltip]="!permissions.has('history') ? translate.translate('brak_uprawnien'): ''"
            >
              <mat-icon [svgIcon]="'heroicons_solid:clock'"></mat-icon>
              <span>{{'historia' | transloco}}</span>
            </button>

            <button mat-menu-item (click)="showCancelReservationConfirmation()"
                [disabled]="!permissions.has('deleteReservation')"
                [matTooltip]="!permissions.has('deleteReservation') ? translate.translate('brak_uprawnien'): ''"
                > 
              <mat-icon [svgIcon]="'heroicons_solid:trash'"></mat-icon>
              <span>{{'usun' | transloco}}</span>
            </button>

            <mat-divider></mat-divider>

            <button mat-menu-item 
            [disabled]="reservation?.registration === 1 || !permissions.has('registration')"
            [matTooltip]="!permissions.has('registration') ? translate.translate('brak_uprawnien'): ''"
                (click)="setReservationAsCheckedInOrCheckedOut(reservation?.reservationId, 1)"> 
              <mat-icon [svgIcon]="'heroicons_solid:arrow-left-on-rectangle'"></mat-icon>
              <span>{{'zamelduj' | transloco}}</span>
            </button>

            <button mat-menu-item 
            [disabled]="reservation?.registration === 2 || !permissions.has('registration')"
            [matTooltip]="!permissions.has('registration') ? translate.translate('brak_uprawnien'): ''"
                (click)="setReservationAsCheckedInOrCheckedOut(reservation?.reservationId, 2)">
              <mat-icon [svgIcon]="'heroicons_solid:arrow-right-on-rectangle'"></mat-icon>
              <span>{{'wymelduj' | transloco}}</span>
            </button>

            <mat-divider></mat-divider>

            <button mat-menu-item 
                [disabled]="reservation?.paymentStatus === 0"
                (click)="setReservationPaymentStatus(reservation?.reservationId, 0)">
              <mat-icon [svgIcon]="'heroicons_solid:currency-dollar'"></mat-icon>
              <span>{{'brak_wplaty' | transloco}}</span>
            </button>
            <button mat-menu-item 
                [disabled]="reservation?.paymentStatus === 1"
                (click)="setReservationPaymentStatus(reservation?.reservationId, 1)">
              <mat-icon [svgIcon]="'heroicons_solid:currency-dollar'"></mat-icon>
              <span>{{'wplacony_zadatek' | transloco}}</span>
            </button>
            <button mat-menu-item   [disabled]="reservation?.paymentStatus === 2"
            (click)="setReservationPaymentStatus(reservation?.reservationId, 2)"> 
              <mat-icon [svgIcon]="'heroicons_solid:currency-dollar'"></mat-icon>
              <span>{{'wplacono_calosc' | transloco}}</span>
            </button>

            <mat-divider></mat-divider>

            <button mat-menu-item 
                (click)="copyReservation()"
                [disabled]="!permissions.has('addReservation')"
                [matTooltip]="!permissions.has('addReservation') ? translate.translate('brak_uprawnien') : ''"
            > 
              <mat-icon [svgIcon]="'heroicons_solid:document-duplicate'"></mat-icon>
              <span>{{'kopiuj' | transloco}}</span>
            </button>
  
          </mat-menu>
    </ng-container>
</div>