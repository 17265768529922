import { Injectable, OnDestroy } from "@angular/core";
import { AppDB } from "../models/db";
import { BehaviorSubject, Observable, Subscription, from, map, of, tap } from "rxjs";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { DbMeal } from "../models/DbMeal";

@Injectable({
  providedIn: 'root'
})
export class MealsService implements OnDestroy {

  private _subscriptions: Subscription[] = [];
  private _config = new BehaviorSubject<DbMeal>(new DbMeal())
  public currentConfig: Observable<DbMeal> = this._config.asObservable()

  private _db: AppDB

  // Clean subscriptions
  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService,
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
          this._config.next(new DbMeal())
        }
      })
    )
    
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: async (data) => {
          if(data.table == 'meals') {
            this._config.next(data.value[0])
          }
        }
    }))
  }

  getConfig(): DbMeal {
    return this._config.value
  }

  getAlimentationPrices(id, adults, children, days) {

    let success = true
    let adultPrice = 0
    let childrenPrice = 0

    let total = 0
    switch(id) {

      case 0:
        adultPrice = 0
        childrenPrice = 0
      break;

      // BB_sniadanie
      case 1:
        adultPrice = this._config.value.bbAdult || 0
        childrenPrice = this._config.value.bbChildren || 0
      break;

      // HB_sniadanie_obiad
      case 2:
        adultPrice = this._config.value.halfBoardAdult || 0
        childrenPrice = this._config.value.halfBoardChildren || 0
      break;

      // FB_sniadanie_obiad_kolacja
      case 3:
        adultPrice = this._config.value.fullBoardAdult || 0
        childrenPrice = this._config.value.fullBoardChildren || 0
      break;

      // AI_all_inclusive
      case 4:
        adultPrice = this._config.value.allInclusiveAdult || 0
        childrenPrice = this._config.value.allInclusiveChildren || 0
      break;

      // obiad
      case 5:
        adultPrice = this._config.value.dinnerAdult || 0
        childrenPrice = this._config.value.dinnerChildren || 0
      break;

      // sniadanie_kolacja
      case 6:
        adultPrice = this._config.value.breakfastSupperAdult || 0
        childrenPrice = this._config.value.breakfastSupperChildren || 0
      break;

      // obiad_kolacja
      case 7:
        adultPrice = this._config.value.dinnerSupperAdult || 0
        childrenPrice = this._config.value.dinnerSupperChildren || 0
      break;

      // kolacja
      case 8:
        adultPrice = this._config.value.supperAdult || 0
        childrenPrice = this._config.value.supperChildren || 0
      break;

      default:
        adultPrice = 0
        childrenPrice = 0
      break;
    }

    if(adultPrice == 0 && childrenPrice == 0) {
      success = false
    }

    console.log(`[MealsService]: adultPrice: ${adultPrice}, childrenPrice: ${childrenPrice}, days: ${days}, adults: ${adults}, children: ${children}`)
    if(success) {
      total = ((adultPrice * days) * adults) + ((childrenPrice * days) * children)
    } else total = 0

    return {
      isSuccess: success,
      total: total,
      days: days,
      adultPrice,
      childrenPrice
    }
  } 

  getAll(): Observable<boolean> {

    if(!this._db) return of(true)
    this._config.next(new DbMeal());
    
    let startTime = 0

    const loader = from(this._db.transaction("r", this._db.meals, () => {
      startTime = performance.now();
      return this._db.meals.toArray();
    }))
    .pipe(
      map((meals: DbMeal[]) => {
        
        if(meals.length == 1) {
          this._config.next(meals[0]);
        }
        else {
          console.log(`[Meals Service]: Count: ${meals.length}`)
          this._config.next(new DbMeal());
        }
        return true;
      }),
      tap(() => console.log(`[Meals Service]: Built in ${performance.now() - startTime}ms.`))
    );

    return loader
  }

}
