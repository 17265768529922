import { Route } from '@angular/router';
import { DataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/no-auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PortalGuard } from './core/auth/guards/portal-guard';
import { SubscriptionGuard } from './core/auth/guards/subscription.guard';
import { ConfiguredAccountGuard } from './core/auth/guards/configured-account.guard';
import { RedirectSmtpConfigComponent } from './modules/admin/smtp-config/redirect-smtp-config/redirect-smtp-config.component';
import { OnlyNotConfiguredAccountGuard } from './core/auth/guards/only-not-configured-account.guard';
import { OnlyNotConfirmedEmailGuard } from './core/auth/guards/only-not-confirmed-email.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [


    // Redirect empty path to '/example'
    // { path: '', pathMatch: 'full', redirectTo: '/app' },
    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '/app' },
    { path: 'rules', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/pages/rules/rules.routes') },
    { path: 'privacy-policy', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/pages/privacy-policy/privacy-policy.routes') },
    { path: 'hard-reset', loadChildren: () => import('app/modules/hard-reset/hard-reset.routes') },
    
    // Sign out
    { 
        path: '',
        component: LayoutComponent,  
        data: { 
            layout: 'empty' 
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/sign-in' },
            { path: 'sign-out',  loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')}
        ]
    },
    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/sign-in' },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes') },
            { path: 'reset-password/:email/:token', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes') },
        ]
    },

    // Auth routes for unauthenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/sign-in' },
            { path: 'account-confirmation', canActivate: [OnlyNotConfirmedEmailGuard], loadChildren: () => import('app/modules/admin/account-confirmation/account-confirmation.routes')}, 
            { path: 'register-wizard', canActivate: [OnlyNotConfiguredAccountGuard], loadChildren: () => import('app/modules/admin/register-wizard/register-wizard.routes')}, 
            // { path: 'lock', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
        ]
    },


    // Landing route
    // Logged in normal user    
    {
        path: 'app',
        canActivate: [AuthGuard, PortalGuard, ConfiguredAccountGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: { appResolver: DataResolver },
        data: { portal: 'app' },
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'calendar' },
            { path: 'dashboard', data: { permissions: ['dashboard'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes') },
            { path: 'calendar', loadChildren: () => import('app/modules/admin/calendar/calendar.routes') },
            { path: 'pricing', data: { permissions: ['pricing'], minSubscription: 0 }, loadChildren: () => import('app/modules/admin/pricing/pricing.routes') },
            { path: 'statistics', data: { permissions: ['reservations'] }, loadChildren: () => import('app/modules/admin/statistics/statistics.routes') },
            { path: 'reservations',  data: { permissions: ['reservations'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/reservations/reservations.routes') },
            { path: 'reservations/import',  data: { permissions: ['reservations', 'importReservations' ], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/reservations/import/import.routes') },
            { path: 'reservations/confirmation/:id',  data: { permissions: ['dashboard', 'reservations'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/reservations/reservation-confirmation/reservation-confirmation.routes') },
            { path: 'rooms', data: { minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/rooms/rooms.routes') },
            { path: 'meals', data: { permissions: ['meals'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/meals/meals.routes') },
            { path: 'additional-services', data: { permissions: ['additionalServices'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/additional-services/additional-services.routes') },
            { path: 'clients', data: { permissions: ['customerDatabase'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/clients/clients.routes') },
            { path: 'history', data: { permissions: ['history'], minSubscription: 1 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/history/history.routes') },
            { path: 'my-account', loadChildren: () => import('app/modules/admin/my-account/my-account.routes') },
            { path: 'settings', loadChildren: () => import('app/modules/admin/settings/settings.routes') },
            { path: 'employees', data: { permissions: ['employees'], minSubscription: 1 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/employees/employees.routes') },
            { path: 'employees/:id', data: { permissions: ['employees'], minSubscription: 1 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/employees/employee/employee.route') },
            { path: 'tasks', data: { minSubscription: 0 }, loadChildren: () => import('app/modules/admin/tasks/tasks.route') },
            { path: 'booking-engine', data: { permissions: ['onlineBookingEngine'], minSubscription: 0 }, loadChildren: () => import('app/modules/admin/booking-engine/booking-engine.route') },
            { path: 'templates', data: { permissions: ['messagesTemplates'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/templates/templates.routes') },
            { path: 'smtp-config', data: { permissions: ['smtpConfiguration'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/smtp-config/smtp-config.route') },
            { path: 'invoices', data: { permissions: ['invoicing'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/invoices/invoices.routes') },
            { path: 'subscription', loadChildren: () => import('app/modules/admin/subscription/subscription.route') },
            { path: 'messages', data: { permissions: ['sendConfirmation'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/messages/messages.routes') },
            { path: 'reports/arrivals-departures', data: { permissions: ['arrivalsDeparturesReport'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/report-arrivals-departures/report-arrivals-departures.routes') },
            { path: 'reports/meals', data: { permissions: ['meals'], minSubscription: 0 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/report-meals/report-meals.routes') },
            { path: 'channel-manager', data: { minSubscription: 1 }, canActivate:[SubscriptionGuard], loadChildren: () => import('app/modules/admin/channel-manager/channel-manager.routes') },
            { path: '**', pathMatch: 'full', redirectTo: 'calendar' },
        ]
    },
    // Admin routes
    {
        path: 'housekeeper',
        canActivate: [AuthGuard, PortalGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            houseKeeperResolver: DataResolver
        },
        data: { portal: 'housekeeper' },
        children: [
            { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
            { path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes') },
            { path: 'calendar', loadChildren: () => import('app/modules/admin/calendar/calendar.routes') },
            { path: 'reservations', loadChildren: () => import('app/modules/admin/reservations/reservations.routes') },
            { path: 'history', loadChildren: () => import('app/modules/admin/history/history.routes') },
            { path: 'my-account', loadChildren: () => import('app/modules/admin/my-account/my-account.routes') },
        ]
    },
    { 
        path: 'newsletter', component: LayoutComponent, data: {  layout: 'empty' }, loadChildren: () => import('app/modules/newsletter/newsletter.routes')
    },
    { 
        path: 'confirm-email-address', component: LayoutComponent, data: { layout: 'empty' }, loadChildren: () => import('app/modules/confirm-email-address/confirm-email-address.routes')
    },
    // Redirects without route localization
    {
        path: 'smtp',
        component: LayoutComponent,
        data: {
            layout: 'empty',
            skipRouteLocalization: true
        },
        children: [
            { path: 'callback', component: RedirectSmtpConfigComponent, data: { skipRouteLocalization: true }}
        ]
    },
    // Remote login feature
    { path: 'remote-login', data: { layout: 'empty', skipRouteLocalization: true }, component: LayoutComponent, loadChildren: () => import('app/modules/remote-login/remote-login.routes') },
    { path: '', pathMatch: 'full', redirectTo: '/app' },
    { path: '**', pathMatch: 'full', redirectTo: '/app' },

];
