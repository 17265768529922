import { Overlay } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, inject, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { fuseAnimations } from '@fuse/animations/public-api';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DbRoom } from 'app/core/shared/models/DbRoom';
import { Utils } from 'app/core/shared/others/utils';
import { PanelService } from 'app/core/shared/services/panel.service';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { ReservationRowComponent } from '../../../core/shared/components/reservation-row/reservation-row.component';
import { ReservationsService } from 'app/core/shared/services/reservations.service';
import { RoomsService } from 'app/core/shared/services/rooms.service';
import { DbReservation } from 'app/core/shared/models/DbReservation';
import { statusFilter } from 'app/core/shared/filters/status.filter';
import { reservationClientFilter } from 'app/core/shared/filters/reservation-client.filter';
import { SettingsService } from 'app/core/shared/services/settings.service';
import { UserSettings } from 'app/core/shared/models/UserSettings';
import { UserPermissions } from 'app/core/shared/models/UserPermissions';
import { PermissionsService } from 'app/core/shared/services/permissions.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
    standalone: true,
    imports: [NgIf, SlicePipe, MatButtonModule, MatIconModule, FormsModule, MatTooltipModule, MatAutocompleteModule, ReactiveFormsModule, MatOptionModule, NgFor, RouterLink, NgTemplateOutlet, MatFormFieldModule, MatInputModule, NgClass, TranslocoModule, ReservationRowComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: () => {
                const overlay = inject(Overlay);
                return () => overlay.scrollStrategies.block();
            },
        },
    ],
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 10;
    @Input() minLength: number = 1;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    resultSets = { reservations: [], clients: [] }
    searchControl: UntypedFormControl = new UntypedFormControl();

    public permissions = new UserPermissions({})
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private _userSettings:UserSettings;
    /**
     * Constructor
     */
    constructor(
        public translate: TranslocoService,
        private panelService: PanelService,
        private _reservationsService:ReservationsService,
        private _roomsService:RoomsService,
        private _settingsService:SettingsService,
        private _permissionsService:PermissionsService
    ) {
        this._permissionsService.currentPermissions
        .pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (data) => {
                this.permissions = data
            }
        })

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened,
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {
                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete) {
        this._matAutocomplete = value;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {

        this._settingsService.getUserSettings()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe({
            next: (data:UserSettings)=> {
                this._userSettings = data
            }
        })
        // Subscribe to the search field value changes
        this.searchControl.valueChanges
        .pipe(
            debounceTime(this.debounce),
            takeUntil(this._unsubscribeAll),
            map((value) => {
                // Set the resultSets to null if there is no value or
                // the length of the value is smaller than the minLength
                // so the autocomplete panel can be closed
                if (!value || value.length < this.minLength) {
                    this.resultSets = null;
                }

                // Continue
                return value;
            }),
            // Filter out undefined/null/false statements and also
            // filter out the values that are smaller than minLength
            filter(value => value && value.length >= this.minLength),
        )
        .subscribe({
            next: (searchQuery)=> {
                this.browseResults(searchQuery.toLowerCase())
            }
        })
          
    }

    browseResults(searchQuery) {
        const results = { reservations: [], clients: [] }

        const filters = [
            (reservation:DbReservation) => statusFilter({ object: reservation, value: 1}),
            (reservation:DbReservation) => reservationClientFilter({reservation: reservation, value: searchQuery}),
        ]

        if(Utils.isDefined(this._userSettings,"settings.reservationBlockade.lockAsReservation.turnOn")) {
            if(Utils.getNestedValue(this._userSettings,"settings.reservationBlockade.lockAsReservation.turnOn") == '1')
            filters.push(
                (reservation:DbReservation) => reservation.clientId > 0,
            )
        }
       
        const paginatedReservations = this._reservationsService.browseReservations({
            filters: filters
        })

        // results.clients = paginatedClients.results;
        results.reservations = paginatedReservations.allResults.slice(0,30);
        results.reservations.forEach(reservation => {
            reservation.room = this._roomsService.getById(reservation.roomId)
        })

        this.resultSets = results
        this.search.next(results)
    }

    chooseOption(event:any) {
        if (Utils.isDefined(event, "option.value.obj")) {
    
          if (event.option.value.type == 'reservation') {
            this.panelService.openUpdateReservationPanel(event.option.value.obj)
          }
          else if (event.option.value.type == 'client') {
            this.panelService.showCreateOrUpdateClient('update', event.option.value.obj)
          }
    
        }
        this.searchControl.reset();
        this.resultSets.clients = [];
        this.resultSets.reservations = [];

        this.close()
      }
      
    private _getRoom(roomId): DbRoom {
        return this._roomsService.getById(roomId)
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Escape
        if (event.code === 'Escape') {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {

        if(!this.permissions.has('searchReservation')) {
           this.opened = false
           return; 
        } 
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}


