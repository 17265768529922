import { Injectable, OnDestroy, inject } from "@angular/core";
import { Source } from "../models/Source";
import { AppDB } from "../models/db";
import { DbSource } from "../models/DbSource";
import { BehaviorSubject, Observable, Subscription, from, of } from "rxjs";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { TranslocoService } from "@ngneat/transloco";
import { DbCurrency } from "../models/DbCurrency";

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService implements OnDestroy {

  private _subscriptions: Subscription[] = [];

  private _currencies = new BehaviorSubject<DbCurrency[]>([])
  public currentCurrencies: Observable<DbCurrency[]> = this._currencies.asObservable()

  private _db: AppDB

  constructor(
    private _dbService: DbService,
    private _dataSynchronizerService: DataSynchronizerService
  ) {

    this._subscriptions.push(
      this._dbService.getDatabase().subscribe({
        next: (data) => {
          this._db = data
        }
      })
    )

    // Add currencies on synchronize
    this._subscriptions.push(
      this._dataSynchronizerService.currentData.subscribe({
        next: (data) => {
          if (!data) return
          if (data.table != 'currencies') return

          data.value.forEach((currency: DbCurrency) => {
            const index = this._currencies.value.findIndex(x => x.currencyId === currency.currencyId)
            if (index >= 0) this._currencies.value[index] = currency
            else this._currencies.value.push(currency)
          });

          this._currencies.next(this._currencies.value)

          this._dataSynchronizerService.received()
        }
      }))
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe())
  }

  getAll(): Observable<DbCurrency[]> {
    this._currencies.next([])
    if(!this._db) return of([])
    
    return from(
      this._db.transaction("r", this._db.currencies, () => {
        return this._db.currencies.toArray();
      })
        .then((result: DbCurrency[]) => {

          this._currencies.next(result)
          return result;
        })
    );
  }

  getCurrencyById(currencyId): DbCurrency {
    const currency = this._currencies.value.find(x => x.currencyId == currencyId)
    if (currency) return currency
    else console.warn(`Currency with id: ${currencyId} not found.`)
  }
}
