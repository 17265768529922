import { Injectable, Inject } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(
    private _fuseConfigService:FuseConfigService
    ) 
    {
      
    }

  setThemeModeByValue(value) {
    if(value == 0) this._fuseConfigService.config = {scheme: 'light'}
    else if(value == 1) this._fuseConfigService.config = {scheme: 'dark'}
    else this._fuseConfigService.config = {scheme: 'auto'}
  }
 
}