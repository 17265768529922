import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { CalendarFilters } from '../models/CalendarFilters';

@Injectable({
  providedIn: 'root'
})
export class CalendarFiltersService {

  filters = new BehaviorSubject<CalendarFilters>({name: null, roomSizes: [''], roomTypes:['']});
  currentFilters = this.filters.asObservable();

  filtersCounter =new BehaviorSubject<number>(0);
  currentFiltersCounter = this.filtersCounter.asObservable();

  constructor() { }


  clearAll()
  {
    this.filters.next({name: null, roomSizes: [''], roomTypes: ['']})
    this.countFilters()
  }

  set(option: keyof(CalendarFilters),
    value:any)
  {
    this.filters.value[option.toString()] = value;
    this.filters.next(this.filters.value);

    this.countFilters()
    
  }

  countFilters()
  {
    let filtersCount = 0;
    Object.keys(this.filters.value).forEach((key:keyof CalendarFilters) => {
      if( this.filters.value[key] != '' && this.filters.value[key] != null)
      {
        filtersCount = filtersCount + 1
      }
    });
    this.filtersCounter.next(filtersCount);
  }

}

