import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  standalone:true,
  selector: '[phoneNumberDirective]'
})
export class PhoneNumberDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    let inputValue: string = this.el.nativeElement.value;

    this.el.nativeElement.value = inputValue.replace(/[^0-9+\-()]/g, '');

    if (inputValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}