import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, forkJoin, from, map, of, tap } from "rxjs";
import { DbEmployee } from "../models/DbEmployee";
import { AppDB } from "../models/db";
import { DbService } from "./db.service";
import { DataSynchronizerService } from "./data-synchronizer.service";
import { ApplicationData } from "../data/application-data";

@Injectable({
    providedIn: 'root'
})
export class EmployeesService implements OnDestroy {
 
    private _subscriptions: Subscription[] = [];
    private _employees = new BehaviorSubject<DbEmployee[]>([])
    public currentEmployees: Observable<DbEmployee[]> = this._employees.asObservable()

    private _db: AppDB

    constructor(
        private _dbService: DbService,
        private _dataSynchronizerService: DataSynchronizerService
    ) {
        this._subscriptions.push(
            this._dbService.getDatabase().subscribe({
                next: (data) => {
                    this._db = data
                    this._employees.next([])
                }
            })
        )

        // Subscribe to updates on tables
        this._subscriptions.push(
            this._dataSynchronizerService.currentData.subscribe({
                next: (data) => {
                    if (!data) return

                    if (data.table == 'employees') {

                        data.value.forEach((employee:DbEmployee )=> {
                            const position = ApplicationData.WorkerPositions.find(x=>x.id == employee.position)
                            if(position) employee.jobPosition = position

                            const index = this._employees.value.findIndex(x=>x.employeeId == employee.employeeId)
                            if(index == -1) this._employees.value.push(employee)
                            else this._employees.value[index] = employee
                        })

                        this._dataSynchronizerService.received()
                        this._employees.next(this._employees.value)
                    }
        }}))
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub=>sub.unsubscribe())
    }

    getById(employeeId): DbEmployee {
        const employee = this._employees.value.find(employee => employee.employeeId == employeeId)
        if (employee) return employee
        else console.warn(`Employee with id: ${employeeId} not found.`)
    }


    getAll(): Observable<boolean> {

        if(!this._db) return of(true)
        
        this._employees.next([])
        
        let startTime = 0
        const loader = from(this._db.transaction("r", this._db.employees, () => {
            startTime = performance.now();
            return this._db.employees.toArray();
        }))
        .pipe(
            map((employees: DbEmployee[]) => {

                const positions = ApplicationData.WorkerPositions
                employees.forEach((employee:DbEmployee)=> {
                    const position = positions.find(x=>x.id == employee.position)
                    if(position) employee.jobPosition = position
                })
                this._employees.next(employees)
                return true;
            }),
            tap(() => console.log(`[Employee Service]: Built in ${performance.now() - startTime}ms.`))
        )
        return loader


    }
}