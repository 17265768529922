<div>
    <!-- Rezerwacje / Blokady -->
    <div *ngIf="log?.objectName == 'Reservations'">
        <!-- Rezerwacja -->
        <span *ngIf="!isLock(log?.object) && log?.mode =='EDIT'">{{log?.objectId?.length > 0 ?
            ("edytowano_wiele_rezerwacji" | transloco) : ("edytowano_rezerwacje" | transloco)}} </span>
        <span *ngIf="!isLock(log?.object) && log?.mode =='ADD'">{{log?.objectId?.length > 0 ? ("dodano_wiele_rezerwacji"
            |
            transloco) : ("dodano_rezerwacje" | transloco)}} </span>
        <span *ngIf="!isLock(log?.object) && log?.mode =='RESTORE'">{{log?.objectId?.length > 0 ?
            ("przywrocono_wiele_rezerwacji" | transloco): ("przywrocono_rezerwacje" | transloco)}} </span>
        <span *ngIf="!isLock(log?.object) && log?.mode =='DELETE'">{{log?.objectId?.length > 0 ?
            ("usunieto_wiele_rezerwacji" | transloco) : ("usunieto_rezerwacje" | transloco)}} </span>

        <!-- Blokada -->
        <span *ngIf="isLock(log?.object) && log?.mode =='EDIT'">{{log?.objectId?.length > 0 ? ("edytowano_wiele_blokad" | transloco) : ("edytowano_blokade" | transloco)}} </span>
        <span *ngIf="isLock(log?.object) && log?.mode =='ADD'">{{log?.objectId?.length > 0 ? ("dodano_wiele_blokad" | transloco) : ("dodano_blokade" | transloco)}} </span>
        <span *ngIf="isLock(log?.object) && log?.mode =='RESTORE'">{{log?.objectId?.length > 0 ?  ("przywrocono_wiele_blokad" | transloco) : ("przywrocono_blokade" | transloco)}} </span>
        <span *ngIf="isLock(log?.object) && log?.mode =='DELETE'">{{log?.objectId?.length > 0 ? ("usunieto_wiele_blokad" | transloco) : ("usunieto_blokade" | transloco)}} </span>

        <ng-container *ngIf="log?.objectId?.length > 0; else reservationNo">
            <b>({{log?.objectId?.length}})</b>
        </ng-container>

        <ng-template #reservationNo>
            #{{log?.object?.reservationNo}}
        </ng-template>

    </div>

    <!-- Klienci -->
    <div *ngIf="log?.objectName == 'Clients'">
        <span *ngIf="log?.mode =='EDIT'">
            {{log?.objectId?.length > 0 ?
            ("edytowano_wielu_klientow" | transloco) :
            ("edytowano_klienta" | transloco)}}
        </span>
        <span *ngIf="log?.mode =='ADD'">{{log?.objectId?.length > 0 ? ("dodano_wielu_klientow" | transloco) :
            ("dodano_klienta" | transloco)}} </span>
        <span *ngIf="log?.mode =='RESTORE'">{{log?.objectId?.length > 0 ? ("przywrocono_wielu_klientow" | transloco) :
            ("przywrocono_klienta" | transloco)}} </span>
        <span *ngIf="log?.mode =='DELETE'">{{log?.objectId?.length > 0 ? ("usunieto_wielu_klientow" | transloco) :
            ("usunieto_klienta" | transloco)}} </span>

        <ng-container *ngIf="log?.objectId?.length > 0; else clientId">
            <b>({{log?.objectId?.length}})</b>
        </ng-container>
        <ng-template #clientId>
            #{{log?.objectId}}
        </ng-template>
    </div>

    <!-- Pomieszczenia -->
    <div *ngIf="log?.objectName == 'Rooms'">
        <span *ngIf="log?.mode =='EDIT'">{{log?.objectId?.length > 0 ? ("edytowano_wiele_pomieszczen" | transloco) :
            ("edytowano_pomieszczenie" | transloco)}} </span>
        <span *ngIf="log?.mode =='ADD'">{{log?.objectId?.length > 0 ? ("dodano_wiele_pomieszczen" | transloco) :
            ("dodano_pomieszczenie" | transloco)}} </span>
        <span *ngIf="log?.mode =='RESTORE'">{{log?.objectId?.length > 0 ? ("przywrocono_wiele_pomieszczen" | transloco)
            :
            ("przywrocono_pomieszczenie" | transloco)}} </span>
        <span *ngIf="log?.mode =='DELETE'">{{log?.objectId?.length > 0 ? ("usunieto_wiele_pomieszczen" | transloco) :
            ("usunieto_pomieszczenie" | transloco)}} </span>

        <ng-container *ngIf="log?.objectId?.length > 0; else roomId">
            <b>({{log?.objectId?.length}})</b>
        </ng-container>
        <ng-template #roomId>
            #{{log?.objectId}}
        </ng-template>
    </div>


    <span *ngIf="log?.employee != null" [class]="log?.employee?.status == '0' ? 'line-through' : ''"
        [matTooltip]="log?.employee?.status == '0' ? ('usuniety_pracownik' | transloco) : ('pracownik' | transloco)">
        ({{"przez" | transloco}}
        <b>{{log?.employee?.forename}} {{log?.employee?.name}}</b>
        {{log?.employee?.status == "0" ? log?.employee?.deletedEmail :
        log?.employee?.email}})
    </span>

    <span *ngIf="log?.source != null">
        ({{"przez" | transloco}}
        <b>{{log?.source?.name | transloco}}</b>)
    </span>


    <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
        <!-- Date -->
        <div class="text-secondary">
            <date-formatter [date]="log?.addDate" [showTime]="true"></date-formatter>
        </div>
        <!-- Linked content -->
        <!-- <div class="hidden sm:block">&bull;</div> -->
        <!-- Internal link -->
        <ng-container *ngIf="log?.objectId?.length > 0; else details">
            <ng-container *ngIf="log?.objectId?.length > (expandableLimit+1)">
                <div class="hidden sm:block">&bull;</div>
                <a (click)="isExpanded = !isExpanded" class="cursor-pointer text-primary">
                    {{ isExpanded ? ('zwin' | transloco) : ('rozwin' | transloco)}}
                </a>
            </ng-container>
        </ng-container>

        <ng-template #details>
            <div class="hidden sm:block">&bull;</div>
            <a (click)="showReservation(log?.object)" *ngIf="log?.objectName == 'Reservations'"
                class="cursor-pointer text-primary">
                {{'szczegoly' | transloco}}
            </a>

            <a (click)="showClient(log?.object)" *ngIf="log?.objectName == 'Clients'"
                class="cursor-pointer text-primary">
                {{'szczegoly' | transloco}}
            </a>

            <a (click)="showRoom(log?.object)" *ngIf="log?.objectName == 'Rooms'" class="cursor-pointer text-primary">
                {{'szczegoly' | transloco}}
            </a>

        </ng-template>
    </div>

    <ng-container *ngIf="log?.objectId?.length > 0">
        <div class="block mt-4 py-4 px-5 rounded-lg bg-gray-200 dark:bg-gray-800 leading-10">
            <ng-container *ngFor="let item of log?.object; let i = index">
                <ng-container *ngIf="(i <= expandableLimit) || (i > expandableLimit && isExpanded) ">
                    <span>
                        <span *ngIf="log?.objectName == 'Reservations' ">
                            {{isLock(item) ? ('blokada' | transloco ) : ('rezerwacja' | transloco )}}
                            <a (click)="showReservation(item)" class="cursor-pointer text-primary font-medium">
                                #{{item.reservationNo}}<ng-container *ngIf="log?.objectId.length-1 != i">,
                                </ng-container>
                            </a>
                        </span>

                        <span *ngIf="log?.objectName == 'Clients' ">
                            {{('klient' | transloco )}}
                            <a (click)="showClient(item)" class="cursor-pointer text-primary font-medium">
                                #{{item.clientId}}<ng-container *ngIf="log?.objectId.length-1 != i">, </ng-container>
                            </a>
                        </span>

                        <span *ngIf="log?.objectName == 'Rooms' ">
                            {{('pomieszczenie' | transloco )}}
                            <a (click)="showClient(item)" class="cursor-pointer text-primary font-medium">
                                #{{item.roomId}}<ng-container *ngIf="log?.objectId.length-1 != i">, </ng-container>
                            </a>
                        </span>

                        <a class="cursor-pointer font-medium hover:underline lowercase"
                            *ngIf="!isExpanded && i == expandableLimit && i != log?.objectId.length-1"
                            (click)="isExpanded = !isExpanded">{{'wiecej' | transloco}}...</a>
                    </span>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>