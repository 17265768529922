export class PaginationUtils {
    public static getPageRecords({ currentPage, defaultPageSize, allResults }) {

        let fromIndex = (currentPage * defaultPageSize) - defaultPageSize;
        let toIndex = fromIndex + (defaultPageSize - 1);

        if (toIndex > allResults.length - 1) {
            toIndex = allResults.length - 1;
        }

        return this._getObjectsInRange(allResults, fromIndex, toIndex)
    }

    private static _getObjectsInRange(arr, min, max) {
        if (max < 0) max = 0
        // Sprawdzamy, czy indeksy min i max są poprawne i nie wykraczają poza granice tablicy
        if (min < 0 || min > max) {
            return []; // Zwracamy null, jeśli indeksy są niepoprawne
        }

        if (max > arr.length - 1) { max = arr.length - 1 }

        const result = [];
        // Iterujemy po tablicy i dodajemy obiekty, których indeks znajduje się w zakresie min i max
        for (let i = min; i <= max; i++) {
            result.push(arr[i]);
        }

        return result;
    }
}