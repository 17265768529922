import Dexie, { Table } from 'dexie';
import { DbAdditionalService } from './DbAdditionalService';
import { DbClient } from './DbClient';
import { DbCurrency } from './DbCurrency';
import { DbEmployee } from './DbEmployee';
import { DbLogs } from './DbLogs';
import { DbInvoice } from './DbInvoice';
import { DbInvoiceCorrection } from './DbInvoiceCorrection';
import { DbInvoiceItem } from './DbInvoiceItem';
import { DbInvoicePrepayment } from './DbInvoicePrepayment';
import { DbMessage } from './DbMessage';
import { DbPricing } from './DbPricing';
import { DbPricingModel } from './DbPricingModel';
import { DbRate } from './DbRate';
import { DbReservation } from './DbReservation';
import { DbRoom } from './DbRoom';
import { DbSynchronization } from './DbSynchronization';
import { DbUserSettings } from './DbUserSettings';
import { DbSource } from './DbSource';
import { DbTemplate } from './DbTemplate';
import { DbNotification } from './DbNotification';
import { DbMeal } from './DbMeal';

export class AppDB extends Dexie {
  rooms!: Table<DbRoom, number>;
  clients!: Table<DbClient, number>;
  reservations!: Table<DbReservation, number>;
  employees!: Table<DbEmployee, number>;
  logs!: Table<DbLogs, number>;
  messages!: Table<DbMessage, number>;
  currencies!: Table<DbCurrency, number>;
  additionalServices!: Table<DbAdditionalService, number>;
  synchronizations!: Table<DbSynchronization, number>;
  userSettings!: Table<DbUserSettings, number>;
  invoices!: Table<DbInvoice, number>;
  invoicesCorrection!: Table<DbInvoiceCorrection, number>;
  invoicesPrepayment!: Table<DbInvoicePrepayment, number>;
  invoicesItems!: Table<DbInvoiceItem, number>;
  prices!: Table<DbPricing, number>;
  pricesBooking!: Table<DbPricing, number>;
  pricingModel!: Table<DbPricingModel, number>;
  rates!: Table<DbRate, number>;
  sources!: Table<DbSource, number>;
  templates!: Table<DbTemplate, number>;
  notifications!: Table<DbNotification, number>;
  meals!: Table<DbMeal, number>;

  constructor(userId: number, employeeId: number) {

    let newUserId = `${userId}`
    if(employeeId > 0) newUserId += '-'+employeeId
    if (userId == null) { console.error("User id is null while building database."); }
    
    super(`${newUserId}@mobile-calendar.com`);

    this.version(30).stores({
      rooms: 'roomId, order, status',
      clients: 'clientId, status',
      reservations: 'reservationId, roomId, clientId, hasPermission, arrival, departure, status, reservationNo',
      employees: 'employeeId, userId, email',
      messages: 'messageId, userId, reservationId',
      logs: 'logId, objectId, userId, objectName, addDate',
      additionalServices: 'additionalServiceId, userId, status',
      currencies: 'currencyId',
      synchronizations: '++id, lastSync, version',
      userSettings: '++id, userId',

      invoices: 'invoiceId, userId, reservationId, status',
      invoicesCorrection: 'id, invoiceId, correctionInvoiceId, status',
      invoicesPrepayment: 'id, invoiceId, prepaymentInvoiceId, status',
      invoicesItems: 'positionId, invoiceId, userId, status',

      prices: 'pricingId, roomId, userId, rateId, status',
      pricesBooking: 'pricingId, roomId, userId, rateId, status',
      pricingModel: 'pricingModelId, userId, roomId, rateId, status',
      rates: 'rateId, userId, status',
      sources: 'sourceId, userId, status',
      templates: 'templateId',
      notifications: 'notificationId, status',
      meals: 'userId',
    });

    this.on('populate', () => {
      // this.populate()
    });
  }
}