import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { AuthService } from 'app/core/auth/auth.service';
import { PermissionsService } from 'app/core/shared/services/permissions.service';
import { environment } from 'environments/environment';
import { of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const localize = inject(LocalizeRouterService);
    const _permissionService = inject(PermissionsService);
    const _authService = inject(AuthService);
    const userPermissions = _permissionService.permissions.value;

    // Check the authentication status
    return inject(AuthService).isLoggedIn$.pipe(
        switchMap((authenticated) =>
        {
            // console.log('[AuthGuard]: Checking route', route); // Log the route information
            // If the user is not authenticated...
            if (!authenticated )
            {

                // Redirect to the sign-in
                const jtwIsDefined = localStorage.getItem(environment.ACCESS_TOKEN)
                let path =  localize.translateRoute('/sign-in')
                if(jtwIsDefined) {
                    path = localize.translateRoute('/sign-out');
                }
                console.log('[AuthGuard]: Not logged in. Redirecting...')
                router.navigate([path]);

                return of(false);
            }

            if(!_authService.isLoggedIn()) {
                console.log('[AuthGuard]: Not logged in. Redirecting...')
                const path = localize.translateRoute('/sign-in/');
                router.navigate([path]);
                return of(false);
            }

            // If route doesn't have restrictions pass through
            if (typeof route.data.permissions === 'undefined') return of(true);

            // else verify permissions
            for (let i = 0; i < route.data.permissions.length; i++) {
                const permission = route.data.permissions[i];
                // Can not access
                if (!userPermissions.has(permission)) {
                    console.warn(`[PermissionGuard]: No permission "${permission}" to access that view. Redirecting...`);
                    const path = localize.translateRoute('/app/calendar/');
                    router.navigate([path]);
                    return of(false);
                }
            }

            // Allow the access
            return of(true);
        }),
    );
};
