<ng-container *ngIf="loadedData == true; else loading">
    <ng-container *ngIf="grouped.length > 0; else noActivity">
        <div>
            <ol>
                <ng-container *ngFor="let dateWithChanges of grouped; let i = index;">
                    <!-- Date separator -->
                    <ng-container>
                        <li class="relative flex py-7">
                            <div *ngIf="dateWithChanges.isToday"
                                class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                                {{'dzisiaj' | transloco}}
                            </div>
                            <div *ngIf="!dateWithChanges.isToday"
                                class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                                <date-formatter [date]="dateWithChanges?.dateString"
                                    [showTime]="false"></date-formatter>
                            </div>
                        </li>
                    </ng-container>

                    <ng-container *ngFor="let log of dateWithChanges.histories; let first = first; let last = last;">

                        <!-- Activity -->
                        <li class="relative flex py-7">
                            <!-- Line -->
                            <ng-container *ngIf="!last && isSameDay(dateWithChanges.dateString, log?.addDate)">
                                <div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600">
                                </div>
                            </ng-container>
                            <div class="relative flex flex-auto">
                                <!-- Icon -->
                                <ng-container *ngIf="log?.employee == null && log?.source == null">
                                    <!-- *ngIf="activity.icon && !activity.image"-->
                                    <div
                                        class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
                                        <mat-icon *ngIf="log?.mode =='EDIT'" class="icon-size-5 text-white"
                                            svgIcon="heroicons_solid:pencil-square"></mat-icon>
                                        <mat-icon *ngIf="log?.mode =='ADD'" class="icon-size-5 text-white"
                                            svgIcon="heroicons_solid:plus"></mat-icon>
                                        <mat-icon *ngIf="log?.mode =='DELETE'" class="icon-size-5 text-white"
                                            svgIcon="heroicons_solid:trash"></mat-icon>
                                        <mat-icon *ngIf="log?.mode =='RESTORE'" class="con-size-5 text-white"
                                            svgIcon="heroicons_solid:arrow-path"></mat-icon>
                                    </div>
                                </ng-container>

                                <!-- Image -->
                                <ng-container *ngIf="log?.employee != null && log?.source == null">
                                    <user-avatar-image [user]="log?.employee"
                                        [customClass]="'shrink-0 w-10 h-10 mr-4 rounded-full overflow-hidden object-cover object-center'"></user-avatar-image>
                                </ng-container>

                                <ng-container *ngIf="log?.source != null">
                                    <img class="shrink-0 w-10 h-10 mr-4 rounded-full overflow-hidden object-cover object-center"
                                        [src]="'assets/images/sources/'+log?.source?.logo+'.png'" [alt]="'Source image'">
                                </ng-container>

                                <!-- Content -->
                                <div class="flex flex-col flex-auto items-start">
                                    <log-action-name [log]="log"></log-action-name>

                                    <!-- Extra content -->
                                    <ng-container *ngIf="log?.changes?.length > 0">
                                        <div class="mt-4 py-4 px-5 rounded-lg bg-gray-200 dark:bg-gray-800 leading-10">
                                            <ol>
                                                <ng-container *ngFor="let c of log?.changes">
                                                    <!-- <li>{{c | json }}</li> -->
                                                    <li class="font-medium" *ngIf="c.action === 'updated'">
                                                        {{"zmieniono" | transloco}} <history-key-to-text
                                                            [color]="'updated'" [key]="c.key">
                                                        </history-key-to-text> 
                                                        {{"z" | transloco}}
                                                        <b>{{c.previous}}</b> 
                                                        <div *ngIf="c.key == 'color'" class="w-4 h-4 inline-block mx-2 rounded-xl" [style.background-color]="c.previous"></div> 
                                                        {{"na" | transloco}} 
                                                        <b>{{c.current}}</b>
                                                        <div *ngIf="c.key == 'color'" class="w-4 h-4 inline-block mx-2 rounded-xl" [style.background-color]="c.current"></div>
                                                    </li>
                                                    <li class="font-medium" *ngIf="c.action === 'added'">
                                                        {{"dodano" | transloco}} <history-key-to-text [color]="'added'"
                                                            [key]="c.key">
                                                        </history-key-to-text>
                                                        <b class="ml-1">{{c.current}}</b>
                                                        <div *ngIf="c.key == 'color'" class="w-4 h-4 inline-block mx-2 rounded-xl" [style.background-color]="c.previous"></div> 

                                                    </li>
                                                    <li class="font-medium" *ngIf="c.action === 'deleted'">
                                                        {{"usunieto" | transloco}} <history-key-to-text
                                                            [color]="'deleted'" [key]="c.key">
                                                        </history-key-to-text>
                                                        <b class="ml-1">{{c.previous}}</b>
                                                    </li>

                                                    <li class="font-medium" *ngIf="c.action === 'moved'">
                                                        {{"przeniesiono" | transloco}} {{"z" | transloco}}
                                                        <b>{{c.previous}}</b> {{"na" | transloco}} <b>{{c.current}}</b>
                                                    </li>

                                                    <li class="font-medium" *ngIf="c.action === 'updated_nested'">
                                                        {{"zmieniono" | transloco}} 
                                                        <history-key-to-text
                                                            [color]="'updated'" [key]="c.key">
                                                        </history-key-to-text>
                                                        <history-key-to-text  class="ml-1" [color]="'updated'" [lowercase]="false"
                                                            [key]="c.name" [type]="c.type" [hasFlag]="c?.hasFlag" [flag]="c?.flag">
                                                        </history-key-to-text> {{"z" | transloco}}
                                                        <b>{{c.previous}}</b> {{"na" | transloco}} <b>{{c.current}}</b>
                                                    </li>

                                                    <li class="font-medium" *ngIf="c.action === 'added_nested'">
                                                        {{"dodano" | transloco}} 
                                                        <history-key-to-text [color]="'added'"
                                                            [key]="c.key">
                                                        </history-key-to-text>
                                                        <history-key-to-text  class="ml-1" [color]="'added'" [lowercase]="false"
                                                            [type]="c.type" [key]="c.name" [hasFlag]="c?.hasFlag" [flag]="c?.flag">
                                                        </history-key-to-text> <b>{{c.current}}</b>
                                                    </li>

                                                </ng-container>
                                            </ol>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="currentPage != allPages && i == grouped?.length-1">
                        <li class="relative flex py-7">
                            <div (click)="nextPage()"
                                class="cursor-pointer relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                                {{"wiecej" | transloco}} {{"strona" | transloco}}{{": "+currentPage +" z " +allPages}}
                            </div>
                        </li>
                    </ng-container>
                </ng-container>
            </ol>
        </div>
    </ng-container>
</ng-container>

<!-- Loading template -->
<ng-template #loading>
    <div class="mt-8">
        {{'ladowanie' | transloco}}...
    </div>
</ng-template>
<!-- No Activity template -->
<ng-template #noActivity>
    <div class="mt-8">
        {{'nie_znaleziono_wynikow' | transloco}}
    </div>
</ng-template>