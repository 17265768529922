import { Utils } from "../others/utils";
import { AdditionalServicesService } from "../services/additional-services.service";
import { DbReservation } from "./DbReservation";

export class ReservationPricingSummary {

    defaultCurrency = '';
    noPaymentText = '';

    pricePerRoom:number = 0;
    foodPrice:number = 0;
    additionalServices:number = 0;
    summary:number = 0;

    advancePayment = 0;

    discount:number = 0;
    discountInFiat:number = 0;
    discountType:number = 0;
    isDiscountEnabled = false;

    paid = 0;
    toPay = 0;
    reservationStatus = 0;

    alerts = [];
    additionalServicesList:any = [];
    private _additionalServicesService:AdditionalServicesService
    constructor(
        currency,
        additionalServicesService:AdditionalServicesService, 
        noPaymentText = ''
        )
    {
        this.defaultCurrency = currency;
        this.noPaymentText = noPaymentText;
        this._additionalServicesService = additionalServicesService
    } 


    fromReservation(reservation: DbReservation)
    {
      
        this.setReservationStatus(reservation.paymentStatus)
     
        if(Utils.isDefined(reservation,"additionalServices"))
        {
            this.setAdditionalServices(reservation.additionalServices)
        }

        if(Utils.isDefined(reservation,"discount") && reservation.discount > 0)
        {
            if(!Utils.isNullOrEmpty(reservation.discount)) {
                this.setDiscount(true,reservation.discount,reservation.discountType)
            }
        }

        if(Utils.isDefined(reservation,"pricePerRoom") && reservation.pricePerRoom > 0)
        {
            if(reservation.pricePerRoom > 0) {
                
                this.setPricePerRoom(reservation.pricePerRoom)
            }
        }

        if(Utils.isDefined(reservation,"pricePerMeal") && reservation.pricePerMeal > 0)
        {
            if(reservation.pricePerMeal > 0) {
                this.setFoodPrice(reservation.pricePerMeal)
            }
        }
        
        if(reservation.prepayment > 0) this.setAdvancePayment(reservation.prepayment)
        
        
    }

    setReservationStatus(status:any)
    {
        this.reservationStatus = status;
        this.calculaceDiscount();
        this.countPaid();
        this.countSummary();
    }


    // Create additional services array from object propeties as id
    setAdditionalServices(array:IAdditionalService[])
    {
        this.additionalServicesList = [];
        array.forEach(s=> {
            let service:any = this._additionalServicesService.getById(s.additionalServiceId)


            if(service == null) { 
                console.warn("[ReservationPricingSummary]: Additional service not found:", s.additionalServiceId);
                // Service is not found in database
                service = {
                    displayName: 'deleted',
                    taxRate: 23
                }
            }
          
            this.additionalServicesList.push({
                additionalServiceId: s.additionalServiceId,
                displayName: service.displayName,
                price: s.price,
                status: s.status,
                taxRate: service.taxRate
            })
        })
        
       this.updateCalculations()
    }

    updateCalculations() {

        let sum = 0;
        this.additionalServicesList.forEach(service => {
            sum += parseFloat(service.price);
        });
        this.additionalServices = parseFloat((sum * 1).toFixed(2));

        this.calculaceDiscount();
        this.countPaid();
        this.countSummary();
    }

    setAdvancePayment(advancePayment:number)
    {
        this.advancePayment = advancePayment;
        this.calculaceDiscount();
        this.countPaid();
        this.countSummary();
    }

    setFoodPrice(foodPrice:number)
    {
        this.foodPrice = parseFloat((foodPrice * 1).toFixed(2));
        this.calculaceDiscount();
        this.countPaid();
        this.countSummary();
    }

    setPricePerRoom(pricePerRoom:number)
    {
        if(pricePerRoom == 0) this.pricePerRoom = 0;
        else this.pricePerRoom = parseFloat((pricePerRoom * 1).toFixed(2));
        this.calculaceDiscount();
        this.countPaid();
        this.countSummary();
    }

    setDiscount(isDiscountEnabled:boolean, discount:number, discountType:number)
    {
        this.isDiscountEnabled = isDiscountEnabled;
        this.discount = discount;
        this.discountType = discountType;
        this.calculaceDiscount();
    }

    // Aktualizacja zapłacono
    countPaid()
    {
        this.alerts = [];

        this.paid = 0;
        this.additionalServicesList.forEach(service => {

        if(service.status == 1)
        {
            let servicePrice = parseFloat(service.price);
            this.paid += servicePrice;
        }
        else {
            if(this.reservationStatus == 2)
            {
                this.alerts.push(`${service.name}: ${service.price}${this.defaultCurrency} (${this.noPaymentText})`)
            }
        }
        });

        if(this.reservationStatus == 1)
        {
            this.paid = this.paid + parseFloat((this.advancePayment * 1).toFixed(2));
        }

        else if(this.reservationStatus == 2)
        {
            this.paid = this.paid + parseFloat((this.pricePerRoom + this.foodPrice - this.discountInFiat).toFixed(2));
        }

    }

    countSummary()
    {
        this.summary = parseFloat((this.pricePerRoom + this.foodPrice + this.additionalServices - this.discountInFiat).toFixed(2));
        this.toPay = this.summary - this.paid
    }

    getSummary()
    {
        return parseFloat((this.pricePerRoom + this.foodPrice + this.additionalServices).toFixed(2));
    }
    

    // Podczas zmiany rabaty
    calculaceDiscount()
    {
        if(!this.isDiscountEnabled || this.discount == 0)
        {
            this.discountInFiat = 0;
            this.countSummary();
            return;
        }

        // fiat discount
        if(this.discountType == 1)
        {
            this.discountInFiat = this.discount;
        }
        
        // percentage discount
        else if(this.discountType == 0)
        {
            if(this.discount > 100) this.discount = 100
            this.discountInFiat = parseFloat(((this.discount / 100) * this.getSummary()).toFixed(2)); 
        }   
        
        this.countSummary();

        this.countPaid();
    }
}

export interface IAdditionalService {
    additionalServiceId: number
    displayName?: string
    price: number
    status: number
    taxRate: string | number 
}