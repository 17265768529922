import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "app/core/auth/auth.service";
import { Observable, catchError, switchMap, take, finalize, throwError } from "rxjs";
import { Utils } from "../others/utils";
import { Router } from "@angular/router";
import { LocalizeRouterService } from "@penleychan/ngx-transloco-router";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private _isRefreshing = false;

  constructor(
    private _authService: AuthService,
    private _router:Router, 
    private _localize:LocalizeRouterService
  ) 
  {
    
  }

  // Intercept request
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(!request) return next.handle(request)
    if(request instanceof(HttpErrorResponse)) return throwError(() => request);
    // Do not proceed when logout
    if(request.url.includes('logout')) return next.handle(request)


    return next.handle(request).pipe(
      catchError((error) => {
        
        // return throwError(() => error)
        // Must include `TOKEN_EXPIRED` status
        const errorMessage = Utils.getNestedValue(error,"error.message")
        if(errorMessage != 'TOKEN_EXPIRED' )  {
          console.log(`[RefreshTokenInterceptor]: ErrorMessage is not 'TOKEN_EXPIRED' provided value: ${errorMessage}.`)
          return throwError(() => error);
        }

        // Avoid when logging in
        if(request.url.includes('auth/login')) {
          console.log(`[RefreshTokenInterceptor]: Invalid path found: 'auth/login'.`)
          return throwError(() => error);
        }
        
        // Avoid when getting refresh token
        else if(request.url.includes('token/refresh')) {
          console.log(`[RefreshTokenInterceptor]: Invalid path found: 'token/refresh'.`)

          this._redirectToLogout()
          return throwError(() => error);
        }

        // Must be 401 status
        else if(error.status !== 401) {
          console.log(`[RefreshTokenInterceptor]: Invalid status code: ${error.status} not 401.`)
          return throwError(() => error);
        }

        
        
        return this._handleError(request, error, next);


      })
    );
  }
  
  private _handleError(request: HttpRequest<any>, error,  next: HttpHandler) {


    if (this._isRefreshing){
      return throwError(() => error);
    }
     
    // Toggle is refreshing 
    this._isRefreshing = true;

    if (!this._authService.isLoggedIn()) {
      return throwError(() => error);
    }

    // Get new access and refresh token
    return this._authService.getRefreshToken().pipe(
      switchMap(() => {
        this._isRefreshing = false;
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this._authService.accessToken}`,
          },
        })
        this._isRefreshing = false
        return next.handle(request);
      }),
      catchError((error) => {
        this._isRefreshing = false
        this._redirectToLogout()
        return throwError(() => error);
      })
    );

  }

  private _redirectToLogout() {
    console.log(`[RefreshTokenInterceptor]: Redirecting to sign out page.`)
    localStorage.clear()
    this._authService._isLoggedIn$.next(false)
    const path = this._localize.translateRoute('/sign-out')
    this._router.navigate([path])
  }

}

export const RefreshTokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: RefreshTokenInterceptor,
  multi: true
}
