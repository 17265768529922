import { Injectable, inject } from "@angular/core";
import { QuickChatService } from "app/layout/common/quick-chat/quick-chat.service";
import { ShortcutsService } from "app/layout/common/shortcuts/shortcuts.service";
import { AdditionalServicesService } from "./additional-services.service";
import { ClientsService } from "./clients.service";
import { CurrenciesService } from "./currencies.service";
import { EmployeesService } from "./employees.service";
import { ReservationsService } from "./reservations.service";
import { RoomsService } from "./rooms.service";
import { SourcesService } from "./sources.service";
import { firstValueFrom, forkJoin, of } from "rxjs";
import { InvoicesService } from "./invoices.service";
import { TemplatesService } from "./templates.service";
import { MessagesService } from "./messages.service";
import { NotificationsService } from "./notifications.service";
import { MealsService } from "./meals.service";
import { PricingService } from "./pricing.service";

@Injectable({
    providedIn: 'root'
})
export class DataLoaderService {

    // Start
    employeesService = inject(EmployeesService);
    templatesService = inject(TemplatesService);
    mealsService = inject(MealsService);

    // First step
    sourceService = inject(SourcesService);
    currenciesService = inject(CurrenciesService);
    additionalServicesService = inject(AdditionalServicesService);

    roomsService = inject(RoomsService);
    reservationsService = inject(ReservationsService);
    clientsService = inject(ClientsService);

    // Second step
    invoicesService = inject(InvoicesService)
    messagesService = inject(MessagesService);
    notificationsService = inject(NotificationsService);

    pricingService = inject(PricingService);


    getStrategy() { return this.loadServices( this._createServices()) }

    private _createServices() {
        return [
            {
                services: [
                    this.mealsService.getAll(),
                    this.employeesService.getAll(),
                    this.templatesService.getAll()
                ]
            },
            {
                services: [
                    this.sourceService.getAll(),
                    this.currenciesService.getAll(),
                    this.additionalServicesService.getAll(),
                    this.roomsService.getAll(),
                ]
            },
            {
                services: [
                    this.clientsService.getAll()
                ]
            },
            {
                services: [
                    this.reservationsService.getAll()
                ]
            },
            {
                services: [
                    this.invoicesService.getAll(),
                    this.messagesService.getAll(),
                    this.notificationsService.getAll(),
                    this.pricingService.getAll()
                ]
            }
        ];
    }

    private loadServices(loaderArray: any[], currentIndex: number = 0): Promise<any[]> {
        if (currentIndex === loaderArray.length) {
            return Promise.resolve([]);
        }
        const currentLoader = loaderArray[currentIndex];
        const services = currentLoader.services
        return firstValueFrom(forkJoin(services)).then(() => this.loadServices(loaderArray, currentIndex + 1));
    }
}