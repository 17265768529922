export class Countries {

    public static List = [
        {
            id: 1,
            name: "Afghanistan",
            pl: "Afganistan",
            countryCode: "AF",
            isEU: false
        },
        {
            id: 2,
            name: "Åland Islands",
            pl: "Wyspy Alandzkie",
            countryCode: "AX",
            isEU: false
        },
        {
            id: 3,
            name: "Albania",
            order: 2,
            countryCode: "AL",
            isEU: false
        },
        {
            id: 4,
            name: "Algeria",
            countryCode: "DZ",
            isEU: false
        },
        {
            id: 5,
            name: "American Samoa",
            countryCode: "AS",
            isEU: false
        },
        {
            id: 6,
            name: "Andorra",
            countryCode: "AD",
            isEU: false
        },
        {
            id: 7,
            name: "Angola",
            countryCode: "AO",
            isEU: false
        },
        {
            id: 8,
            name: "Anguilla",
            countryCode: "AI",
            isEU: false
        },
        {
            id: 9,
            name: "Antarctica",
            countryCode: "AQ",
            isEU: false
        },
        {
            id: 10,
            name: "Antigua and Barbuda",
            countryCode: "AG",
            isEU: false
        },
        {
            id: 11,
            name: "Argentina",
            order: 4,
            countryCode: "AR",
            isEU: false
        },
        {
            id: 12,
            name: "Armenia",
            countryCode: "AM",
            isEU: false
        },
        {
            id: 13,
            name: "Aruba",
            countryCode: "AW",
            isEU: false
        },
        {
            id: 14,
            name: "Australia",
            order: 3,
            countryCode: "AU",
            isEU: false
        },
        {
            id: 15,
            name: "Austria",
            order: 2,
            countryCode: "AT",
            isEU: true
        },
        {
            id: 16,
            name: "Azerbaijan",
            countryCode: "AZ",
            isEU: false
        },
        {
            id: 17,
            name: "Bahamas",
            countryCode: "BS",
            isEU: false
        },
        {
            id: 18,
            name: "Bahrain",
            countryCode: "BH",
            isEU: false
        },
        {
            id: 19,
            name: "Bangladesh",
            countryCode: "BD",
            isEU: false
        },
        {
            id: 20,
            name: "Barbados",
            countryCode: "BB",
            isEU: false
        },
        {
            id: 21,
            name: "Belarus",
            countryCode: "BY",
            isEU: false
        },
        {
            id: 22,
            name: "Belgium",
            order: 3,
            countryCode: "BE",
            isEU: true
        },
        {
            id: 23,
            name: "Belize",
            countryCode: "BZ",
            isEU: false
        },
        {
            id: 24,
            name: "Benin",
            countryCode: "BJ",
            isEU: false
        },
        {
            id: 25,
            name: "Bermuda",
            countryCode: "BM",
            isEU: false
        },
        {
            id: 26,
            name: "Bhutan",
            countryCode: "BT",
            isEU: false
        },
        {
            id: 27,
            name: "Bolivia, Plurinational State of",
            countryCode: "BO",
            isEU: false
        },
        {
            id: 28,
            name: "Bonaire, Sint Eustatius and Saba",
            countryCode: "BQ",
            isEU: false
        },
        {
            id: 29,
            name: "Bosnia and Herzegovina",
            countryCode: "BA",
            isEU: false
        },
        {
            id: 30,
            name: "Botswana",
            countryCode: "BW",
            isEU: false
        },
        {
            id: 31,
            name: "Bouvet Island",
            countryCode: "BV",
            isEU: false
        },
        {
            id: 32,
            name: "Brazil",
            order: 4,
            countryCode: "BR",
            isEU: false
        },
        {
            id: 33,
            name: "British Indian Ocean Territory",
            countryCode: "IO",
            isEU: false
        },
        {
            id: 34,
            name: "Brunei Darussalam",
            countryCode: "BN",
            isEU: false
        },
        {
            id: 35,
            name: "Bulgaria",
            order: 3,
            countryCode: "BG",
            isEU: true
        },
        {
            id: 36,
            name: "Burkina Faso",
            countryCode: "BF",
            isEU: false
        },
        {
            id: 37,
            name: "Burundi",
            countryCode: "BI",
            isEU: false
        },
        {
            id: 38,
            name: "Cambodia",
            countryCode: "KH",
            isEU: false
        },
        {
            id: 39,
            name: "Cameroon",
            countryCode: "CM",
            isEU: false
        },
        {
            id: 40,
            name: "Canada",
            order: 3,
            countryCode: "CA",
            isEU: false
        },
        {
            id: 41,
            name: "Cape Verde",
            countryCode: "CV",
            isEU: false
        },
        {
            id: 42,
            name: "Cayman Islands",
            countryCode: "KY",
            isEU: false
        },
        {
            id: 43,
            name: "Central African Republic",
            countryCode: "CF",
            isEU: false
        },
        {
            id: 44,
            name: "Chad",
            countryCode: "TD",
            isEU: false
        },
        {
            id: 45,
            name: "Chile",
            order: 4,
            countryCode: "CL",
            isEU: false
        },
        {
            id: 46,
            name: "China",
            order: 3,
            countryCode: "CN",
            isEU: false
        },
        {
            id: 47,
            name: "Christmas Island",
            countryCode: "CX",
            isEU: false
        },
        {
            id: 48,
            name: "Cocos (Keeling) Islands",
            countryCode: "CC",
            isEU: false
        },
        {
            id: 49,
            name: "Colombia",
            order: 4,
            countryCode: "CO",
            isEU: false
        },
        {
            id: 50,
            name: "Comoros",
            countryCode: "KM",
            isEU: false
        },
        {
            id: 51,
            name: "Congo",
            countryCode: "CG",
            isEU: false
        },
        {
            id: 52,
            name: "Congo, the Democratic Republic of the",
            countryCode: "CD",
            isEU: false
        },
        {
            id: 53,
            name: "Cook Islands",
            countryCode: "CK",
            isEU: false
        },
        {
            id: 54,
            name: "Costa Rica",
            countryCode: "CR",
            isEU: false
        },
        {
            id: 55,
            name: "Côte d'Ivoire",
            countryCode: "CI",
            isEU: false
        },
        {
            id: 56,
            name: "Croatia",
            order: 2,
            countryCode: "HR",
            isEU: true
        },
        {
            id: 57,
            name: "Cuba",
            countryCode: "CU",
            isEU: false
        },
        {
            id: 58,
            name: "Curaçao",
            countryCode: "CW",
            isEU: false
        },
        {
            id: 59,
            name: "Cyprus",
            order: 3,
            countryCode: "CY",
            isEU: true
        },
        {
            id: 60,
            name: "Czech Republic",
            order: 2,
            countryCode: "CZ",
            isEU: true
        },
        {
            id: 61,
            name: "Denmark",
            order: 2,
            countryCode: "DK",
            isEU: true
        },
        {
            id: 62,
            name: "Djibouti",
            countryCode: "DJ",
            isEU: false
        },
        {
            id: 63,
            name: "Dominica",
            countryCode: "DM",
            isEU: false
        },
        {
            id: 64,
            name: "Dominican Republic",
            order: 2,
            countryCode: "DO",
            isEU: false
        },
        {
            id: 65,
            name: "Ecuador",
            countryCode: "EC",
            isEU: false
        },
        {
            id: 66,
            name: "Egypt",
            order: 3,
            countryCode: "EG",
            isEU: false
        },
        {
            id: 67,
            name: "El Salvador",
            order: 4,
            countryCode: "SV",
            isEU: false
        },
        {
            id: 68,
            name: "Equatorial Guinea",
            countryCode: "GQ",
            isEU: false
        },
        {
            id: 69,
            name: "Eritrea",
            countryCode: "ER",
            isEU: false
        },
        {
            id: 70,
            name: "Estonia",
            order: 4,
            countryCode: "EE",
            isEU: true
        },
        {
            id: 71,
            name: "Ethiopia",
            countryCode: "ET",
            isEU: false
        },
        {
            id: 72,
            name: "Falkland Islands (Malvinas)",
            countryCode: "FK",
            isEU: false
        },
        {
            id: 73,
            name: "Faroe Islands",
            countryCode: "FO",
            isEU: false
        },
        {
            id: 74,
            name: "Fiji",
            countryCode: "FJ",
            isEU: false
        },
        {
            id: 75,
            name: "Finland",
            order: 3,
            countryCode: "FI",
            isEU: true
        },
        {
            id: 76,
            name: "France",
            order: 3,
            countryCode: "FR",
            isEU: true
        },
        {
            id: 77,
            name: "French Guiana",
            countryCode: "GF",
            isEU: false
        },
        {
            id: 78,
            name: "French Polynesia",
            countryCode: "PF",
            isEU: false
        },
        {
            id: 79,
            name: "French Southern Territories",
            countryCode: "TF",
            isEU: false
        },
        {
            id: 80,
            name: "Gabon",
            countryCode: "GA",
            isEU: false
        },
        {
            id: 81,
            name: "Gambia",
            countryCode: "GM",
            isEU: false
        },
        {
            id: 82,
            name: "Georgia",
            order: 4,
            countryCode: "GE",
            isEU: false
        },
        {
            id: 83,
            name: "Germany",
            order: 2,
            countryCode: "DE",
            isEU: true
        },
        {
            id: 84,
            name: "Ghana",
            countryCode: "GH",
            isEU: false
        },
        {
            id: 85,
            name: "Gibraltar",
            order: 4,
            countryCode: "GI",
            isEU: false
        },
        {
            id: 86,
            name: "Greece",
            order: 3,
            countryCode: "EL",
            isEU: true
        },
        {
            id: 87,
            name: "Greenland",
            countryCode: "GL",
            isEU: false
        },
        {
            id: 88,
            name: "Grenada",
            countryCode: "GD",
            isEU: false
        },
        {
            id: 89,
            name: "Guadeloupe",
            countryCode: "GP",
            isEU: false
        },
        {
            id: 90,
            name: "Guam",
            countryCode: "GU",
            isEU: false
        },
        {
            id: 91,
            name: "Guatemala",
            countryCode: "GT",
            isEU: false
        },
        {
            id: 92,
            name: "Guernsey",
            countryCode: "GG",
            isEU: false
        },
        {
            id: 93,
            name: "Guinea",
            countryCode: "GN",
            isEU: false
        },
        {
            id: 94,
            name: "Guinea-Bissau",
            countryCode: "GW",
            isEU: false
        },
        {
            id: 95,
            name: "Guyana",
            countryCode: "GY",
            isEU: false
        },
        {
            id: 96,
            name: "Haiti",
            countryCode: "HT",
            isEU: false
        },
        {
            id: 97,
            name: "Heard Island and McDonald Islands",
            countryCode: "HM",
            isEU: false
        },
        {
            id: 98,
            name: "Holy See (Vatican City State)",
            countryCode: "VA",
            isEU: false
        },
        {
            id: 99,
            name: "Honduras",
            countryCode: "HN",
            isEU: false
        },
        {
            id: 100,
            name: "Hong Kong",
            countryCode: "HK",
            isEU: false
        },
        {
            id: 101,
            name: "Hungary",
            order: 4,
            countryCode: "HU",
            isEU: true
        },
        {
            id: 102,
            name: "Iceland",
            order: 3,
            countryCode: "IS",
            isEU: false
        },
        {
            id: 103,
            name: "India",
            countryCode: "IN",
            isEU: false
        },
        {
            id: 104,
            name: "Indonesia",
            countryCode: "ID",
            isEU: false
        },
        {
            id: 105,
            name: "Iran, Islamic Republic of",
            countryCode: "IR",
            isEU: false
        },
        {
            id: 106,
            name: "Iraq",
            countryCode: "IQ",
            isEU: false
        },
        {
            id: 107,
            name: "Ireland",
            order: 2,
            countryCode: "IE",
            isEU: true
        },
        {
            id: 108,
            name: "Isle of Man",
            countryCode: "IM",
            isEU: false
        },
        {
            id: 109,
            name: "Israel",
            countryCode: "IL",
            isEU: false
        },
        {
            id: 110,
            name: "Italy",
            order: 2,
            countryCode: "HT",
            isEU: true
        },
        {
            id: 111,
            name: "Jamaica",
            countryCode: "JM",
            isEU: false
        },
        {
            id: 112,
            name: "Japan",
            countryCode: "JP",
            isEU: false
        },
        {
            id: 113,
            name: "Jersey",
            countryCode: "JE",
            isEU: false
        },
        {
            id: 114,
            name: "Jordan",
            countryCode: "JO",
            isEU: false
        },
        {
            id: 115,
            name: "Kazakhstan",
            countryCode: "KZ",
            isEU: false
        },
        {
            id: 116,
            name: "Kenya",
            countryCode: "KE",
            isEU: false
        },
        {
            id: 117,
            name: "Kiribati",
            countryCode: "KI",
            isEU: false
        },
        {
            id: 118,
            name: "Korea, Democratic People's Republic of",
            countryCode: "KP",
            isEU: false
        },
        {
            id: 119,
            name: "Korea, Republic of",
            countryCode: "KR",
            isEU: false
        },
        {
            id: 120,
            name: "Kuwait",
            countryCode: "KW",
            isEU: false
        },
        {
            id: 121,
            name: "Kyrgyzstan",
            countryCode: "KG",
            isEU: false
        },
        {
            id: 122,
            name: "Lao People's Democratic Republic",
            countryCode: "LA",
            isEU: false
        },
        {
            id: 123,
            name: "Latvia",
            countryCode: "LV",
            isEU: true
        },
        {
            id: 124,
            name: "Lebanon",
            countryCode: "LB",
            isEU: false
        },
        {
            id: 125,
            name: "Lesotho",
            countryCode: "LS",
            isEU: false
        },
        {
            id: 126,
            name: "Liberia",
            countryCode: "LR",
            isEU: false
        },
        {
            id: 127,
            name: "Libya",
            countryCode: "LY",
            isEU: false
        },
        {
            id: 128,
            name: "Liechtenstein",
            countryCode: "LI",
            isEU: false
        },
        {
            id: 129,
            name: "Lithuania",
            countryCode: "LT",
            isEU: true
        },
        {
            id: 130,
            name: "Luxembourg",
            countryCode: "LU",
            isEU: true
        },
        {
            id: 131,
            name: "Macao",
            countryCode: "MO",
            isEU: false
        },
        {
            id: 132,
            name: "Macedonia",
            order: 4,
            countryCode: "MK",
            isEU: false
        },
        {
            id: 133,
            name: "Madagascar",
            countryCode: "MG",
            isEU: false
        },
        {
            id: 134,
            name: "Malawi",
            countryCode: "MW",
            isEU: false
        },
        {
            id: 135,
            name: "Malaysia",
            order: 4,
            countryCode: "MY",
            isEU: false
        },
        {
            id: 136,
            name: "Maldives",
            countryCode: "MV",
            isEU: false
        },
        {
            id: 137,
            name: "Mali",
            countryCode: "ML",
            isEU: false
        },
        {
            id: 138,
            name: "Malta",
            countryCode: "MT",
            isEU: true
        },
        {
            id: 139,
            name: "Marshall Islands",
            countryCode: "MH",
            isEU: false
        },
        {
            id: 140,
            name: "Martinique",
            countryCode: "MQ",
            isEU: false
        },
        {
            id: 141,
            name: "Mauritania",
            countryCode: "MR",
            isEU: false
        },
        {
            id: 142,
            name: "Mauritius",
            countryCode: "MU",
            isEU: false
        },
        {
            id: 143,
            name: "Mayotte",
            countryCode: "YT",
            isEU: false
        },
        {
            id: 144,
            name: "Mexico",
            countryCode: "MX",
            isEU: false
        },
        {
            id: 145,
            name: "Micronesia, Federated States of",
            countryCode: "FM",
            isEU: false
        },
        {
            id: 146,
            name: "Moldova, Republic of",
            countryCode: "MD",
            isEU: false
        },
        {
            id: 147,
            name: "Monaco",
            order: 3,
            countryCode: "MC",
            isEU: false
        },
        {
            id: 148,
            name: "Mongolia",
            countryCode: "MN",
            isEU: false
        },
        {
            id: 149,
            name: "Montenegro",
            countryCode: "ME",
            isEU: false
        },
        {
            id: 150,
            name: "Montserrat",
            countryCode: "MS",
            isEU: false
        },
        {
            id: 151,
            name: "Morocco",
            countryCode: "MA",
            isEU: false
        },
        {
            id: 152,
            name: "Mozambique",
            countryCode: "MZ",
            isEU: false
        },
        {
            id: 153,
            name: "Myanmar",
            countryCode: "MM",
            isEU: false
        },
        {
            id: 154,
            name: "Namibia",
            countryCode: "NA",
            isEU: false
        },
        {
            id: 155,
            name: "Nauru",
            countryCode: "NR",
            isEU: false
        },
        {
            id: 156,
            name: "Nepal",
            countryCode: "NP",
            isEU: false
        },
        {
            id: 157,
            name: "Netherlands",
            order: 3,
            countryCode: "NL",
            isEU: true
        },
        {
            id: 158,
            name: "New Caledonia",
            countryCode: "NC",
            isEU: false
        },
        {
            id: 159,
            name: "New Zealand",
            order: 3,
            countryCode: "NZ",
            isEU: false
        },
        {
            id: 160,
            name: "Nicaragua",
            countryCode: "NI",
            isEU: false
        },
        {
            id: 161,
            name: "Niger",
            countryCode: "NE",
            isEU: false
        },
        {
            id: 162,
            name: "Nigeria",
            countryCode: "NG",
            isEU: false
        },
        {
            id: 163,
            name: "Niue",
            countryCode: "NU",
            isEU: false
        },
        {
            id: 164,
            name: "Norfolk Island",
            countryCode: "NF",
            isEU: false
        },
        {
            id: 165,
            name: "Northern Mariana Islands",
            countryCode: "MP",
            isEU: false
        },
        {
            id: 166,
            name: "Norway",
            order: 3,
            countryCode: "NO",
            isEU: false
        },
        {
            id: 167,
            name: "Oman",
            countryCode: "OM",
            isEU: false
        },
        {
            id: 168,
            name: "Pakistan",
            countryCode: "PK",
            isEU: false
        },
        {
            id: 169,
            name: "Palau",
            countryCode: "PW",
            isEU: false
        },
        {
            id: 170,
            name: "Palestinian Territory, Occupied",
            countryCode: "PS",
            isEU: false
        },
        {
            id: 171,
            name: "Panama",
            countryCode: "PA",
            isEU: false
        },
        {
            id: 172,
            name: "Papua New Guinea",
            countryCode: "PG",
            isEU: false
        },
        {
            id: 173,
            name: "Paraguay",
            countryCode: "PY",
            isEU: false
        },
        {
            id: 174,
            name: "Peru",
            countryCode: "PE",
            isEU: false
        },
        {
            id: 175,
            name: "Philippines",
            countryCode: "PH",
            isEU: false
        },
        {
            id: 176,
            name: "Pitcairn",
            countryCode: "PN",
            isEU: false
        },
        {
            id: 177,
            name: "Poland",
            order: 1,
            countryCode: "PL",
            isEU: true
        },
        {
            id: 178,
            name: "Portugal",
            order: 3,
            countryCode: "PT",
            isEU: true
        },
        {
            id: 179,
            name: "Puerto Rico",
            countryCode: "PR",
            isEU: false
        },
        {
            id: 180,
            name: "Qatar",
            countryCode: "QA",
            isEU: false
        },
        {
            id: 181,
            name: "Réunion",
            countryCode: "RE",
            isEU: false
        },
        {
            id: 182,
            name: "Romania",
            order: 3,
            countryCode: "RO",
            isEU: true
        },
        {
            id: 183,
            name: "Russian Federation",
            countryCode: "RU",
            isEU: false
        },
        {
            id: 184,
            name: "Rwanda",
            countryCode: "RW",
            isEU: false
        },
        {
            id: 185,
            name: "Saint Barthélemy",
            countryCode: "BL",
            isEU: false
        },
        {
            id: 186,
            name: "Saint Helena, Ascension and Tristan da Cunha",
            countryCode: "SH",
            isEU: false
        },
        {
            id: 187,
            name: "Saint Kitts and Nevis",
            countryCode: "KN",
            isEU: false
        },
        {
            id: 188,
            name: "Saint Lucia",
            countryCode: "LC",
            isEU: false
        },
        {
            id: 189,
            name: "Saint Martin (French part)",
            countryCode: "MF",
            isEU: false
        },
        {
            id: 190,
            name: "Saint Pierre and Miquelon",
            countryCode: "PM",
            isEU: false
        },
        {
            id: 191,
            name: "Saint Vincent and the Grenadines",
            countryCode: "VC",
            isEU: false
        },
        {
            id: 192,
            name: "Samoa",
            countryCode: "WS",
            isEU: false
        },
        {
            id: 193,
            name: "San Marino",
            countryCode: "SM",
            isEU: false
        },
        {
            id: 194,
            name: "Sao Tome and Principe",
            countryCode: "ST",
            isEU: false
        },
        {
            id: 195,
            name: "Saudi Arabia",
            countryCode: "SA",
            isEU: false
        },
        {
            id: 196,
            name: "Senegal",
            countryCode: "SN",
            isEU: false
        },
        {
            id: 197,
            name: "Serbia",
            countryCode: "RS",
            isEU: false
        },
        {
            id: 198,
            name: "Seychelles",
            countryCode: "SC",
            isEU: false
        },
        {
            id: 199,
            name: "Sierra Leone",
            countryCode: "SL",
            isEU: false
        },
        {
            id: 200,
            name: "Singapore",
            countryCode: "SG",
            isEU: false
        },
        {
            id: 201,
            name: "Sint Maarten (Dutch part)",
            countryCode: "SX",
            isEU: false
        },
        {
            id: 202,
            name: "Slovakia",
            countryCode: "SK",
            isEU: true
        },
        {
            id: 203,
            name: "Slovenia",
            countryCode: "SI",
            isEU: true
        },
        {
            id: 204,
            name: "Solomon Islands",
            countryCode: "SB",
            isEU: false
        },
        {
            id: 205,
            name: "Somalia",
            countryCode: "SO",
            isEU: false
        },
        {
            id: 206,
            name: "South Africa",
            countryCode: "ZA",
            isEU: false
        },
        {
            id: 207,
            name: "South Georgia and the South Sandwich Islands",
            countryCode: "GS",
            isEU: false
        },
        {
            id: 208,
            name: "South Sudan",
            countryCode: "SS",
            isEU: false
        },
        {
            id: 209,
            name: "Spain",
            countryCode: "ES",
            isEU: true,
            order: 2
        },
        {
            id: 210,
            name: "Sri Lanka",
            countryCode: "LK",
            isEU: false
        },
        {
            id: 211,
            name: "Sudan",
            countryCode: "SD",
            isEU: false
        },
        {
            id: 212,
            name: "Suriname",
            countryCode: "SR",
            isEU: false
        },
        {
            id: 213,
            name: "Svalbard and Jan Mayen",
            countryCode: "SJ",
            isEU: false
        },
        {
            id: 214,
            name: "Swaziland",
            countryCode: "SZ",
            isEU: false
        },
        {
            id: 215,
            name: "Sweden",
            countryCode: "SE",
            isEU: true,
            order: 3
        },
        {
            id: 216,
            name: "Switzerland",
            countryCode: "CH",
            isEU: false,
            order: 2
        },
        {
            id: 217,
            name: "Syrian Arab Republic",
            countryCode: "SY",
            isEU: false
        },
        {
            id: 218,
            name: "Taiwan, Province of China",
            countryCode: "TW",
            isEU: false
        },
        {
            id: 219,
            name: "Tajikistan",
            countryCode: "TJ",
            isEU: false
        },
        {
            id: 220,
            name: "Tanzania, United Republic of",
            countryCode: "TZ",
            isEU: false
        },
        {
            id: 221,
            name: "Thailand",
            countryCode: "TH",
            isEU: false,
            order: 4
        },
        {
            id: 222,
            name: "Timor-Leste",
            countryCode: "TL",
            isEU: false
        },
        {
            id: 223,
            name: "Togo",
            countryCode: "TG",
            isEU: false
        },
        {
            id: 224,
            name: "Tokelau",
            countryCode: "TK",
            isEU: false
        },
        {
            id: 225,
            name: "Tonga",
            countryCode: "TO",
            isEU: false
        },
        {
            id: 226,
            name: "Trinidad and Tobago",
            countryCode: "TT",
            isEU: false
        },
        {
            id: 227,
            name: "Tunisia",
            countryCode: "TN",
            isEU: false,
            order: 4
        },
        {
            id: 228,
            name: "Turkey",
            countryCode: "TR",
            isEU: false,
            order: 4
        },
        {
            id: 229,
            name: "Turkmenistan",
            countryCode: "TM",
            isEU: false,
            order: 4
        },
        {
            id: 230,
            name: "Turks and Caicos Islands",
            countryCode: "TC",
            isEU: false
        },
        {
            id: 231,
            name: "Tuvalu",
            countryCode: "TV",
            isEU: false
        },
        {
            id: 232,
            name: "Uganda",
            countryCode: "UG",
            isEU: false
        },
        {
            id: 233,
            name: "Ukraine",
            countryCode: "UA",
            isEU: false,
            order: 3
        },
        {
            id: 234,
            name: "United Arab Emirates",
            countryCode: "AE",
            isEU: false,
            order: 2
        },
        {
            id: 235,
            name: "United Kingdom",
            countryCode: "GB",
            isEU: true,
            order: 2
        },
        {
            id: 236,
            name: "United States",
            countryCode: "US",
            isEU: false,
            order: 2
        },
        {
            id: 237,
            name: "United States Minor Outlying Islands",
            countryCode: "UM",
            isEU: false
        },
        {
            id: 238,
            name: "Uruguay",
            countryCode: "UY",
            isEU: false,
            order: 5
        },
        {
            id: 239,
            name: "Uzbekistan",
            countryCode: "UZ",
            isEU: false
        },
        {
            id: 240,
            name: "Vanuatu",
            countryCode: "VU",
            isEU: false
        },
        {
            id: 241,
            name: "Venezuela, Bolivarian Republic of",
            countryCode: "VE",
            isEU: false
        },
        {
            id: 242,
            name: "Viet Nam",
            countryCode: "VN",
            isEU: false
        },
        {
            id: 243,
            name: "Virgin Islands, British",
            countryCode: "VG",
            isEU: false
        },
        {
            id: 244,
            name: "Virgin Islands, U.S.",
            countryCode: "VI",
            isEU: false
        },
        {
            id: 245,
            name: "Wallis and Futuna",
            countryCode: "WF",
            isEU: false
        },
        {
            id: 246,
            name: "Western Sahara",
            countryCode: "EH",
            isEU: false
        },
        {
            id: 247,
            name: "Yemen",
            countryCode: "YE",
            isEU: false,
            order: 5
        },
        {
            id: 248,
            name: "Zambia",
            countryCode: "ZM",
            isEU: false
        },
        {
            id: 249,
            name: "Zimbabwe",
            countryCode: "ZW",
            isEU: false
        }
    ]
}