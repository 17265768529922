import { Utils } from "../others/utils"

export class DbAdditionalService {
    
    additionalServiceId: number
    userId: string
    status: number
    name: string
    description: string
    rooms: any[]
    priceAdult: number
    priceChildren: number
    period: number
    type: number
    editDate: string
    addDate: string
    option: string
    shareInOffer: string
    displayName?: string
    price?:number = 0
    paymentStatus?: number = 0
    taxRate: string | number

    constructor({
        additionalServiceId,
        userId,
        status,
        name,
        description,
        rooms,
        priceAdult,
        priceChildren,
        period,
        type,
        editDate,
        addDate,
        option,
        shareInOffer,
        paymentStatus,
        taxRate
    }:any) {
        this.additionalServiceId = additionalServiceId
        this.userId = userId
        this.status = status
        this.name = name
        this.description = description
        this.rooms = rooms
        this.priceAdult = priceAdult
        this.priceChildren = priceChildren
        this.period = period
        this.type = type
        this.editDate = editDate
        this.addDate = addDate
        this.option = option
        this.shareInOffer = shareInOffer
        this.paymentStatus = paymentStatus
        this.taxRate = taxRate
        if (Utils.isJSON(name)) {
            this.displayName = JSON.parse(name).default
        } else this.displayName = name.default
    }
}