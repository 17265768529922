export class RoomEquipment {
    public static List = [
        { id: 1, name: "klimatyzacja" },
        { id: 2, name: "aneks_kuchenny" },
        { id: 3, name: "telewizor" },
        { id: 4, name: "lazienka" },
        { id: 5, name: "basen" },
        { id: 6, name: "balkon" },
        { id: 7, name: "pralka" },
        { id: 8, name: "przybory_kuchenne" },
        { id: 9, name: "lozeczko_dla_dzieci" },
        { id: 10, name: "koc" },
        { id: 11, name: "parawan" },
        { id: 12, name: "parasol" },
        { id: 13, name: "lezak" },
        { id: 14, name: "wieszak_na_ubrania" },
        { id: 15, name: "suszarka" },
        { id: 16, name: "reczniki" },
        { id: 17, name: "wykladzina_podlogowa" },
        { id: 18, name: "kominek" },
        { id: 19, name: "ogrzewanie" },
        { id: 20, name: "zelazko" },
        { id: 21, name: "sejf" },
        { id: 22, name: "biurko" },
        { id: 23, name: "bidet" },
        { id: 24, name: "szczoteczka_do_zebow" },
        { id: 25, name: "szampon" },
        { id: 26, name: "odzywka_do_wlosow" },
        { id: 27, name: "mydlo" },
        { id: 28, name: "radio" },
        { id: 29, name: "stol" },
        { id: 30, name: "kieliszki_do_wina" },
        { id: 31, name: "korkociag" },
        { id: 32, name: "woda" },
        { id: 33, name: "grill" },
        { id: 34, name: "zmywarka" },
        { id: 35, name: "minibar" },
        { id: 36, name: "lodowka" },
        { id: 37, name: "ekspres_do_kawy"}
    ]
}