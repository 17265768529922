import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { LocalizeRouterService } from '@penleychan/ngx-transloco-router';
import { AuthService } from 'app/core/auth/auth.service';
import { UserSettings } from 'app/core/shared/models/UserSettings';
import { Utils } from 'app/core/shared/others/utils';
import { SettingsService } from 'app/core/shared/services/settings.service';
import { of, switchMap } from 'rxjs';

export const SubscriptionGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const localize = inject(LocalizeRouterService);

    let minSubscription = 0;
    if(Utils.isDefined(route,"data.minSubscription")) minSubscription = route.data['minSubscription']
    // Check the authentication status
    return inject(SettingsService).getUserSettings().pipe(
        switchMap((userSettings:UserSettings) =>
        {
            const premiumDays = Utils.checkPremiumDays(userSettings.userData.premiumTime)

            if (premiumDays <= 0)
            {
                console.log(`[SubscriptionGuard]: Access available only for active subscription. No premium days left. Redirecting...`)
                const translatedPath = localize.translateRoute('/app/subscription/expired');
                router.navigate([translatedPath]);
                return of(true)
            }

            // If the user is authenticated...
            if (userSettings?.userData?.premiumType < minSubscription)
            {
                console.log(`[SubscriptionGuard]: Access available only from ${minSubscription} subscription. Redirecting...`)
                const translatedPath = localize.translateRoute('/app/subscription/extend');
                router.navigate([translatedPath]);
                return of(true)
            }
            // lol23
            // Allow the access
            return of(true);
        }),
    );
};