import { DbInvoice } from "../models/DbInvoice";

export const invoiceFilter = ({invoice, value}: {invoice:  DbInvoice, value:string}) => {
    let companyNameBuyer = invoice.companyNameBuyer.toLowerCase();
    let addressBuyer = invoice.addressBuyer.toLowerCase();
    let taxIdBuyer = invoice.taxIdBuyer.toLowerCase();
    let additionalInfo = invoice.additionalInfo.toLowerCase();

    if (companyNameBuyer.includes(value)) { return true }
    else if (addressBuyer.includes(value)) { return true }
    else if (taxIdBuyer.includes(value)) { return true }
    else if (invoice.invoiceNo == value) { return true }
    else if (additionalInfo.includes(value)) { return true }
    else if (invoice.fullInvoiceName.includes(value)) { return true }
    else if (invoice.issueDate.includes(value)) return true
    else if (invoice.saleDate.includes(value)) return true

    return false;
}