import { ApplicationData } from "../data/application-data";
import { DbInvoice } from "../models/DbInvoice";

export class InvoiceUtils {


    public static BuildInvoiceFullName (invoice:DbInvoice): string {

        return this.BuildInvoiceNameByFormat(invoice)
    }

    public static BuildInvoiceNameByFormat(invoice:DbInvoice)
    {
        const format = invoice.format || 'NUMBER/MM/YYYY';

        const issueDate = new Date(invoice.issueDate);
        const year = issueDate.getFullYear();
        let month: any = issueDate.getMonth() + 1;
        if (month < 10) month = "0" + month.toString();
    
        const number = invoice.invoiceNo;
        const extraChar = invoice.extraChar || ''; // Include `extraChar` if needed
    
        // Replace format placeholders with values
        const formattedName = format
            .replace('NUMBER', number)
            .replace('MM', month)
            .replace('YYYY', year.toString());
    
        return `${extraChar}${formattedName}`;
        
    }
    
}