<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <ng-container>
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button
                mat-icon-button
                [matDialogClose]="undefined">
                <mat-icon
                    class="text-secondary"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <ng-container *ngIf="data.showIcon" >
            <div
                class="flex flex-0 items-center justify-center mr-4 w-10 h-10 rounded-full"
                [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data?.type == 'primary',
                            'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data?.type == 'accent',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data?.type == 'warning',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data?.type == 'danger',
                            'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data?.type == 'basic',
                            'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data?.type == 'info',
                            'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data?.type == 'success',
                            'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data?.type == 'warning',
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data?.type == 'error'
                            }">
                            
                <mat-icon class="text-current" *ngIf="data?.type == 'danger'" [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
                <mat-icon class="text-current" *ngIf="data?.type == 'warning' || data?.type == 'error'" [svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
                <mat-icon class="text-current" *ngIf="data?.type == 'info'" [svgIcon]="'heroicons_solid:bell'"></mat-icon>
                <mat-icon class="text-current" *ngIf="data?.type == 'edit'" [svgIcon]="'heroicons_solid:pencil-square'"></mat-icon>
                <mat-icon class="text-current" *ngIf="data?.type == 'success'" [svgIcon]="'heroicons_solid:check-cirlce'"></mat-icon>
            </div>
        </ng-container>

        <ng-container>
            <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

                <!-- Title -->
                <ng-container *ngIf="data?.title">
                    <div
                        class="text-xl leading-6 font-medium"
                        [innerHTML]="data.title"></div>
                </ng-container>

                <!-- Message -->
                <ng-container *ngIf="data?.message">
                    <div
                        class="text-secondary"
                        [innerHTML]="data.message"></div>
                </ng-container>

                <!-- Inputs -->
                <ng-container *ngIf="data?.inputs?.length > 0">
                    <form [formGroup]="form">
                        <div *ngFor="let input of data?.inputs">
                            <div [ngSwitch]="input.type">
                                <div *ngSwitchCase="'checkbox'">
                                    <mat-checkbox color="primary" class="-ml-2" [formControlName]="input.name">{{input.description}}</mat-checkbox>
                                </div>
                                <div *ngSwitchCase="'text'">
                                    <mat-form-field class="w-100">
                                        <mat-label>{{input.description}}</mat-label>
                                        <input [formControlName]="input.name" matInput [placeholder]="input.description"
                                            [value]="input.value">
                                    </mat-form-field>
                                </div>
                                <div *ngSwitchCase="'color'">

                                    <mat-form-field ngx-colors-trigger="colorPicker" appearance="fill"
                                    [hideTextInput]="true" [colorPickerControls]="colorPickerControls"
                                    [acceptLabel]="translate.translate('wybierz')"
                                    [cancelLabel]="translate.translate('anuluj')" (change)="updateColor($event)">
                                        <mat-label>{{input.description}}</mat-label>
                                        <input readonly matInput [formControlName]="input.name">
                                        <ngx-colors #colorPicker  ngx-colors-trigger matSuffix  [formControlName]="input.name+'Pckr'"
                                            style="display: inline-block; margin:10px;">
                                        </ngx-colors>

                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>

            </div>
        </ng-container>

        
    </div>
    
    <!-- Actions -->
    <ng-container>
        <div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
            <!-- Cancel -->
            <ng-container *ngIf="data?.cancelText != null" >
                <button mat-stroked-button (click)="close(false)">{{data?.cancelText}}</button>
            </ng-container>
            <!-- Confirm -->
            <ng-container *ngIf="data?.confirmText != null" >
                <button mat-flat-button (click)="close(true)" [disabled]="!form.valid" [color]="(data?.type == 'warning' || data?.type == 'error' || data?.type == 'danger') ? 'warn' : 'primary'">{{data?.confirmText}}</button>
            </ng-container>
        </div>
    </ng-container>
</div>