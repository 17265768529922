<mat-dialog-content class="mat-typography">
    <img *ngIf="" src="" alt="">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <input type="text" formControlName="title" class="title clean-input auto-height w-100" [placeholder]="translate.translate('tytul')">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <textarea type="text" formControlName="description" class="description clean-input auto-height w-100" [placeholder]="translate.translate('krotki_opis')"></textarea>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-icon-button>
        <mat-icon>image</mat-icon>
    </button>
    <button mat-icon-button>
        <mat-icon svgIcon="heroicons_solid:document-duplicate"></mat-icon>
    </button>
    <button mat-icon-button>
        <mat-icon>local_offer</mat-icon>
    </button>
    <button *ngIf="note != null" mat-icon-button color="warn">
        <mat-icon svgIcon="trash"></mat-icon>
    </button>

    <button mat-button class="ms-auto" color="primary" (click)="saveOrCreate()">
        {{note == null ? ("dodaj" | transloco) : ("zapisz" | transloco)}}
    </button>
    <button mat-button (click)="close()">
        {{"zamknij" | transloco}}
    </button>
</mat-dialog-actions>