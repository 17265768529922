import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelService } from '../../services/panel.service';
import { MatIconModule } from '@angular/material/icon';
import { CdkPortalOutlet, PortalModule } from '@angular/cdk/portal';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'portal-outlet-loader',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, PortalModule, TranslocoModule],
  templateUrl: './portal-outlet-loader.component.html',
  styleUrl: './portal-outlet-loader.component.scss'
})
export class PortalOutletLoaderComponent implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: true }) portalOutlet: CdkPortalOutlet;
  constructor(
    public panelService:PanelService
  ) {}

  ngOnInit(): void {
    this.panelService.registerPortalOutlet(this.portalOutlet);
  }
}
