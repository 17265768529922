export const decodeToken = (token: string):any => {

    if (!token) {
      return null;
    }

    const payload = token.split('.')[1];
    if (!payload) {
      return null;
    }

    try {
      const decodedPayload = base64Decode(payload);
      return JSON.parse(decodedPayload);
    } catch (error) {
      console.error('Error decoding token payload', error);
      return null;
    }

  }

function base64Decode(str: string): string {
    try {
        return decodeURIComponent(
        atob(str)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        );
    } catch (error) {
        console.error('Base64 decoding failed', error);
        return '';
    }
}